import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Export from "../../assets/img/icons/exportar.svg";
import blueAddNew from "../../assets/img/icons/blueAddNew.svg";
import Refresh from "../../assets/img/icons/Refresh.svg";
import lupa from "../../assets/img/icons/lupa.svg";
import dotThree from "../../assets/img/icons/dotThree.svg";
import Pagination from "react-js-pagination";
import GenericTable from "../Layouts/GenericTable";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import { getAllAdverseEvents } from "../../actions/adverseEventsAction";
import { AdverseEventsForm } from "./AdverseEventsForm";
import {
  getAllBusinessUnits,
  getAllInsurances,
  getGenders,
  getIdentityTypes,
} from "../../actions/businessUnits";

function AdverseEventsMasterTable(props) {
  const store = useSelector((state) => state); //entra aca para acceder a todos los reducers
  const dispatch = useDispatch(); //usa este metodo para ejecutar un action
  const arrAdverseEvents = store.adverseEventsReducer.adverseEventsList;
  const [showForm, setShowForm] = useState(false);

  let i = 0;

  const [filters, setFilter] = useState({
    search: "",
    page: 1,
    perpage: 10,
  }); //valores de entrada de la funcion en el backend

  const [contador, setContador] = useState(0); //esto es para recargar la pagina
  useEffect(
    () => {
      dispatch(getAllAdverseEvents());
      dispatch(getAllBusinessUnits());
      dispatch(getIdentityTypes());
      dispatch(getGenders());
      dispatch(getAllInsurances());
    },
    [contador, filters.page, showForm] //cada vez que esto cambie, ejecutar la funcion de arriba.
  );

  const handlePageChange = (val) => {
    setFilter({
      ...filters, // División de objetos
      page: val,
    });
  };

  const handleSearchInput = (val) => {
    setFilter({
      ...filters, //el resto no los quiero cambiar
      search: val, //solo search
    });
  };

  const renderElement = (elem) => {
    return (
      <tr key={elem.code}>
        <td className="col-md-2 text-center">{elem.observerName}</td>
        <td className="col-md-2 text-center">{elem.workPosition}</td>
        <td
          alt={elem.summary}
          title={elem.summary}
          className="col-md-2 text-center"
        >
          {elem.shortSummary}
        </td>
        <td className="col-md-1 text-center">{elem.service}</td>
        <td className="col-md-2 text-center">{elem.branchOffice}</td>
        <td className="col-md-2 text-center">{elem.location}</td>
        <td className="col-md-2 text-center">{elem.date}</td>
        <td
          className="col-md-1 text-center"
          style={{ color: "blue", paddingRight: "10px" }}
        >
          <button style={{ margin: "0 auto" }}>
            <img src={dotThree}></img>
          </button>
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (arrAdverseEvents !== undefined) {
      const elem2 = arrAdverseEvents;
      elemMap = elem2.map((elem) => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };
  const goToForm = () => {
    setShowForm(true);
  };
  const renderHeaders = [
    <th style={{ borderTopLeftRadius: "10px" }} key={i}>
      Observador
    </th>,
    <th key={i + 1}>Cargo</th>,
    <th key={i + 1}>Descripción</th>,
    <th key={i + 1}>Servicio</th>,
    <th key={i + 1}>Sede</th>,
    <th key={i + 1}>Lugar</th>,
    <th key={i + 1}>Fecha</th>,
    <th style={{ borderTopRightRadius: "10px" }} key={i + 1}></th>,
  ];

  return (
    <>
      {showForm ? (
        <AdverseEventsForm
          hideForm={() => setShowForm(false)}
        ></AdverseEventsForm>
      ) : (
        <div className={tableStyles.container}>
          <h1
            className={`${tableStyles.clearBlueText} ${tableStyles.marginLeftsix}`}
          >
            Listado de reportes &nbsp;
            <a>
              <img src={Refresh}></img>
            </a>
          </h1>

          <div className={tableStyles.tableArea}>
            <div className={tableStyles.mainRow}>
              <div className="d-flex justify-content-space-between filterDiv-ListView">
                <div>
                  <input
                    className={`${tableStyles.searchUsersAlt} mt-4`}
                    type="text"
                    placeholder="Buscar"
                    onChange={(e) => handleSearchInput(e.target.value)}
                  />
                </div>
                <button onClick={() => setContador(contador + 1)}>
                  <img
                    src={lupa}
                    alt="User icon"
                    className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin} mt-4`}
                  />
                </button>
              </div>

              <div className={tableStyles.groupElems}>
                <small className="mt-4">
                  <b onClick={goToForm} className="buttonNewOrCreate">
                    Nuevo
                    <img
                      width="15.6px"
                      height="20px"
                      src={blueAddNew}
                      alt="Form"
                      className={`${tableStyles.iconSvg} buttonNewOrCreate_img`}
                    />
                  </b>
                </small>
              </div>
            </div>

            <GenericTable
              theadTheme={`${tableStyles.theadClearBlue}`}
              headers={renderHeaders}
            >
              {listElem()}
            </GenericTable>

            <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                Pag. {store.cupsReducer.total ? filters.page : ""}
                {" de "}
                {Math.ceil(store.cupsReducer.total / filters.perpage)
                  ? Math.ceil(store.cupsReducer.total / filters.perpage)
                  : ""}{" "}
                ({store.cupsReducer.total} encontrados)
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={10}
                totalItemsCount={store.cupsReducer.total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default AdverseEventsMasterTable;
