import { combineReducers } from "redux";
import { loginReducer } from "./loginReducer";
import { profileReducer } from "./profileReducer";
import { userReducer } from "./userReducer";
import { headerReducer } from "./headerReducer";
// import { siteReducer } from "./siteReducer";
// import { cietenReducer } from "./cietenReducer";
// import { cuentasReducer } from "./cuentasReducer";
// import { locationReducer } from "./locationReducer";
// import { functionalReducer } from "./functionalReducer";
// import { costcenterReducer } from "./costcenterReducer";
import { measurementUnitsReducer } from "./measurementUnitsReducer";
// import { inventoryFamilyReducer } from "./inventoryFamilyReducer";
import { pucReducer } from "./pucReducer";
// import { consumptionCenterReducer } from "./consumptionCenterReducer";
// import { articleReducer } from "./articleReducer";
import { storageReducer } from "./storageReducer";
import { cupsReducer } from "./cupsReducer";
// import { individualAuctionReducer } from "./individualAuctionReducer";
// import { providerReducer } from "./providerReducer";
// import { corporateclientsReducer } from "./corporateclientsReducer";
import { documentReducer } from "./documentReducer";
import { auditoriesReducer } from "./auditoriesReducer";
import { indicatorsReducer } from "./indicatorsReducer";
import { actionPlansReducer } from "./actionPlanReducer";
import { pollsReducer } from "./pollReducer";
import { toolsReducer } from "./toolsReducer";
import { comitesReducer } from "./comitesReducer";
import { adverseEventsReducer } from "./adverseEventsReducer";
import { businessUnitsReducer } from "./businessUnitsReducer";
import { statusReducer } from "./statusReducer";
export default combineReducers({
  storageReducer,
  loginReducer,
  headerReducer,
  userReducer,
  profileReducer,
  // siteReducer,
  // cuentasReducer,
  // cietenReducer,
  // functionalReducer,
  // costcenterReducer,
  // locationReducer,
  measurementUnitsReducer,
  // pucReducer,
  // inventoryFamilyReducer,
  // consumptionCenterReducer,
  cupsReducer,
  // articleReducer,
  // individualAuctionReducer,
  // providerReducer,
  // corporateclientsReducer,
  documentReducer,
  auditoriesReducer,
  indicatorsReducer,
  actionPlansReducer,
  pollsReducer,
  toolsReducer,
  comitesReducer,
  adverseEventsReducer,
  businessUnitsReducer,
  statusReducer,
});
