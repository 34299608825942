import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Export from "../../assets/img/icons/exportar.svg";
import blueAddNew from "../../assets/img/icons/blueAddNew.svg";
import lupa from "../../assets/img/icons/lupa.svg";
import Pagination from "react-js-pagination";
import GenericTable from "../Layouts/GenericTable";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
/* ------------------------------------ x ----------------------------------- */
import { getAllDocumentType } from "../../actions/documentActions";
import { getAllActionPlans } from "../../actions/actionPlanAction";
import { ActionPlanForm } from "./ActionPlanForm";
import { getAllBusinessUnits } from "../../actions/businessUnits";

function ActionPlanMasterTable(props) {
  const [showForm, setShowForm] = useState(false);
  const store = useSelector((state) => state); //entra aca para acceder a todos los reducers
  const arrActionPlans = store.actionPlansReducer.actionPlans;
  const arrFilter = store.documentReducer.documentTypes;
  const dispatch = useDispatch(); //usa este metodo para ejecutar un action
  const [filters, setFilter] = useState({
    search: "",
    page: 1,
    perpage: 10,
  }); //valores de entrada de la funcion en el backend

  let i = 0;

  const [contador, setContador] = useState(0); //esto es para recargar la pagina

  useEffect(() => {
    dispatch(getAllActionPlans());
    dispatch(getAllDocumentType());
    dispatch(getAllBusinessUnits());
  }, [contador, filters.page, filters.search, showForm]);

  const handlePageChange = (val) => {
    setFilter({
      ...filters, // División de objetos
      page: val,
    });
  };
  const handleSearchInput = (val) => {
    setFilter({
      ...filters, //el resto no los quiero cambiar
      search: val, //solo search
    });
  };

  const renderElement = (elem) => {
    return (
      <tr key={elem.code}>
        <td className="text-center first-table-header">{elem.name}</td>
        <td className="text-center">{elem.source}</td>
        <td className="text-center">{elem.leader}</td>
        <td className="text-center">{elem.process}</td>
        <td className="text-center">{elem.reason}</td>
        <td className="text-center">{elem.startDate}</td>
        <td className="text-center">{elem.endDate}</td>
        <td className="text-center">{elem.status}</td>
        <td className="text-center last-table-header">{elem.percentage}</td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (arrActionPlans !== undefined) {
      const elem2 = arrActionPlans;
      elemMap = elem2.map((elem) => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };
  const renderHeaders = [
    <th style={{ borderTopLeftRadius: "10px" }} key={i}>
      Nombre
    </th>,
    <th key={i + 1}>Origenes</th>,
    <th key={i + 1}>Líder</th>,
    <th key={i + 1}>Procesos</th>,
    <th key={i + 1}>Causas</th>,
    <th key={i + 1}>Fecha inicio</th>,
    <th key={i + 1}>Fecha final</th>,
    <th key={i + 1}>Estado</th>,
    <th key={i + 1}>Cumplimiento</th>,
    <th style={{ borderTopRightRadius: "10px" }} key={i + 1}></th>,
  ];

  const goToForm = () => {
    setShowForm(true);
  };

  return (
    <>
      {showForm ? (
        <ActionPlanForm hideForm={() => setShowForm(false)}></ActionPlanForm>
      ) : (
        <div className={tableStyles.container}>
          <h1
            className={`${tableStyles.clearBlueText} ${tableStyles.marginLeftsix}`}
          >
            Listado de planes de acción
          </h1>

          <div className={tableStyles.tableArea}>
            <div className={tableStyles.mainRow}>
              <div className="d-flex justify-content-space-between filterDiv-ListView">
                <div>
                  <p className={tableStyles.crudModalLabel}>Fecha de inicio</p>
                  <input
                        type="date"
                        name="date"
                        className="register-inputs"
                      />
                </div>
                <div>
                  <p className={tableStyles.crudModalLabel}>Fecha de fin</p>
                  <input
                        type="date"
                        name="date"
                        className="register-inputs"
                      />
                </div>
                <div>
                  <p className={tableStyles.crudModalLabel}>Lider del proceso</p>

                  <select placeholder={"Seleccione"} className="select-register">
                    <option value={""}>Seleccione</option>
                    <option value={""}>Jose David</option>
                    <option value={""}>Kelvin Martinez</option>
                  </select>
                </div>
                <div className="mt-4">
                  <input
                    className={tableStyles.searchUsersAlt}
                    type="text"
                    placeholder="Buscar"
                    // onChange={(e) => handleChangeInputSearch(e)}
                  />

                  <button
                  //  onClick={() => filterTable()}
                  >
                    <img
                      src={lupa}
                      alt="User icon"
                      className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                    />
                  </button>
                </div>
              </div>

              <div className={tableStyles.groupElems}>
                <small className="mt-4">
                    <b onClick={goToForm} className="buttonNewOrCreate">
                      Nuevo
                      <img
                        width="15.6px"
                        height="20px"
                        src={blueAddNew}
                        alt="Form"
                        className={`${tableStyles.iconSvg} buttonNewOrCreate_img`}
                      />
                    </b>
                  </small>
              </div>
            </div>

            <GenericTable
              theadTheme={`${tableStyles.theadClearBlue}`}
              headers={renderHeaders}
            >
              {listElem()}
            </GenericTable>

            <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                Pag. {store.cupsReducer.total ? filters.page : ""}
                {" de "}
                {Math.ceil(store.cupsReducer.total / filters.perpage)
                  ? Math.ceil(store.cupsReducer.total / filters.perpage)
                  : ""}{" "}
                ({store.cupsReducer.total} encontrados)
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={10}
                totalItemsCount={store.cupsReducer.total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default ActionPlanMasterTable;
