import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { message, TokenIsExpired } from "../helpers/helpers";
import {
  LOADING_SAVE_BUSINESS_UNITS_LIST,
  SAVE_BUSINESS_UNITS_LIST,
  SAVE_GENDER_LIST,
  SAVE_IDENTITIES_LIST,
  SAVE_INSURANCES_LIST,
} from "./actionTypes";

export const getAllBusinessUnits = () => (dispatch, getState) => {
  dispatch({
    type: LOADING_SAVE_BUSINESS_UNITS_LIST,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;

  fetch(`${URL_GATEWAY}${API_VERSION}/businessUnit`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        console.log(data.statusCode);
        TokenIsExpired(data.statusCode, dispatch);
        return message("error", "Error", data.message);
      }
      //Si no es 200 va a verificar si es un 401, si no lo es no hace nada
      dispatch({
        type: SAVE_BUSINESS_UNITS_LIST,
        payload: data.data,
        loading: false,
      });
    })
    .catch((err) => {
      console.error(err);
      message("error", "Error", err.message + "catch");
    });
};
export const getProcessByIdbUnit = async (bUnit, token) => {
  let data = { bUnit };
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/process`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const getBranchOfficeBybUnit = async (bUnit, token) => {
  let data = { bUnit };
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/branchOffice/getAll`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const getAllMeasurementUnit = async (token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/measurementsUnit/getAll`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const getAllMeasurementFrequency = async (token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/measurementsFrequency/getAll`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const getAllAreas = async (bUnit, token) => {
  let data = { bUnit };
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/area/getAll`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const getAllWorkPositions = async (bUnit, token) => {
  let data = { bUnit };
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/workPosition/getAll`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const getLocationsBybOffice = async (branchOffice, token) => {
  let data = { branchOffice };
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/location/getAll`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      console.log(result);
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const getIdentityTypes = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;

  fetch(`${URL_GATEWAY}${API_VERSION}/idType/getAll`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        console.log(data.statusCode);
        TokenIsExpired(data.statusCode, dispatch);
        return message("error", "Error", data.message);
      }
      //Si no es 200 va a verificar si es un 401, si no lo es no hace nada
      dispatch({
        type: SAVE_IDENTITIES_LIST,
        payload: data.data,
      });
    })
    .catch((err) => {
      console.error(err);
      message("error", "Error", err.message + "catch");
    });
};
export const getGenders = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;

  fetch(`${URL_GATEWAY}${API_VERSION}/gender/getAll`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        console.log(data.statusCode);
        TokenIsExpired(data.statusCode, dispatch);
        return message("error", "Error", data.message);
      }
      //Si no es 200 va a verificar si es un 401, si no lo es no hace nada
      dispatch({
        type: SAVE_GENDER_LIST,
        payload: data.data,
      });
    })
    .catch((err) => {
      console.error(err);
      message("error", "Error", err.message + "catch");
    });
};
export const getAsisProcess = async (bUnit, token) => {
  let data = { bUnit };
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/processByType`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const getAllInsurances = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;

  fetch(`${URL_GATEWAY}${API_VERSION}/insuranceCompany/getAll`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        console.log(data.statusCode);
        TokenIsExpired(data.statusCode, dispatch);
        return message("error", "Error", data.message);
      }
      //Si no es 200 va a verificar si es un 401, si no lo es no hace nada
      dispatch({
        type: SAVE_INSURANCES_LIST,
        payload: data.data,
      });
    })
    .catch((err) => {
      console.error(err);
      message("error", "Error", err.message + "catch");
    });
};
