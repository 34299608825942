//Login's reducer

import {
  SAVE_BUSINESS_UNITS_LIST,
  LOADING_SAVE_BUSINESS_UNITS_LIST,
  SAVE_IDENTITIES_LIST,
  SAVE_GENDER_LIST,
  SAVE_INSURANCES_LIST,
} from "../actions/actionTypes";

const initialState = {
  businessUnits: [],
  businessUnitsLoading: false,
};
export const businessUnitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_BUSINESS_UNITS_LIST:
      return {
        ...state,
        businessUnits: action.payload,
        businessUnitsLoading: action.loading,
      };
    case SAVE_IDENTITIES_LIST:
      return {
        ...state,
        identities: action.payload,
      };
    case SAVE_GENDER_LIST:
      return {
        ...state,
        genders: action.payload,
      };
    case SAVE_INSURANCES_LIST:
      return {
        ...state,
        insurances: action.payload,
      };

    case LOADING_SAVE_BUSINESS_UNITS_LIST:
      return {
        ...state,
        businessUnitsLoading: action.loading,
      };

    default:
      return state;
  }
};
