import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Select from "react-select";
import tableStyles from "../Layouts/tableStyle.module.scss";
import leftArrow from "../../assets/img/icons/leftArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewAuditory,
  getAuditCheckList,
  getAuditoriesTypes,
} from "../../actions/auditoriesAction";
import { isEmptyOrUndefined, message } from "../../helpers/helpers";
import { customSelectNew } from "../Layouts/react-select-custom";
import { getProcessByIdbUnit } from "../../actions/businessUnits";
import { getAllUsers } from "../../actions/documentActions";
export const AuditoriesForm = (props) => {
  const store = useSelector((state) => state);
  const arrBusinessList = store.businessUnitsReducer.businessUnits;
  const [businessList, setBusinessList] = useState([]);
  const token = store.loginReducer.Authorization;
  const [dataUserInput, setDataUserInput] = useState({});
  const [arrUsers, setArrUsers] = useState([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState("");
  const [arrTypes, setArrTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [arrAuditProcess, setArrAuditProcess] = useState([]);
  const [selectedAuditProcess, setSelectedAuditProcess] = useState("");
  const [arrCheckList, setArrCheckList] = useState([]);
  const [selectedCheckList, setSelectedCheckList] = useState("");
  const [selectedLeader, setSelectedLeader] = useState("");
  const [selectedAuditor, setSelectedAuditor] = useState("");
  const [selectedAudited, setSelectedAudited] = useState("");
  /* -------------------------------------------------------------------------- */
  /*                           GET DOCUMENT TEMPLATES                           */
  /* -------------------------------------------------------------------------- */
  // const dispatch = useDispatch();
  useEffect(() => {
    cleanFilters();
    getTypes();

    getUsers();
  }, []);
  const getTypes = async () => {
    const result = await getAuditoriesTypes(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrTypes = [];
      result.forEach((e) => {
        arrTypes.push({ value: e.id, label: e.name });
      });
      setArrTypes(arrTypes);
    }
  };
  const getCheckLists = async (idbUnit) => {
    const result = await getAuditCheckList(idbUnit, token);
    if (Array.isArray(result) && result.length > 0) {
      let arrCheckList = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrCheckList.push({ value: e.id, label: e.name });
      });
      setArrCheckList(arrCheckList);
    }
  };

  const cleanFilters = () => {
    let arrBusiness = [];
    if (Array.isArray(arrBusinessList) && arrBusinessList.length > 0) {
      arrBusinessList.forEach((e) => {
        arrBusiness.push({ value: e.id, label: e.name });
      });
      setBusinessList(arrBusiness);
    }
  };
  const getProcess = async (bUnit) => {
    let result = await getProcessByIdbUnit(bUnit, token);
    if (Array.isArray(result) && result.length > 0) {
      let arrProcess = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrProcess.push({ value: e.id, label: e.name });
      });
      setArrAuditProcess(arrProcess);
    }
  };
  const getUsers = async () => {
    const result = await getAllUsers(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrUs = [];
      result.forEach((e) => {
        arrUs.push({ value: e.id, label: e.fullName });
      });
      setArrUsers(arrUs);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               CREATE AUDITORY                              */
  /* -------------------------------------------------------------------------- */
  const submitAuditory = async () => {
    if (
      isEmptyOrUndefined(dataUserInput.auditName) ||
      isEmptyOrUndefined(selectedLeader) ||
      isEmptyOrUndefined(selectedAuditor) ||
      isEmptyOrUndefined(selectedAudited) ||
      isEmptyOrUndefined(dataUserInput.objetiveName) ||
      // isEmptyOrUndefined(dataUserInput.description) ||
      isEmptyOrUndefined(dataUserInput.startDate) ||
      isEmptyOrUndefined(dataUserInput.endDate)
    ) {
      return message(
        "warning",
        "Advertencia",
        "Por favor llene todos los campos  obligatorios"
      );
    }
    const saveAuditory = await createNewAuditory(
      dataUserInput.auditName,
      dataUserInput.startDate,
      dataUserInput.endDate,
      selectedLeader,
      selectedType,
      selectedAuditProcess,
      selectedAuditor,
      selectedAudited,
      dataUserInput.objetiveName,
      selectedCheckList,
      dataUserInput.description,
      token
    );
    if (!isEmptyOrUndefined(saveAuditory)) {
      props.hideForm();
      return message("success", "Éxito", "Auditoria creada exitosamente");
    } else {
      return message("error", "Error", "No se pudo crear la auditoria");
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                           HANDLE CHANGES SECTION                           */
  /* -------------------------------------------------------------------------- */

  const handleChangeInput = (e) => {
    let { value, name } = e.target;
    // console.log(value, name);
    setDataUserInput({ ...dataUserInput, [name]: value });
  };
  const handleChangeBusinessUnit = (e) => {
    setArrCheckList([]);
    setArrAuditProcess([]);
    setSelectedCheckList(-1);
    setSelectedAuditProcess(-1);
    if (!isEmptyOrUndefined(e.value)) {
      getCheckLists(e.value);
      getProcess(e.value);
      setSelectedBusinessUnit(e.value);
    }
  };
  const handleChangeTypes = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedType(e.value);
    }
  };
  const handleChangeAuditProcess = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedAuditProcess(e.value);
    }
  };
  const handleChangeCheckList = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedCheckList(e.value);
    }
  };
  const handleChangeLeader = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedLeader(e.value);
    }
  };
  const handleChangeAuditor = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedAuditor(e.value);
    }
  };
  const handleChangeAudited = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedAudited(e.value);
    }
  };

  return (
    <>
      <div className={"w-100"}>
        <div className={`${tableStyles.ItemSmall} mt-5`}>
          <Row
            className="d-flex justify-content-space-between "
            style={{ width: "90%" }}
          >
            <Col xs={12} className="ml-5 d-flex">
              <button onClick={props.hideForm}>
                <img src={leftArrow} alt="back"></img>
              </button>
              <h2 className="text-dark mb-3" style={{ marginLeft: "4%" }}>
                Crear Auditoria
              </h2>
            </Col>
          </Row>
        </div>
        <div className={tableStyles.centeredContainer}>
          <div className={`${tableStyles.container1} mt-2`}>
            <Card
              className={`${tableStyles.fourBorderRadiuses} ${tableStyles.container} ${tableStyles.bgColorClearBlue}`}
              style={{ borderWidth: 0 }}
            >
              <h6 className={`${tableStyles.clearBlueText} mt-2`}>
                Información Básica
              </h6>
              {/* ---------------------------- ROW 1 ---------------------------------- */}
              <Row className="d-flex" style={{ width: "100%" }}>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Nombre de auditoría
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    className="register-inputs"
                    name="auditName"
                    onChange={(e) => handleChangeInput(e)}
                  />
                </Col>

                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Unidad de negocio
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={businessList}
                    styles={customSelectNew}
                    value={businessList.find(
                      (obj) => obj.value === selectedBusinessUnit
                    )}
                    onChange={(e) => handleChangeBusinessUnit(e)}
                  ></Select>
                </Col>

                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Fecha inicio
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    name="startDate"
                    onChange={(e) => handleChangeInput(e)}
                    className="register-inputs"
                    type="date"
                  />
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Fecha final
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    name="endDate"
                    onChange={(e) => handleChangeInput(e)}
                    className="register-inputs"
                    type="date"
                  />
                </Col>
              </Row>
              {/* ---------------------------- ROW 2 ---------------------------------- */}
              <Row className="d-flex" style={{ width: "100%" }}>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Tipo
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrTypes}
                    styles={customSelectNew}
                    value={arrTypes.find((obj) => obj.value === selectedType)}
                    name="type"
                    onChange={(e) => handleChangeTypes(e)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Proceso a auditar
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrAuditProcess}
                    styles={customSelectNew}
                    value={arrAuditProcess.find(
                      (obj) => obj.value === selectedAuditProcess
                    )}
                    onChange={(e) => handleChangeAuditProcess(e)}
                  ></Select>
                </Col>

                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Auditor
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrUsers}
                    styles={customSelectNew}
                    value={arrUsers.find(
                      (obj) => obj.value === selectedAuditor
                    )}
                    name="type"
                    onChange={(e) => handleChangeAuditor(e)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Auditado
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrUsers}
                    styles={customSelectNew}
                    value={arrUsers.find(
                      (obj) => obj.value === selectedAudited
                    )}
                    name="type"
                    onChange={(e) => handleChangeAudited(e)}
                  ></Select>
                </Col>
              </Row>
              {/* ---------------------------- ROW 3 ---------------------------------- */}
              <Row className="d-flex" style={{ width: "100%" }}>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Líder responsable<span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrUsers}
                    styles={customSelectNew}
                    value={arrUsers.find((obj) => obj.value === selectedLeader)}
                    name="type"
                    onChange={(e) => handleChangeLeader(e)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Objetivo<span className={"text-danger"}>*</span>
                  </p>
                  <input
                    name="objetiveName"
                    onChange={(e) => handleChangeInput(e)}
                    className="register-inputs"
                  />
                </Col>
                <Col xs={6}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Lista de chequeo<span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrCheckList}
                    styles={customSelectNew}
                    value={arrCheckList.find(
                      (obj) => obj.value === selectedCheckList
                    )}
                    onChange={(e) => handleChangeCheckList(e)}
                  ></Select>
                </Col>
              </Row>
              {/* ---------------------------- ROW 4 ---------------------------------- */}
              <Row className="d-flex mb-2" style={{ width: "100%" }}>
                <Col xs={6}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Descripción
                  </p>
                  <textarea
                    name="description"
                    onChange={(e) => handleChangeInput(e)}
                    style={{ height: "3rem" }}
                    placeholder="Escribir..."
                    rows="5"
                    cols="40"
                    className="register-inputs"
                  />
                </Col>
              </Row>
            </Card>
          </div>
        </div>
        <div className={`customTabs__Footer mt-5`} style={{ width: "84%" }}>
          <Button
            onClick={() => submitAuditory()}
            className={tableStyles.btnPrimary}
          >
            Guardar
          </Button>
          <Button onClick={props.hideForm} className={tableStyles.btnSecondary}>
            Cancelar
          </Button>
        </div>
      </div>
    </>
  );
};
