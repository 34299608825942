import React, { useEffect, useState } from "react";
import { Button, Card, CardGroup, Col, Row } from "react-bootstrap";
import Select from "react-select";
import tableStyles from "../Layouts/tableStyle.module.scss";
import minus from "../../assets/img/icons/minus.svg";
import Add from "../../assets/img/icons/Add.svg";
import blueBall from "../../assets/img/icons/blueBall.svg";
import leftArrow from "../../assets/img/icons/leftArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../actions/documentActions";
import { isEmptyOrUndefined, message } from "../../helpers/helpers";
import { customSelectNew } from "../Layouts/react-select-custom";
import deepcopy from "deepcopy";
import {
  createNewActionPlan,
  getAllReasons,
  getAllSources,
} from "../../actions/actionPlanAction";
import {
  getAllBusinessUnits,
  getAllMeasurementFrequency,
  getProcessByIdbUnit,
} from "../../actions/businessUnits";
import { useHistory } from "react-router-dom";
import {
  createNewComitee,
  getAllComitesTypes,
} from "../../actions/comitesAction";
export const NewComiteForm = (props) => {
  const store = useSelector((state) => state);
  const arrBusinessList = store.businessUnitsReducer.businessUnits;
  const token = store.loginReducer.Authorization;
  const [arrUsers, setArrUsers] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState("");
  const [arrSources, setArrSources] = useState([]);
  const [selectedSource, setSelectedSource] = useState(-1);
  const [mesurementFrequency, setMesurementFrequency] = useState([]);
  const [selectedMesurementFrequency, setSelectedMesurementFrequency] =
    useState(-1);
  const [arrReasons, setArrReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState(-1);
  const [selectedLeader, setSelectedLeader] = useState(-1);
  const [selectedApprover, setSelectedApprover] = useState(-1);
  const [selectedProcess, setSelectedProcess] = useState(-1);
  const [dataUserInput, setDataUserInput] = useState({});
  const [addNumberRender, setAddNumberRender] = useState([
    { activity: "", accountable: "" },
  ]);
  const history = useHistory();
  const arrComites = store.comitesReducer.comitesTypes;
  const [arrComiTypes, setArrComiTypes] = useState([]);
  const [selectedComitype, setSelectedComitype] = useState(-1);
  const dispatch = useDispatch();
  const [arrAuditProcess, setArrAuditProcess] = useState([]);
  const [selectedAuditProcess, setSelectedAuditProcess] = useState("");
  const repFalse = [
    { value: 1, label: "Cada jueves y cada martes" },
    { value: 2, label: "Cada lunes y cada viernes" },
  ];
  const [selectedRepetition, setSelectedRepetition] = useState({});
  // console.log("Arr comite=> ", arrComites);
  /* -------------------------------------------------------------------------- */
  /*                           GET DOCUMENT TEMPLATES                           */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    dispatch(getAllBusinessUnits());
    getUsers();
    getSources();
    getMeasurementFrequency();
    getReasons();
    getComiTypes();
    dispatch(getAllComitesTypes());
    if (arrBusinessList.length > 0) {
      cleanFilters();
    }
  }, []);

  console.log("Tipos=>", arrComiTypes);

  const getSources = async () => {
    const result = await getAllSources(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrSources = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrSources.push({ value: e.id, label: e.name });
      });
      setArrSources(arrSources);
    }
  };
  const getUsers = async () => {
    const result = await getAllUsers(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrUs = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrUs.push({ value: e.id, label: e.fullName });
      });
      setArrUsers(arrUs);
    }
  };
  const getComiTypes = async () => {
    // const result = await getAllComitesTypes(token);
    // console.log("entró", result);
    if (Array.isArray(arrComites) && arrComites.length > 0) {
      let arrCom = [{ value: -1, label: "-Seleccione-" }];
      arrComites.forEach((e) => {
        arrCom.push({ value: e.id, label: e.name });
      });
      console.log("ArrCom dentro", arrCom);
      setArrComiTypes(arrCom);
    }
  };

  const cleanFilters = () => {
    let arrBusiness = [];
    if (Array.isArray(arrBusinessList) && arrBusinessList.length > 0) {
      arrBusinessList.forEach((e) => {
        arrBusiness.push({ value: e.id, label: e.name });
      });
      setBusinessList(arrBusiness);
    }
  };
  const getMeasurementFrequency = async () => {
    let result = await getAllMeasurementFrequency(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrMeasurementFrequency = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrMeasurementFrequency.push({ value: e.id, label: e.name });
      });
      setMesurementFrequency(arrMeasurementFrequency);
    }
  };
  const getReasons = async () => {
    let result = await getAllReasons(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrReasons = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrReasons.push({ value: e.id, label: e.name });
      });
      setArrReasons(arrReasons);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                            Helper JSX Functions                            */
  /* -------------------------------------------------------------------------- */

  const addActivity = (e, i) => {
    const activity = {
      activity: "",
      accountable: "",
    };
    setAddNumberRender([...addNumberRender, activity]);
  };

  const removeActivity = (e, i) => {
    //console.log("Index a elimnar", i);
    console.log("Original ", addNumberRender);
    let copy = deepcopy(addNumberRender);
    console.log("Copia ", copy);
    let index = copy.indexOf(i);
    console.log("INDEX ", i);
    copy.splice(1, 1);
    console.log("Copia despues de borrar ", copy);
    setAddNumberRender(copy);
  };
  console.log("addnumb=>", addNumberRender);
  /* ---------------------------- Submit plan action ---------------------------- */
  const submitActionPlan = async () => {
    if (
      isEmptyOrUndefined(dataUserInput.name) ||
      isEmptyOrUndefined(selectedComitype) ||
      isEmptyOrUndefined(selectedAuditProcess) ||
      isEmptyOrUndefined(dataUserInput.firstMeet) ||
      isEmptyOrUndefined(dataUserInput.hour) ||
      isEmptyOrUndefined(selectedRepetition) ||
      isEmptyOrUndefined(dataUserInput.description) ||
      isEmptyOrUndefined(dataUserInput.target)
    ) {
      return message(
        "warning",
        "Advertencia",
        "Por favor llene todos los campos  obligatorios"
      );
    }
    const saveDoc = await createNewComitee(
      dataUserInput.name,
      selectedComitype,
      selectedAuditProcess,
      dataUserInput.firstMeet,
      dataUserInput.hour,
      selectedRepetition,
      dataUserInput.description,
      dataUserInput.target,
      token
    );
    if (saveDoc) {
      if (!isEmptyOrUndefined(saveDoc.docId)) {
        handleBackButton();
        return message("success", "Éxito", "Comité creado exitosamente");
      } else {
        return message("error", "Error", "No se pudo guardar el documento");
      }
    }
  };
  const getProcess = async (bUnit) => {
    let result = await getProcessByIdbUnit(bUnit, token);
    if (Array.isArray(result) && result.length > 0) {
      let arrProcess = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrProcess.push({ value: e.id, label: e.name });
      });
      setArrAuditProcess(arrProcess);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                           HANDLE CHANGES SECTION                           */
  /* -------------------------------------------------------------------------- */
  const handleChangeInput = (e) => {
    let { value, name } = e.target;
    setDataUserInput({ ...dataUserInput, [name]: value });
  };
  const handleChangeType = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedComitype(e.value);
    }
  };

  const handleChangeActivity = (e, i) => {
    const { name, value } = e.target;
    console.log("name=>", name, value, i);
    let copy = deepcopy(addNumberRender);
    copy[i][name] = value;
    setAddNumberRender(copy);
  };
  const handleChangeBusinessUnit = (e) => {
    setSelectedAuditProcess(-1);
    // setSelectedBranchOffice(-1);
    if (!isEmptyOrUndefined(e.value)) {
      getProcess(e.value);
      // getBranchOffices(e.value);
      setSelectedBusinessUnit(e.value);
    }
  };
  const handleChangeAuditProcess = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedAuditProcess(e.value);
    }
  };
  const handleChangeRep = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedRepetition(e.value);
    }
  };
  // console.log("add=R>", addNumberRender);
  const handleBackButton = () => {
    history.push("/comites/listadoComites");
  };
  return (
    <>
      <div className={"w-100"}>
        <div className={`${tableStyles.ItemSmall} mt-5`}>
          <Row
            className="d-flex justify-content-space-between "
            style={{ width: "100%" }}
          >
            <Col xs={12} className="ml-5 d-flex" style={{ width: "80%" }}>
              <button onClick={handleBackButton}>
                <img src={leftArrow} alt="back"></img>
              </button>
              <h2 className="text-dark mb-3" style={{ marginLeft: "4%" }}>
                Crear Comité
              </h2>
            </Col>
          </Row>
        </div>
        <div className={tableStyles.centeredContainer}>
          <div className={`${tableStyles.container1} mt-2`}>
            <Row className={"d-flex w-100  justify-content-space-between"}>
              <Col xs={9}>
                <Card
                  className={`${tableStyles.fourBorderRadiuses} ${tableStyles.container} ${tableStyles.bgColorClearBlue} pb-4`}
                  style={{ borderWidth: 0 }}
                >
                  <h6 className={`${tableStyles.clearBlueText} mt-3 fontBold`}>
                    Información Básica
                  </h6>
                  {/* ---------------------------- ROW 1 ---------------------------------- */}
                  <Row className="d-flex" style={{ width: "100%" }}>
                    <Col xs={4}>
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Nombre del comité
                        <span className={"text-danger"}>*</span>
                      </p>
                      <input
                        name="name"
                        className="register-inputs"
                        onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                    <Col xs={4}>
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Tipo
                        <span className={"text-danger"}>*</span>
                      </p>
                      <Select
                        options={arrComiTypes}
                        styles={customSelectNew}
                        placeholder={<div>Seleccione</div>}
                        value={arrComiTypes.find(
                          (obj) => obj.value === selectedComitype
                        )}
                        onChange={(e) => handleChangeType(e)}
                      ></Select>
                    </Col>
                    <Col xs={4}>
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Unidad de negocio
                        <span className={"text-danger"}>*</span>
                      </p>
                      <Select
                        options={businessList}
                        styles={customSelectNew}
                        placeholder={<div>Seleccione</div>}
                        value={businessList.find(
                          (obj) => obj.value === selectedBusinessUnit
                        )}
                        onChange={(e) => handleChangeBusinessUnit(e)}
                      ></Select>
                    </Col>
                  </Row>
                  {/* ---------------------------- ROW 2 ---------------------------------- */}
                  <Row className="d-flex" style={{ width: "100%" }}>
                    <Col xs={4}>
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Primera reunión
                        <span className={"text-danger"}>*</span>
                      </p>
                      <input
                        type="date"
                        name="firstMeet"
                        className="register-inputs"
                        onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                    <Col xs={4}>
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Hora
                        <span className={"text-danger"}>*</span>
                      </p>
                      <input
                        maxLength="2"
                        type="time"
                        name="hour"
                        className="register-inputs"
                        onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                    <Col xs={4}>
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Proceso
                        <span className={"text-danger"}>*</span>
                      </p>
                      <Select
                        options={arrAuditProcess}
                        styles={customSelectNew}
                        placeholder={<div>Seleccione</div>}
                        value={arrAuditProcess.find(
                          (obj) => obj.value === selectedAuditProcess
                        )}
                        onChange={(e) => handleChangeAuditProcess(e)}
                      ></Select>
                    </Col>
                  </Row>
                  {/* ---------------------------- ROW 3 ---------------------------------- */}
                  <Row className="d-flex" style={{ width: "100%" }}>
                    <Col xs={4}>
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Descripción
                        <span className={"text-danger"}>*</span>
                      </p>
                      <textarea
                        name="description"
                        style={{ height: "3.5rem" }}
                        placeholder="Escribir..."
                        rows="10"
                        cols="100"
                        className="register-inputs"
                        onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                    <Col xs={4}>
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Objetivo
                        <span className={"text-danger"}>*</span>
                      </p>
                      <textarea
                        name="target"
                        style={{ height: "3.5rem" }}
                        placeholder="Escribir..."
                        rows="10"
                        cols="100"
                        className="register-inputs"
                        onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                    <Col xs={4}>
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Repetición
                        <span className={"text-danger"}>*</span>
                      </p>
                      <Select
                        placeholder={""}
                        options={repFalse}
                        styles={customSelectNew}
                        placeholder={<div>Seleccione</div>}
                        value={repFalse.find(
                          (obj) => obj.value === selectedRepetition
                        )}
                        onChange={(e) => handleChangeRep(e)}
                      ></Select>
                    </Col>{" "}
                  </Row>
                </Card>
                {/* /* -------------------------------- BELOW ROW ------------------------------- */}
                <div style={{ padding: "15px" }}>
                  <h6 className={`${tableStyles.clearBlueText} mt-2 fontBold`}>
                    Itinerario principal
                  </h6>
                  {/* /* ---------------------------------- ROW 2 CONDITIONAL --------------------------------- */}
                  {addNumberRender.length > 0 &&
                    addNumberRender.map((e, i) => {
                      return (
                        <Row
                          key={i}
                          className="d-flex"
                          style={{ width: "109%" }}
                        >
                          <Col xs={1} style={{ width: "1.333333%" }}>
                            <button
                              onClick={
                                i === 0 ? null : (e) => removeActivity(e, i)
                              }
                            >
                              <img
                                src={i === 0 ? blueBall : minus}
                                style={{ marginTop: "", width: "16px" }}
                              ></img>
                            </button>
                          </Col>
                          <Col xs={4}>
                            <div>
                              <input
                                name="activity"
                                placeholder="Título del tema"
                                onChange={(e) => handleChangeActivity(e, i)}
                                className="register-inputs"
                                type="text"
                              ></input>
                            </div>
                          </Col>
                          <Col xs={7}>
                            <input
                              name="activity"
                              placeholder="Descripción"
                              onChange={(e) => handleChangeActivity(e, i)}
                              className="register-inputs"
                              type="text"
                            ></input>
                          </Col>
                        </Row>
                      );
                    })}
                  {/* /* ---------------------------------- ROW 4 --------------------------------- */}
                  <Row className="d-flex" style={{ width: "100%" }}>
                    <Col xs={6}>
                      <button onClick={addActivity}>
                        <div className="d-flex">
                          <img src={Add}></img>
                          <p className={`${tableStyles.crudModalLabel} `}>
                            Agregar actividad
                          </p>
                        </div>
                      </button>
                    </Col>
                  </Row>
                </div>
                <div style={{ padding: "15px" }}>
                  <h6 className={`${tableStyles.clearBlueText} mt-2 fontBold`}>
                    Integrantes
                  </h6>

                  {addNumberRender.length > 0 &&
                    addNumberRender.map((e, i) => {
                      return (
                        <Row
                          key={i}
                          className="d-flex"
                          style={{ width: "109%" }}
                        >
                          <Col xs={1} style={{ width: "1.333333%" }}>
                            <button
                              onClick={
                                i === 0 ? null : (e) => removeActivity(e, i)
                              }
                            >
                              <img
                                src={i === 0 ? blueBall : minus}
                                style={{ marginTop: "", width: "16px" }}
                              ></img>
                            </button>
                          </Col>
                          <Col xs={7}>
                            <div>
                              <input
                                name="activity"
                                placeholder="Usuario / Cargo"
                                onChange={(e) => handleChangeActivity(e, i)}
                                className="register-inputs"
                                type="text"
                              ></input>
                            </div>
                          </Col>
                          <Col xs={4}>
                            <input
                              name="activity"
                              placeholder="Rol"
                              onChange={(e) => handleChangeActivity(e, i)}
                              className="register-inputs"
                              type="text"
                            ></input>
                          </Col>
                        </Row>
                      );
                    })}
                  <Row className="d-flex" style={{ width: "100%" }}>
                    <Col xs={6}>
                      <button onClick={addActivity}>
                        <div className="d-flex">
                          <img src={Add}></img>
                          <p className={`${tableStyles.crudModalLabel} `}>
                            Agregar usuario
                          </p>
                        </div>
                      </button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col
                xs={3}
                style={{
                  minHeight: "20rem",
                  maxHeight: "20rem",
                  borderRadius: "6px",
                  border: "1px solid #6F9CFF",
                  background:
                    "url(https://image.freepik.com/foto-gratis/ejecutivo-presentando-estrategia-trabajo_329181-12073.jpg) no-repeat center",
                }}
              ></Col>
            </Row>
            {/* /* -------------------------------- BELOW ROW ------------------------------- */}
            <div style={{ padding: "15px" }}>
              {/* /* ---------------------------------- ROW 2 CONDITIONAL --------------------------------- */}

              {/* /* ---------------------------------- ROW 4 --------------------------------- */}
            </div>
          </div>
        </div>
        <div className={`customTabs__Footer mt-5`} style={{ width: "90%" }}>
          <Button
            onClick={() => submitActionPlan()}
            className={tableStyles.btnPrimary}
          >
            Crear comité
          </Button>
          <Button
            onClick={handleBackButton}
            className={tableStyles.btnSecondary}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </>
  );
};
