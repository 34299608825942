import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Export from "../../assets/img/icons/exportar.svg";
import blueAddNew from "../../assets/img/icons/blueAddNew.svg";
import lupa from "../../assets/img/icons/lupa.svg";
import dotThree from "../../assets/img/icons/dotThree.svg";
import fals from "../../assets/img/icons/False.svg";
import Pagination from "react-js-pagination";
import GenericTable from "../Layouts/GenericTable";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
import { getAllAuditories } from "../../actions/auditoriesAction";
import { AuditoriesForm } from "./AuditoriesForm";
import { getAllBusinessUnits } from "../../actions/businessUnits";
function AuditoriesMasterTable(props) {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const arrAuditories = store.auditoriesReducer.auditories;
  let i = 0;

  const [filters, setFilter] = useState({
    search: "",
    page: 1,
    perpage: 10,
  });
  const [contador, setContador] = useState(0);
  useEffect(
    () => {
      dispatch(getAllBusinessUnits());
      dispatch(getAllAuditories());
    },
    [contador, filters.page, showForm] //cada vez que esto cambie, ejecutar la funcion de arriba.
  );

  const handlePageChange = (val) => {
    setFilter({
      ...filters, // División de objetos
      page: val,
    });
  };

  const handleSearchInput = (val) => {
    setFilter({
      ...filters, //el resto no los quiero cambiar
      search: val, //solo search
    });
  };
  const goToForm = () => {
    setShowForm(true);
  };

  const renderElement = (elem) => {
    return (
      <tr key={elem.code}>
        <td className="col-md-3 text-center text-primary">
          <u>
            <a href="#">{elem.name}</a>
          </u>
        </td>
        <td className="col-md-1 text-center">{elem.type}</td>
        <td className="col-md-1 text-center">{elem.process}</td>
        <td className="col-md-2 text-center">{elem.auditor}</td>
        <td className="col-md-2 text-center">{elem.auditedName}</td>
        <td className="col-md-1 text-center">{elem.startDate}</td>
        <td className="col-md-1 text-center">{elem.endDate}</td>
        <td className="col-md-1 text-center">
          <div
            className={"center"}
            style={{
              margin: "0 auto",
              backgroundColor: `${elem.bgColor}`,
              width: "12px",
              height: "12px",
              borderRadius: "100%",
            }}
            title={elem.status}
          ></div>
        </td>
        <td
          className="col-md-1 text-center"
          style={{ color: "blue", paddingRight: "10px" }}
        >
          <button style={{ margin: "0 auto" }}>
            <img src={dotThree}></img>
          </button>
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (arrAuditories !== undefined) {
      const elem2 = arrAuditories;
      elemMap = elem2.map((elem) => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };

  const renderHeaders = [
    <th style={{ borderTopLeftRadius: "10px" }} key={i}>
      Nombre
    </th>,
    <th key={i + 1}>Tipo</th>,
    <th key={i + 1}>Proceso</th>,
    <th key={i + 1}>Auditor</th>,
    <th key={i + 1}>Auditado</th>,
    <th key={i + 1}>F.Inicio</th>,
    <th key={i + 1}>F.Final</th>,
    <th key={i + 1}>Est.</th>,
    <th style={{ borderTopRightRadius: "10px" }} key={i + 1}></th>,
  ];

  return (
    <>
      {showForm ? (
        <AuditoriesForm hideForm={() => setShowForm(false)}></AuditoriesForm>
      ) : (
        <div className={tableStyles.container}>
          <h1
            className={`${tableStyles.clearBlueText} ${tableStyles.marginLeftsix}`}
          >
            Listado de auditorias &nbsp;
            <a>
              <img src={fals}></img>
            </a>
          </h1>

          <div className={tableStyles.tableArea}>
            <div className={tableStyles.mainRow}>
              <div className="d-flex justify-content-space-between filterDiv-ListView">
                <div>
                  <p className={tableStyles.crudModalLabel}>Auditor</p>

                  <select placeholder={"Seleccione"} className="select-register">
                    <option value={""}>Jose David</option>
                    <option value={""}>Kelvin Martinez</option>
                  </select>
                </div>
                &nbsp;
                <div>
                  <p className={tableStyles.crudModalLabel}>Tipo</p>
                  <select placeholder={"Seleccione"} className="select-register">
                    <option value={""}>Auditoria Interna</option>
                    <option value={""}>Auditoria Externa</option>
                  </select>
                </div>
                &nbsp;
                {/* <div>
                  <p className={tableStyles.crudModalLabel}>Proceso</p>
                  <select placeholder={"Seleccione"} className="select-register">
                    <option value={""}>Seleccione</option>
                  </select>
                </div> */}
                &nbsp;
                <div className="mt-4">
                  <input
                    className={tableStyles.searchUsersAlt}
                    type="text"
                    placeholder="Buscar"
                    onChange={(e) => handleSearchInput(e.target.value)}
                  />
                  <button onClick={() => setContador(contador + 1)}>
                    <img
                      src={lupa}
                      alt="User icon"
                      className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                    />
                  </button>
                </div>
              </div>

              <div className={tableStyles.groupElems}>
                <small className="mt-4">
                  <b onClick={goToForm} className="buttonNewOrCreate">
                    Nuevo
                    <img
                      width="15.6px"
                      height="20px"
                      src={blueAddNew}
                      alt="Form"
                      className={`${tableStyles.iconSvg} buttonNewOrCreate_img`}
                    />
                  </b>
                </small>
                
              </div>
            </div>

            <GenericTable
              theadTheme={`${tableStyles.theadClearBlue}`}
              headers={renderHeaders}
            >
              {listElem()}
            </GenericTable>

            <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                Pag. {store.cupsReducer.total ? filters.page : ""}
                {" de "}
                {Math.ceil(store.cupsReducer.total / filters.perpage)
                  ? Math.ceil(store.cupsReducer.total / filters.perpage)
                  : ""}{" "}
                ({store.cupsReducer.total} encontrados)
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={10}
                totalItemsCount={store.cupsReducer.total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default AuditoriesMasterTable;
