import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { message, TokenIsExpired } from "../helpers/helpers";

import {
  LOADING_SAVE_USERS_LIST,
  SAVE_USERS_LIST
} from "../actions/actionTypes";

export const getAllUsers = () => (dispatch, getState) => {
  dispatch({
    type: LOADING_SAVE_USERS_LIST,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;

  fetch(`${URL_GATEWAY}${API_VERSION}/users`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        console.log(data.statusCode);
        TokenIsExpired(data.statusCode, dispatch);
        return message("error", "Error", data.message);
      }
      //Si no es 200 va a verificar si es un 401, si no lo es no hace nada
      dispatch({
        type: SAVE_USERS_LIST,
        payload: data.data,
        loading: false,
      });
    })
    .catch((err) => {
      console.error(err);
      // message("error", "Error", err.message + "catch");
    });
};