import React from "react";
import { Col, Row } from "react-bootstrap";
import tableStyles from "../Layouts/tableStyle.module.scss";
import logoFoca from "../../assets/img/icons/logoFoca.svg";
import { isEmptyOrUndefined } from "../../helpers/helpers";

export const Template = ({ data }) => {
  return (
    <>
      <Row className="d-flex mb-3" style={{ width: "100%" }}>
        <Col
          xs={12}
          style={{ borderRadius: "5px" }}
          className={`w-100 bg-success `}
        >
          <h3
            style={{ margin: "0 auto" }}
            className={` text-light text-center  mb-3 mt-3 fontBold`}
          >
            COPIA CONTROLADA
          </h3>
        </Col>
      </Row>
      {/* /* ------------------------------- SECOND ROW ------------------------------- */}
      <Row className="d-flex mb-4" style={{ width: "100%" }}>
        <table className={`${tableStyles.tableBorder}`}>
          <tr>
            <td
              className={`${tableStyles.tableBorder}`}
              rowspan="3"
              style={{ width: "10px" }}
            >
              <img style={{ width: "19rem" }} src={logoFoca}></img>
            </td>
            <td
              className={`${tableStyles.tableBorder} text-center`}
              rowspan="2"
            >
              FUNDACIÓN OFTALMOLÓGICA DEL CARIBE
            </td>
            <td className={`${tableStyles.tableBorder}`}>CÓDIGO</td>
            <td className={`${tableStyles.tableBorder}`}>PC-FAA-550</td>
          </tr>
          <tr>
            <td className={`${tableStyles.tableBorder}`}>VERSIÓN</td>
            <td className={`${tableStyles.tableBorder}`}>003</td>
          </tr>
          <tr>
            <td className={`${tableStyles.tableBorder} text-center`}>
              PROTOCOLO DE AYUDAS DIAGNOSTICAS COVID-19
            </td>
            <td className={`${tableStyles.tableBorder}`}>FECHA DE VIGENCIA</td>
            <td className={`${tableStyles.tableBorder}`}>2020-09-25</td>
          </tr>
        </table>
      </Row>
      {/* /* -------------------------------- BODY ROW -------------------------------- */}
      <Row
        className={`d-flex ${tableStyles.centeredContainer}`}
        style={{ width: "100%" }}
      >
        <Col xs={12}>
          <div
            // className={`d-flex justify-content-center`}
            dangerouslySetInnerHTML={{
              __html: isEmptyOrUndefined(data)
                ? "<h1 class='text-secondary text-center'>Documento sin contenido para mostrar</h1>"
                : data,
            }}
          ></div>
        </Col>
      </Row>
      {/* /* ------------------------------- FOOTER ROW ------------------------------- */}
      <Row className="d-flex mt-5" style={{ width: "100%" }}>
        <table className={`${tableStyles.tableBorder}`}>
          <tr>
            <td className={`${tableStyles.tableBorder} fontBold`}>Elaboró</td>
            <td className={`${tableStyles.tableBorder} fontBold`}>Revisó</td>
            <td className={`${tableStyles.tableBorder} fontBold`}>Aprovó</td>
          </tr>
          <tr>
            <td className={`${tableStyles.tableBorder}`}>
              <div>
                <p style={{ margin: 0 }}>
                  <span className="fontBold">
                    Mónica Patricia Sierra Merlano
                  </span>
                </p>
                <p style={{ margin: 0 }}>Director médico</p>
              </div>
            </td>
            <td className={`${tableStyles.tableBorder}`}>
              {" "}
              <div>
                <p style={{ margin: 0 }}>
                  <span className="fontBold">Maria Clara Pizarro Garcia</span>
                </p>
                <p style={{ margin: 0 }}>Directora de calidad</p>
              </div>
            </td>
            <td className={`${tableStyles.tableBorder}`}>
              {" "}
              <div>
                <p style={{ margin: 0 }}>
                  <span className="fontBold">Jorge José Martínez Ramírez</span>
                </p>
                <p style={{ margin: 0 }}>Gerente General</p>
              </div>
            </td>
          </tr>
        </table>
      </Row>
    </>
  );
};
