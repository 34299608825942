import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { message, TokenIsExpired } from "../helpers/helpers";

import {
  LOADING_SAVE_DOCUMENT_LIST,
  SAVE_DOCUMENT_LIST,
  SAVE_DOCUMENT_TYPES,
  SAVE_INPROCESS_DOCUMENT_LIST,
  LOADING_SAVE_INPROCESS_DOCUMENT_LIST,
  SAVE_OBSOLETE_DOCUMENTS,
  LOADING_SAVE_OBSOLETE_DOCUMENTS,
  SAVE_GET_DOCUMENT,
} from "../actions/actionTypes";

export const getADocument = async (id, token) => {
  let data = { id };
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/document/getInProcess`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};

export const getAllDocuments = () => (dispatch, getState) => {
  dispatch({
    type: LOADING_SAVE_DOCUMENT_LIST,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;


  fetch(`${URL_GATEWAY}${API_VERSION}/document/getAll`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        console.log(data.statusCode);
        TokenIsExpired(data.statusCode, dispatch);
        return message("error", "Error", data.message);
      }
      dispatch({
        type: SAVE_DOCUMENT_LIST,
        payload: data.data,
        loading: false,
      });
    })
    .catch((err) => {
      console.error(err);
    });
};
export const getInprocessDocuments = () => (dispatch, getState) => {
  dispatch({
    type: LOADING_SAVE_INPROCESS_DOCUMENT_LIST,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/document/getInProcess`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        return message("error", "Error", data.message);
      }
      dispatch({
        type: SAVE_INPROCESS_DOCUMENT_LIST,
        payload: data.data,
        loading: false,
      });
    })
    .catch((err) => {
      console.error(err);
      message("error", "Error", err.message);
    });
};
export const getAllDocumentType = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;

  fetch(`${URL_GATEWAY}${API_VERSION}/documentType/getAll`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        return TokenIsExpired(data.statusCode, dispatch);
        // return message("error", "Error", data.message);
      }
      dispatch({
        type: SAVE_DOCUMENT_TYPES,
        payload: data.data,
      });
    })
    .catch((err) => {
      console.error(err);
      // message("error", "Error", err.message);
    });
};
export const getAllObsoleteDocuments = () => (dispatch, getState) => {
  dispatch({
    type: LOADING_SAVE_OBSOLETE_DOCUMENTS,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/document/getDeprecated`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        return message("error", "Error", data.message);
      }
      dispatch({
        type: SAVE_OBSOLETE_DOCUMENTS,
        payload: data.data,
        loaging: false,
      });
    })
    .catch((err) => {
      console.error(err);
      message("error", "Error", err.message);
    });
};
export const filterDocuments = async (
  type,
  filter,
  status,
  accountable,
  token
) => {
  try {
    let data = { type, filter, status, accountable };

    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/document/getAll`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const getTemplates = async (token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/documentTemplate`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const getProcessByIdTemplate = async (idTemplate, token) => {
  let data = { template: idTemplate };
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/process`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const getSubProcessByIdProcess = async (processId, token) => {
  let data = { processId };
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/subprocess`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const getAllUsers = async (token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/users`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const createNewDocument = async (
  title,
  type,
  template,
  validity,
  isInHouse,
  process,
  subProcess,
  developer,
  editor,
  reviewer,
  approver,
  token
) => {
  try {
    let data = {
      title,
      type,
      template,
      validity,
      isInHouse,
      process,
      subProcess,
      developer,
      editor,
      reviewer,
      approver,
      token,
    };
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/document`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const saveDocumentContent = async (docId, content, token) => {
  try {
    let data = {
      docId,
      content,
      token,
    };
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/updatedocument/saveContent`,
      {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const sendApprovalRequest = async (docId, comment, token) => {
  try {
    let data = {
      docId,
      comment,
      token,
    };
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/documents/requestApproval`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const approveDocument = async (docId, token) => {
  try {
    let data = {
      docId,
      token,
    };
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/documents/approve`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
