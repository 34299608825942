export const customSelectStyle = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#003F80' : '#fff'
  }),
  control: (provided, state) => ({
      ...provided,
      border: '3px solid #01A2F5',
      borderRadius: 5
    // none of react-select's styles are passed to <Control />
    //width: 200,
  }),
  dropdownIndicator:(provided, state) =>({
      ...provided,
      color: '#003F80'
  }),
  clearIndicator:(provided, state) =>({
      ...provided,
      color: '#003F80'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
  // multiValue:(provided, state) =>({
  //     ...provided,
  //     color: '#fff',
  //     borderColor: '#fff',
  //     backgroundColor: '#003F80'
  // }),
  // multiValueLabel:(provided, state) =>({
  //     ...provided,
  //     color: '#fff',
  //     borderColor: '#fff',
  //     backgroundColor: '#003F80'
  // }),
  multiValueRemove:(provided, state) =>({
      ...provided,
      color: '#003F80'
  })

}

export const StyleNiif = {
option: (provided, state) => ({
  ...provided,
  backgroundColor: state.isSelected ? '#003F80' : '#fff'
}),
control: (provided, state) => ({
    ...provided,
    border: '3px solid #1C6EA4',
    borderRadius: 5,
    width: 175
  // none of react-select's styles are passed to <Control />
  //width: 200,
}),
dropdownIndicator:(provided, state) =>({
    ...provided,
    color: '#003F80'
}),
clearIndicator:(provided, state) =>({
    ...provided,
    color: '#003F80'
}),
singleValue: (provided, state) => {
  const opacity = state.isDisabled ? 0.5 : 1;
  const transition = 'opacity 300ms';
  return { ...provided, opacity, transition };
},
// multiValue:(provided, state) =>({
//     ...provided,
//     color: '#fff',
//     borderColor: '#fff',
//     backgroundColor: '#003F80'
// }),
// multiValueLabel:(provided, state) =>({
//     ...provided,
//     color: '#fff',
//     borderColor: '#fff',
//     backgroundColor: '#003F80'
// }),
multiValueRemove:(provided, state) =>({
    ...provided,
    color: '#003F80'
})
}

//       }
//     },
//     control: styles => ({ ...styles, backgroundColor: 'white' })
//   }

export const customSelectStyleSmall = {
option: (provided, state) => ({
  ...provided,
  backgroundColor: state.isSelected ? '#003F80' : '#fff'
}),
control: (provided, state) => ({
    ...provided,
    border: '3px solid #01A2F5',
    borderRadius: 5,
  // none of react-select's styles are passed to <Control />
    width: 240,
    marginBottom:18
}),
dropdownIndicator:(provided, state) =>({
    ...provided,
    color: '#003F80'
}),
clearIndicator:(provided, state) =>({
    ...provided,
    color: '#003F80'
}),
singleValue: (provided, state) => {
  const opacity = state.isDisabled ? 0.5 : 1;
  const transition = 'opacity 300ms';
  return { ...provided, opacity, transition };
},
}


export const customSelectNew = {
option: (provided, state) => ({
  ...provided,
  fontSize: 13,
  backgroundColor: state.isSelected ? '#003F80' : '#fff'
}),
control: (provided, state) => ({
    ...provided,
    border: '1.5px solid #01A2F5',
    borderRadius: 5,
    height: 27,
    minHeight:16,
    outerHeight: 26,
    innerHeight: 26,
    
    fontSize: 13,
}),
dropdownIndicator:(provided, state) =>({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80'
}),
clearIndicator:(provided, state) =>({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80'
}),
valueContainer: (provided, state) => ({
  ...provided,
  height: '27px',
  padding: '0 6px'
}),

input: (provided, state) => ({
  ...provided,
  margin: '0px',
}),
indicatorSeparator: state => ({
  display: 'none',
}),
indicatorsContainer: (provided, state) => ({
  ...provided,
  height: '27px',
}),

singleValue: (provided, state) => {
  const opacity = state.isDisabled ? 0.5 : 1;
  const transition = 'opacity 300ms';
  return { ...provided, opacity, transition };
},
}

export const customSelectNewDark = {
option: (provided, state) => ({
  ...provided,
  fontSize: 13,
  backgroundColor: state.isSelected ? '#003F80' : '#fff'
}),
control: (provided, state) => ({
    ...provided,
    border: '1.5px solid #005DBF',
    borderRadius: 5,
    
    minHeight:16,
    outerHeight: 26,
    innerHeight: 26,
    
    fontSize: 13,
}),
dropdownIndicator:(provided, state) =>({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80'
}),
clearIndicator:(provided, state) =>({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80'
}),
valueContainer: (provided, state) => ({
  ...provided,
  height: '27px',
  padding: '0 6px'
}),

input: (provided, state) => ({
  ...provided,
  margin: '0px',
}),
indicatorSeparator: state => ({
  display: 'none',
}),
indicatorsContainer: (provided, state) => ({
  ...provided,
  height: '27px',
}),

singleValue: (provided, state) => {
  const opacity = state.isDisabled ? 0.5 : 1;
  const transition = 'opacity 300ms';
  return { ...provided, opacity, transition };
},
}