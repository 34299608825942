import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { message, TokenIsExpired } from "../helpers/helpers";

import { LOADING_SAVE_POLL_LIST, SAVE_POLL_LIST, SAVE_POLL_TYPES } from "../actions/actionTypes";

export const getAllPolls = () => (dispatch, getState) => {
  dispatch({
    type: LOADING_SAVE_POLL_LIST,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;

  fetch(`${URL_GATEWAY}${API_VERSION}/survey/getAll`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        //Si no es 200 va a verificar si es un 401, si no lo es no hace nada
        TokenIsExpired(data.statusCode, dispatch);
        return message("error", "Error", data.message);
      }
      dispatch({
        type: SAVE_POLL_LIST,
        payload: data.data,
        loading: false,
      });
    })
    .catch((err) => {
      console.error(err);
      // message("error", "Error", err.message + "catch");
    });
};
export const getPollKind = async (token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/survey/getTypes`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const getPollKindForSelect = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;

  try {
    const query = fetch(`${URL_GATEWAY}${API_VERSION}/survey/getTypes`, {
      method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        return TokenIsExpired(data.statusCode, dispatch);
        // return message("error", "Error", data.message);
      }
      dispatch({
        type: SAVE_POLL_TYPES,
        payload: data.data,
      });
    })
    .catch((err) => {
      console.error(err);
      // message("error", "Error", err.message);
    });
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const createNewPoll = async (
  name,
  goal,
  type,
  process,
  accountable,
  area,
  questions,
  token
) => {
  try {
    let data = {
      name,
      goal,
      type,
      process,
      accountable,
      area,
      questions,
      token,
    };
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/survey/addNew`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
