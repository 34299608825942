import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Select from "react-select";
import tableStyles from "../Layouts/tableStyle.module.scss";
import flow from "../../assets/img/icons/flow.svg";
import leftArrow from "../../assets/img/icons/leftArrow.svg";
import { useSelector } from "react-redux";
import {
  createNewDocument,
  getAllUsers,
  getProcessByIdTemplate,
  getSubProcessByIdProcess,
  getTemplates,
} from "../../actions/documentActions";
import { isEmptyOrUndefined, message } from "../../helpers/helpers";
import { customSelectNew } from "../Layouts/react-select-custom";
export const DocumentForm = (props) => {
  const store = useSelector((state) => state);
  const fullName =
    store.loginReducer.userData.name +
    " " +
    store.loginReducer.userData.lastname;

  const arrFilter = store.documentReducer.documentTypes;
  const [arrTemplates, setArrTemplates] = useState([]);
  const [templateSelected, setTemplateSelected] = useState("");
  const [filterSelectValue, setFilterSelectValue] = useState("");
  const [arrProcess, setArrProcess] = useState([]);
  const [processSelectValue, setProcessSelectValue] = useState({
    value: "",
    label: "",
  });
  const [filterList, setFilterList] = useState([]);
  const token = store.loginReducer.Authorization;
  const [arrSubProcess, setArrSubProcess] = useState([]);
  const [subProcessSelectedValue, setSubProcessSelectValue] = useState("");
  const [arrUsers, setArrUsers] = useState([]);
  const [selectedCreator, setSelectedCreator] = useState("");
  const [selectedEditor, setSelectedEditor] = useState("");
  const [selectedReviewer, setSelectedReviewer] = useState("");
  const [selectedApprover, setSelectedApprover] = useState("");
  const [validity, setValidity] = useState("");
  const [isInstitutional, setIsInstitutional] = useState(false);
  const [documentTitle, setDocumentTitle] = useState("");

  let date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  /* -------------------------------------------------------------------------- */
  /*                           GET DOCUMENT TEMPLATES                           */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getTemplatesIn();
    cleanFilters();
    getUsers();
  }, []);
  const getUsers = async () => {
    const result = await getAllUsers(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrUs = [];
      result.forEach((e) => {
        arrUs.push({ value: e.id, label: e.fullName });
      });
      setArrUsers(arrUs);
    }
  };
  const getTemplatesIn = async () => {
    const result = await getTemplates(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrTmpl = [];
      result.forEach((e) => {
        arrTmpl.push({ value: e.id, label: e.name });
      });
      setArrTemplates(arrTmpl);
    }
  };
  const cleanFilters = () => {
    let arrFilters = [];
    if (Array.isArray(arrFilters) && arrFilter.length > 0) {
      arrFilter.forEach((e) => {
        arrFilters.push({ value: e.id, label: e.name });
      });
      setFilterList(arrFilters);
    }
  };
  const getProcess = async (idTemplate) => {
    let result = await getProcessByIdTemplate(idTemplate, token);
    if (Array.isArray(result) && result.length > 0) {
      let arrProcess = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrProcess.push({ value: e.id, label: e.name });
      });
      setArrProcess(arrProcess);
    }
  };
  const getSubProcess = async (idProcess) => {
    let result = await getSubProcessByIdProcess(idProcess, token);
    if (Array.isArray(result) && result.length > 0) {
      let arrSubProcess = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrSubProcess.push({ value: e.id, label: e.name });
      });
      setArrSubProcess(arrSubProcess);
    }
  };
  /* ---------------------------- Send elaboration ---------------------------- */
  const submitElaboration = async () => {
    if (
      isEmptyOrUndefined(templateSelected) ||
      isEmptyOrUndefined(validity) ||
      isEmptyOrUndefined(processSelectValue) ||
      // isEmptyOrUndefined(subProcessSelectedValue) ||
      isEmptyOrUndefined(selectedCreator) ||
      isEmptyOrUndefined(selectedEditor) ||
      isEmptyOrUndefined(selectedReviewer) ||
      isEmptyOrUndefined(selectedApprover) ||
      isEmptyOrUndefined(documentTitle) ||
      isEmptyOrUndefined(filterSelectValue)
    ) {
      return message(
        "warning",
        "Advertencia",
        "Por favor llene todos los campos  obligatorios"
      );
    }
    const saveDoc = await createNewDocument(
      documentTitle,
      filterSelectValue,
      templateSelected,
      validity,
      isInstitutional,
      processSelectValue,
      subProcessSelectedValue,
      selectedCreator,
      selectedEditor,
      selectedReviewer,
      selectedApprover,
      token
    );
    if (!isEmptyOrUndefined(saveDoc.docId)) {
      props.hideForm();
      return message(
        "success",
        "Enviado a elaboración",
        "Documento creado con éxito"
      );
    } else {
      return message("error", "Error", "No se pudo guardar el documento");
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                           HANDLE CHANGES SECTION                           */
  /* -------------------------------------------------------------------------- */
  const handleChangeFilters = (e) => {
    console.log(e.target.value);
    if (!isEmptyOrUndefined(e.target.value)) {
      setFilterSelectValue(e.target.value);
    }
  };

  const handleChangeProcess = (e) => {
    setSubProcessSelectValue(-1);
    setArrSubProcess([]);
    if (!isEmptyOrUndefined(e.value)) {
      setProcessSelectValue(e.value);
      getSubProcess(e.value);
    }
  };
  const handleChangeSubProcess = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSubProcessSelectValue(e.value);
    }
  };
  const handleOnChangeTemplate = (e) => {
    setProcessSelectValue(-1);
    setSubProcessSelectValue(-1);
    setArrProcess([]);
    setArrSubProcess([]);
    if (!isEmptyOrUndefined(e.value)) {
      setTemplateSelected(e.value);
      getProcess(e.value);
    }
  };
  const handleChangeCreator = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedCreator(e.value);
    }
  };
  const handleChangeEditor = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedEditor(e.value);
    }
  };
  const handleChangeReviewer = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedReviewer(e.value);
    }
  };
  const handleChangeApprover = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedApprover(e.value);
    }
  };
  const handleChangeValidity = (e) => {
    if (!isEmptyOrUndefined(e.target.value)) {
      setValidity(e.target.value);
    }
  };
  const handleChangeDocumentTitle = (e) => {
    if (!isEmptyOrUndefined(e.target.value)) {
      setDocumentTitle(e.target.value);
    }
  };
  const handleChangeIsInstitutional = (e) => {
    setIsInstitutional(isInstitutional === false ? true : false);
  };

  return (
    <>
      <div className={"w-100"}>
        <div className={`${tableStyles.ItemSmall} mt-5`}>
          <Row
            className="d-flex justify-content-space-between "
            style={{ width: "90%" }}
          >
            <Col xs={1} className="ml-5 d-flex">
              <button onClick={props.hideForm}>
                <img src={leftArrow} alt="back"></img>
              </button>
            </Col>
            <Col xs={4}>
              <input
                className="register-inputs mb-4 backgroundColorApp inputDocumentTitle"
                name="requisition_date"
                type="text"
                placeholder="Titulo del documento"
                value={documentTitle}
                onChange={(e) => handleChangeDocumentTitle(e)}
              />
              <select
                value={filterSelectValue}
                class="selectDocumentTitle backgroundColorApp"
                placeholder="Seleccionar tipo"
                onChange={(e) => handleChangeFilters(e)}
              >
                {arrFilter.map((e) => {
                  return <option value={e.id}>{e.name}</option>;
                })}
              </select>

              {/* <Select
                onChange={(e) => handleChangeFilters(e)}
                className="selectDocumentTitle"
                value={filterList.find(
                  (obj) => obj.value === filterSelectValue
                )}
                options={filterList}
              ></Select> */}
            </Col>
          </Row>
        </div>
        <div className={tableStyles.centeredContainer}>
          <div className={`${tableStyles.container1} mt-2`}>
            <Card
              className={`${tableStyles.fourBorderRadiuses} ${tableStyles.container}`}
            >
              <div>
                <p
                  className={`${tableStyles.clearBlueText} div-document-title-header fontBold`}
                >
                  Crear nuevo documento
                </p>
                <hr className="hr-document-title-header"></hr>
              </div>
              <h6 className={`${tableStyles.clearBlueText} mt-2 fontBold`}>
                ●Información General
              </h6>
              {/* ---------------------------- ROW 1 ---------------------------------- */}
              <Row className="d-flex" style={{ width: "100%" }}>
                <Col xs={2}>
                  <p className={tableStyles.crudModalLabel}>Código</p>
                  <input
                    className="register-inputs text-muted text-readonly"
                    name="requisition_date"
                    type="text"
                    value={""}
                    readOnly={true}
                  />
                </Col>
                <Col xs={2}>
                  <p className={`${tableStyles.crudModalLabel}`}>Versión</p>
                  <input
                    className="register-inputs  text-readonly"
                    name="requisition_date"
                    type="text"
                    value={"1.93"}
                    radOnly={true}
                  />
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Plantilla
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrTemplates}
                    styles={customSelectNew}
                    value={arrTemplates.find(
                      (obj) => obj.value === templateSelected
                    )}
                    className="reactSelect"
                    onChange={(e) => handleOnChangeTemplate(e)}
                  ></Select>
                </Col>
                <Col xs={2}>
                  <p className={tableStyles.crudModalLabel}>Creado por</p>
                  <input
                    className="select-register text-muted"
                    name="id_consumption_center"
                    radOnly={true}
                    value={fullName}
                  ></input>
                </Col>
                <Col xs={3} className="d-flex mt-4">
                  <input
                    onChange={(e) => handleChangeIsInstitutional(e)}
                    value={isInstitutional}
                    className="mt-2"
                    type="checkbox"
                  />
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Es institucional
                  </p>
                </Col>
              </Row>

              {/* ---------------------------- ROW 2 ---------------------------------- */}
              <Row className="d-flex" style={{ width: "100%" }}>
                <Col xs={3}>
                  <p className={tableStyles.crudModalLabel}>Creación</p>
                  <input
                    className="register-inputs text-readonly"
                    name="requisition_date"
                    type="text"
                    value={`${day}/${month}/${year}`}
                    radOnly={true}
                    disabled={true}
                  />
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Vigencia
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    onChange={(e) => handleChangeValidity(e)}
                    className="register-inputs"
                    name="requisition_date"
                    type="date"
                  />
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Proceso
                    <span className={"text-danger"}>*</span>
                  </p>

                  <Select
                    placeholder={"Seleccione"}
                    options={arrProcess}
                    styles={customSelectNew}
                    value={arrProcess.find(
                      (obj) => obj.value === processSelectValue
                    )}
                    onChange={(e) => handleChangeProcess(e)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Subproceso
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrSubProcess}
                    styles={customSelectNew}
                    value={arrSubProcess.find(
                      (obj) => obj.value === subProcessSelectedValue
                    )}
                    onChange={(e) => handleChangeSubProcess(e)}
                  ></Select>
                </Col>
              </Row>

              {/* ---------------------------- ROW 3 ---------------------------------- */}
              <h6 className={`${tableStyles.clearBlueText} mt-3`}>
                ●Responsables
              </h6>
              <Row className="d-flex" style={{ width: "100%" }}>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Elaborador<span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrUsers}
                    styles={customSelectNew}
                    value={arrUsers.find(
                      (obj) => obj.value === selectedCreator
                    )}
                    onChange={(e) => handleChangeCreator(e)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Editor<span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrUsers}
                    styles={customSelectNew}
                    value={arrUsers.find((obj) => obj.value === selectedEditor)}
                    onChange={(e) => handleChangeEditor(e)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Revisor<span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    options={arrUsers}
                    styles={customSelectNew}
                    value={arrUsers.find(
                      (obj) => obj.value === selectedReviewer
                    )}
                    onChange={(e) => handleChangeReviewer(e)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Aprobador<span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrUsers}
                    styles={customSelectNew}
                    value={arrUsers.find(
                      (obj) => obj.value === selectedApprover
                    )}
                    onChange={(e) => handleChangeApprover(e)}
                  ></Select>
                </Col>
              </Row>

              {/* ---------------------------- ROW 4 ---------------------------------- */}
              <p className={tableStyles.crudModalLabel}>
                ●Opciones del control de flujo
              </p>
              <Row className="d-flex mt-" style={{ width: "100%" }}>
                <img src={flow} style={{ width: "50%" }}></img>
              </Row>
            </Card>
          </div>
        </div>
        <div className={`customTabs__Footer mt-5`} style={{ width: "84%" }}>
          <Button
            onClick={() => submitElaboration()}
            className={tableStyles.btnPrimary}
          >
            Enviar elaboración
          </Button>
          <Button onClick={props.hideForm} className={tableStyles.btnSecondary}>
            Cancelar
          </Button>
        </div>
      </div>
    </>
  );
};
