import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Select from "react-select";
import tableStyles from "../Layouts/tableStyle.module.scss";
import minus from "../../assets/img/icons/minus.svg";
import Add from "../../assets/img/icons/Add.svg";
import blueBall from "../../assets/img/icons/blueBall.svg";
import leftArrow from "../../assets/img/icons/leftArrow.svg";
import { useSelector } from "react-redux";
import { getAllUsers } from "../../actions/documentActions";
import { getProcessByIdbUnit } from "../../actions/businessUnits";
import { isEmptyOrUndefined, message } from "../../helpers/helpers";
import { customSelectNew } from "../Layouts/react-select-custom";
import deepcopy from "deepcopy";
import {
  createNewActionPlan,
  getAllReasons,
  getAllSources,
} from "../../actions/actionPlanAction";
import { getAllMeasurementFrequency } from "../../actions/businessUnits";
export const ActionPlanForm = (props) => {
  const store = useSelector((state) => state);
  const arrBusinessList = store.businessUnitsReducer.businessUnits;
  const token = store.loginReducer.Authorization;
  const [arrUsers, setArrUsers] = useState([]);
  const [arrProcess, setArrProcess] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState("");
  const [arrSources, setArrSources] = useState([]);
  const [selectedSource, setSelectedSource] = useState(-1);
  const [mesurementFrequency, setMesurementFrequency] = useState([]);
  const [selectedMesurementFrequency, setSelectedMesurementFrequency] =
    useState(-1);
  const [arrReasons, setArrReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState(-1);
  const [selectedLeader, setSelectedLeader] = useState(-1);
  const [selectedApprover, setSelectedApprover] = useState(-1);
  const [selectedProcess, setSelectedProcess] = useState(-1);
  const [dataUserInput, setDataUserInput] = useState({});
  const [addNumberRender, setAddNumberRender] = useState([
    { activity: "", accountable: "" },
  ]);
  /* -------------------------------------------------------------------------- */
  /*                           GET DOCUMENT TEMPLATES                           */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    cleanFilters();
    getUsers();
    getSources();
    getMeasurementFrequency();
    getReasons();
  }, []);
  const getSources = async () => {
    const result = await getAllSources(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrSources = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrSources.push({ value: e.id, label: e.name });
      });
      setArrSources(arrSources);
    }
  };
  const getUsers = async () => {
    const result = await getAllUsers(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrUs = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrUs.push({ value: e.id, label: e.fullName });
      });
      setArrUsers(arrUs);
    }
  };

  const cleanFilters = () => {
    let arrBusiness = [];
    if (Array.isArray(arrBusinessList) && arrBusinessList.length > 0) {
      arrBusinessList.forEach((e) => {
        arrBusiness.push({ value: e.id, label: e.name });
      });
      setBusinessList(arrBusiness);
    }
  };
  const getMeasurementFrequency = async () => {
    let result = await getAllMeasurementFrequency(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrMeasurementFrequency = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrMeasurementFrequency.push({ value: e.id, label: e.name });
      });
      setMesurementFrequency(arrMeasurementFrequency);
    }
  };

  const getProcess = async (bUnit) => {
    let result = await getProcessByIdbUnit(bUnit, token);
    if (Array.isArray(result) && result.length > 0) {
      let arrProcess = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrProcess.push({ value: e.id, label: e.name });
      });
      setArrProcess(arrProcess);
    }
  };

  const getReasons = async () => {
    let result = await getAllReasons(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrReasons = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrReasons.push({ value: e.id, label: e.name });
      });
      setArrReasons(arrReasons);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                            Helper JSX Functions                            */
  /* -------------------------------------------------------------------------- */

  const addActivity = (e, i) => {
    const activity = {
      activity: "",
      accountable: "",
    };
    setAddNumberRender([...addNumberRender, activity]);
  };

  const removeActivity = (e, i) => {
    //console.log("Index a elimnar", i);
    console.log("Original ", addNumberRender);
    let copy = deepcopy(addNumberRender);
    console.log("Copia ", copy);
    let index = copy.indexOf(i);
    console.log("INDEX ", i);
    copy.splice(1, 1);
    console.log("Copia despues de borrar ", copy);
    setAddNumberRender(copy);
  };
  console.log("addnumb=>", addNumberRender);
  /* ---------------------------- Send elaboration ---------------------------- */
  const submitActionPlan = async () => {
    if (
      isEmptyOrUndefined(dataUserInput.name) ||
      isEmptyOrUndefined(dataUserInput.startDate) ||
      isEmptyOrUndefined(dataUserInput.endDate) ||
      isEmptyOrUndefined(selectedSource) ||
      isEmptyOrUndefined(selectedLeader) ||
      isEmptyOrUndefined(selectedApprover) ||
      isEmptyOrUndefined(selectedProcess) ||
      isEmptyOrUndefined(selectedMesurementFrequency) ||
      isEmptyOrUndefined(dataUserInput.finding) ||
      isEmptyOrUndefined(selectedReason) ||
      isEmptyOrUndefined(dataUserInput.description)
    ) {
      return message(
        "warning",
        "Advertencia",
        "Por favor llene todos los campos  obligatorios"
      );
    }
    const saveDoc = await createNewActionPlan(
      dataUserInput.name,
      dataUserInput.startDate,
      dataUserInput.endDate,
      selectedSource,
      selectedLeader,
      selectedApprover,
      selectedProcess,
      selectedMesurementFrequency,
      dataUserInput.finding,
      selectedReason,
      dataUserInput.description,
      addNumberRender,
      token
    );
    if (saveDoc) {
      if (!isEmptyOrUndefined(saveDoc.docId)) {
        props.hideForm();
        return message(
          "success",
          "Éxito",
          "Plan de acción creado exitosamente"
        );
      } else {
        return message("error", "Error", "No se pudo guardar el documento");
      }
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                           HANDLE CHANGES SECTION                           */
  /* -------------------------------------------------------------------------- */
  const handleChangeInput = (e) => {
    let { value, name } = e.target;
    setDataUserInput({ ...dataUserInput, [name]: value });
  };
  const handleChangeSource = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedSource(e.value);
    }
  };
  const handleChangeMesurementFrequency = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedMesurementFrequency(e.value);
    }
  };
  const handleChangeReason = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedReason(e.value);
    }
  };
  const handleChangeLeader = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedLeader(e.value);
    }
  };
  const handleChangeApprover = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedApprover(e.value);
    }
  };
  const handleChangeProcess = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedProcess(e.value);
    }
  };
  const handleChangeActivity = (e, i) => {
    const { name, value } = e.target;
    console.log("name=>", name, value, i);
    let copy = deepcopy(addNumberRender);
    copy[i][name] = value;
    setAddNumberRender(copy);
  };
  const handleChangeBusinessUnit = (e) => {
    // setSelectedAuditProcess(-1);
    // setSelectedBranchOffice(-1);
    if (!isEmptyOrUndefined(e.value)) {
      getProcess(e.value);
      // getBranchOffices(e.value);
      setSelectedBusinessUnit(e.value);
    }
  };
  console.log("add=R>", addNumberRender);
  return (
    <>
      <div className={"w-100"}>
        <div className={`${tableStyles.ItemSmall} mt-5`}>
          <Row
            className="d-flex justify-content-space-between "
            style={{ width: "100%" }}
          >
            <Col xs={12} className="ml-5 d-flex" style={{ width: "80%" }}>
              <button onClick={props.hideForm}>
                <img src={leftArrow} alt="back"></img>
              </button>
              <h2 className="text-dark mb-3" style={{ marginLeft: "4%" }}>
                Crear plan de acción
              </h2>
            </Col>
          </Row>
        </div>
        <div className={tableStyles.centeredContainer}>
          <div className={`${tableStyles.container1} mt-2`}>
            <Card
              className={`${tableStyles.fourBorderRadiuses} ${tableStyles.container} ${tableStyles.bgColorClearBlue} pb-4`}
              style={{ borderWidth: 0 }}
            >
              <h6 className={`${tableStyles.clearBlueText} mt-2`}>
                Información Básica
              </h6>
              {/* ---------------------------- ROW 1 ---------------------------------- */}
              <Row className="d-flex" style={{ width: "100%" }}>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Nombre del plan
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    name="name"
                    className="register-inputs"
                    onChange={(e) => handleChangeInput(e)}
                  />
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Unidad de negocio
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={businessList}
                    styles={customSelectNew}
                    value={businessList.find(
                      (obj) => obj.value === selectedBusinessUnit
                    )}
                    onChange={(e) => handleChangeBusinessUnit(e)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Fecha inicio
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    name="startDate"
                    onChange={(e) => handleChangeInput(e)}
                    className="register-inputs"
                    type="date"
                  />
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Fecha final
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    name="endDate"
                    onChange={(e) => handleChangeInput(e)}
                    className="register-inputs"
                    type="date"
                  />
                </Col>
              </Row>
              {/* ---------------------------- ROW 2 ---------------------------------- */}
              <Row className="d-flex" style={{ width: "100%" }}>
                <Col xs={2}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Lider del proceso
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrUsers}
                    styles={customSelectNew}
                    value={arrUsers.find((obj) => obj.value === selectedLeader)}
                    onChange={(e) => handleChangeLeader(e)}
                  ></Select>
                </Col>
                <Col xs={2}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Aprobador
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrUsers}
                    styles={customSelectNew}
                    value={arrUsers.find(
                      (obj) => obj.value === selectedApprover
                    )}
                    onChange={(e) => handleChangeApprover(e)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Proceso
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrProcess}
                    styles={customSelectNew}
                    value={arrProcess.find(
                      (obj) => obj.value === selectedProcess
                    )}
                    onChange={(e) => handleChangeProcess(e)}
                  ></Select>
                </Col>
                <Col xs={2}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Frecuencia de seguimiento
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={mesurementFrequency}
                    styles={customSelectNew}
                    value={mesurementFrequency.find(
                      (obj) => obj.value === selectedMesurementFrequency
                    )}
                    onChange={(e) => handleChangeMesurementFrequency(e)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Orígenes
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrSources}
                    styles={customSelectNew}
                    value={arrSources.find(
                      (obj) => obj.value === selectedSource
                    )}
                    onChange={(e) => handleChangeSource(e)}
                  ></Select>
                </Col>
              </Row>
            </Card>
            {/* /* -------------------------------- BELOW ROW ------------------------------- */}
            <div style={{ padding: "15px" }}>
              <h6 className={`${tableStyles.clearBlueText} mt-2`}>
                Información del plan
              </h6>
              {/* /* ---------------------------------- ROW 1 --------------------------------- */}
              <Row
                className="d-flex"
                style={{ width: "100%", marginLeft: "1%" }}
              >
                <Col xs={6}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Hallazgos
                    <span className={"text-danger"}>*</span>
                  </p>
                  <textarea
                    name="finding"
                    style={{ height: "5.3rem" }}
                    placeholder="Escribir..."
                    rows="10"
                    cols="100"
                    className="register-inputs"
                    onChange={(e) => handleChangeInput(e)}
                  />
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Causa
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    name="reason"
                    placeholder={"Seleccione"}
                    options={arrReasons}
                    styles={customSelectNew}
                    value={arrReasons.find(
                      (obj) => obj.value === selectedReason
                    )}
                    onChange={(e) => handleChangeReason(e)}
                  ></Select>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Descripción
                    <span className={"text-danger"}>*</span>
                  </p>
                  <textarea
                    name="description"
                    onChange={(e) => handleChangeInput(e)}
                    style={{ height: "2rem" }}
                    placeholder="Escribir..."
                    rows="2"
                    cols="40"
                    className="register-inputs"
                  />
                </Col>
              </Row>
              {/* /* ---------------------------------- ROW 2 CONDITIONAL --------------------------------- */}
              {addNumberRender.length > 0 &&
                addNumberRender.map((e, i) => {
                  return (
                    <Row key={i} className="d-flex" style={{ width: "100%" }}>
                      <Col xs={1} style={{ width: "1.333333%" }}>
                        <button
                          onClick={i === 0 ? null : (e) => removeActivity(e, i)}
                        >
                          <img
                            src={i === 0 ? blueBall : minus}
                            style={{ marginTop: "30px", width: "16px" }}
                          ></img>
                        </button>
                      </Col>
                      <Col xs={6}>
                        <div>
                          <p
                            className={`${tableStyles.crudModalLabel} text-dark`}
                          >
                            Actividad
                            <span className={"text-danger"}>*</span>
                          </p>
                          <input
                            name="activity"
                            onChange={(e) => handleChangeActivity(e, i)}
                            className="register-inputs"
                            type="text"
                          ></input>
                        </div>
                      </Col>
                      <Col xs={3}>
                        <p
                          className={`${tableStyles.crudModalLabel} text-dark`}
                        >
                          Responsable
                          <span className={"text-danger"}>*</span>
                        </p>

                        <select
                          placeholder={"Seleccione"}
                          name="accountable"
                          className="select-register"
                          onChange={(e) => handleChangeActivity(e, i)}
                        >
                          {arrUsers.map((e, i) => {
                            return <option value={e.value}>{e.label}</option>;
                          })}
                        </select>
                      </Col>
                    </Row>
                  );
                })}
              {/* /* ---------------------------------- ROW 4 --------------------------------- */}
              <Row className="d-flex" style={{ width: "100%" }}>
                <Col xs={6}>
                  <button onClick={addActivity}>
                    <div className="d-flex">
                      <img src={Add}></img>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Agregar actividad
                      </p>
                    </div>
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className={`customTabs__Footer mt-5`} style={{ width: "90%" }}>
          <Button
            onClick={() => submitActionPlan()}
            className={tableStyles.btnPrimary}
          >
            Crear plan de acción
          </Button>
          <Button onClick={props.hideForm} className={tableStyles.btnSecondary}>
            Cancelar
          </Button>
        </div>
      </div>
    </>
  );
};
