import React, { useState } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import minus from "../../assets/img/icons/minus.svg";
import tableStyles from "../Layouts/tableStyle.module.scss";
import Add from "../../assets/img/icons/Add.svg";
import deepcopy from "deepcopy";
export const AccordionForm = ({
  dataComite,
  onChangeInputs,
  onChangeTopics,
  dataUserInput,
  topics,
}) => {
  let dataTopics = dataComite.topics;
  const [addNumberRender, setAddNumberRender] = useState([]);

  const addActivity = (e, i) => {
    const summary = {
      id: "",
      summary: "",
    };
    setAddNumberRender([...addNumberRender, summary]);
  };
  const removeActivity = (e, i) => {
    //console.log("Index a elimnar", i);
    console.log("Original ", addNumberRender);
    let copy = deepcopy(addNumberRender);
    console.log("Copia ", copy);
    let index = copy.indexOf(i);
    console.log("INDEX ", i);
    copy.splice(index, 1);
    console.log("Copia despues de borrar ", copy);
    setAddNumberRender(copy);
  };
  const handleChangeActivity = (e, i) => {
    const { name, value } = e.target;
    let copy = deepcopy(addNumberRender);
    copy[i][name] = value;
    setAddNumberRender(copy);
  };

  return (
    <>
      <div className={`${tableStyles.bgColorClearBlue} w-100 p-2`}>
          <h6 className={`${tableStyles.clearBlueText} mt-2 fontBold`}>● Introducción</h6>
          {/* ---------------------------- ROW 1 ---------------------------------- */}
          <Row className="d-flex mb-4  ml-1" style={{ width: "100%" }}>
            <Col xs={12}>
              <textarea
                name="intro"
                value={dataUserInput.intro}
                onChange={(e) => onChangeInputs(e)}
                style={{ maxHeight: "5rem", height:"5rem", minHeight:"5rem" }}
                rows="5"
                cols="40"
                className="register-inputs ml-3 mb-2"
              />
            </Col>
          </Row>
      </div>
      <div className={`${tableStyles.bgColorWhite} w-100 p-2`}>
      {dataTopics.length > 0 ? (
        <>
          <h6 className={`${tableStyles.clearBlueText} mt-2 fontBold`}>● Temas</h6>
          <div>
            <Accordion className="w-100">
              {dataTopics.map((e, i) => {
                let id = e.id;
                return (
                  <div className="w-100 mt-4 p-2 d-flex">
                    
                    <div style={{ 
                          width: "25px", 
                          height:"25px", 
                          border: "2px solid #8B8D94",
                          opacity: "0.3",
                          borderRadius:"100%"
                           }}>
                    </div>
                    <div className="w-100 ml-1">
                      <Accordion.Toggle eventKey={e.id} className={`bg-light w-100 accordionToggleButton`}>
                        <div className={`d-flex`}>
                          <div
                            style={{ 
                              width: "100%",
                              marginTop:"-8px",
                              "margin-left" : "",
                              "border-radius":"10px 10px 0 0"
                            }}
                            className={`${tableStyles.bgColorClearBlue}`}
                          >
                            <p
                              className={`${tableStyles.clearBlueText} mt-2`}
                              style={{ "text-align":"left","padding":"0 0 0 10px" }}
                            >
                              <code className={`${tableStyles.clearBlueText}`}>{`>`}</code>
                              <b> {e.subject ? e.subject : "Sin tema"} - </b>
                              {e.summary ? e.summary : "Sin descripción"}
                            </p>
                          </div>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={e.id} >
                        <Card.Body
                          style
                          className={`${tableStyles.bgColorClearBlue} mb-3`}
                          style={{
                            width: "100%",
                            borderRadius: "0 0 10px 10px",
                          }}
                        >
                          <Row>
                            <Col
                              xs={11}
                              className={`${tableStyles.bgColorClearBlue}`}
                              style={{
                                marginLeft: "56px",
                              }}
                            >
                              <textarea
                                name={e.id}
                                value={topics[e.id]}
                                style={{ height: "3rem" }}
                                rows="5"
                                cols="40"
                                className="register-inputs"
                                onChange={(e) => onChangeTopics(e, id)}
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </div>

                    
                  </div>
                );
              })}
            </Accordion>
            {/* ---------------------------- ROW 4 CONDITIONAL -------------------------------- */}
            {addNumberRender.length > 0 &&
              addNumberRender.map((e, i) => {
                return (
                  <Row
                    key={i}
                    className="d-flex mb-3 "
                    style={{ width: "100%", marginLeft: "1.4%" }}
                  >
                    <Col xs={1} style={{ width: "1.333333%" }}>
                      <button onClick={(e) => removeActivity(e, i)}>
                        <img
                          src={minus}
                          style={{ margin: "0 auto", width: "16px" }}
                        ></img>
                      </button>
                    </Col>
                    <Col xs={3}>
                      <div>
                        <input
                          name="activity"
                          placeHolder="Título del tema"
                          onChange={(e) => handleChangeActivity(e, i)}
                          className="register-inputs"
                          type="text"
                        ></input>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <input
                        placeHolder="Descripción"
                        name="activity"
                        onChange={(e) => handleChangeActivity(e, i)}
                        className="register-inputs"
                        type="text"
                      ></input>
                    </Col>
                  </Row>
                );
              })}
            <Row className="d-flex ml-3">
              <Col xs={6}>
                <button onClick={addActivity}>
                  <div className="d-flex">
                    <img src={Add}></img>
                    <p className={`${tableStyles.crudModalLabel} `}>
                      Agregar Tema
                    </p>
                  </div>
                </button>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <h4 style={{ textAlign: "center" }}>No hay temas para mostrar</h4>
      )}
      </div>
    </>
  );
};
