//Login's reducer

import {
  LOADING_SAVE_USERS_LIST,
  SAVE_USERS_LIST
  } from "../actions/actionTypes";
    
  const initialState = {
    userList: [],
    userLoading:false
  };

  export const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case SAVE_USERS_LIST:
        return {
          ...state,
          userList: action.payload
        };
      case LOADING_SAVE_USERS_LIST:
        return {
          ...state,
          userLoading: action.loading,
        };

      default:
        return state;
    }
  };
  