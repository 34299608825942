import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Select from "react-select";
import tableStyles from "../Layouts/tableStyle.module.scss";
import leftArrow from "../../assets/img/icons/leftArrow.svg";
import { useSelector } from "react-redux";
import { getAllUsers } from "../../actions/documentActions";
import { isEmptyOrUndefined, message } from "../../helpers/helpers";
import { customSelectNew } from "../Layouts/react-select-custom";
import { createNewTool } from "../../actions/toolsAction";

export const ToolsForm = (props) => {
  const store = useSelector((state) => state);
  const arrFilter = store.documentReducer.documentTypes;
  const [filterList, setFilterList] = useState([]);
  const token = store.loginReducer.Authorization;
  const [arrUsers, setArrUsers] = useState([]);
  const [selectedAccountable, setSelectedAccountable] = useState("");
  const [dataUserInput, setDataUserInput] = useState({});
  /* -------------------------------------------------------------------------- */
  /*                           GET DOCUMENT TEMPLATES                           */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    cleanFilters();
    getUsers();
  }, []);

  const getUsers = async () => {
    const result = await getAllUsers(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrUs = [];
      result.forEach((e) => {
        arrUs.push({ value: e.id, label: e.fullName });
      });
      setArrUsers(arrUs);
    }
  };
  const cleanFilters = () => {
    let arrFilters = [];
    if (Array.isArray(arrFilters) && arrFilter.length > 0) {
      arrFilter.forEach((e) => {
        arrFilters.push({ value: e.id, label: e.name });
      });
      setFilterList(arrFilters);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               CREATE NEW TOOL                              */
  /* -------------------------------------------------------------------------- */
  const submitTool = async () => {
    if (
      isEmptyOrUndefined(dataUserInput.subject) ||
      isEmptyOrUndefined(selectedAccountable) ||
      isEmptyOrUndefined(dataUserInput.what) ||
      isEmptyOrUndefined(dataUserInput.who) ||
      isEmptyOrUndefined(dataUserInput.where) ||
      isEmptyOrUndefined(dataUserInput.when) ||
      isEmptyOrUndefined(dataUserInput.howMuch) ||
      isEmptyOrUndefined(dataUserInput.mainReason)
    ) {
      return message(
        "warning",
        "Advertencia",
        "Por favor llene todos los campos  obligatorios"
      );
    }
    const saveTool = await createNewTool(
      dataUserInput.subject,
      selectedAccountable,
      dataUserInput.what,
      dataUserInput.who,
      dataUserInput.where,
      dataUserInput.when,
      dataUserInput.why,
      dataUserInput.how,
      dataUserInput.howMuch,
      dataUserInput.mainReason,
      token
    );
    if (saveTool) {
      if (!isEmptyOrUndefined(saveTool.docId)) {
        props.hideForm();
        return message(
          "success",
          "Éxito",
          "Herramienta creada exitosamente"
        );
      } else {
        return message("error", "Error", "No se pudo guardar el documento");
      }
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                           HANDLE CHANGES SECTION                           */
  /* -------------------------------------------------------------------------- */
  const handleChangeInput = (e) => {
    let { value, name } = e.target;
    setDataUserInput({ ...dataUserInput, [name]: value });
  };
  const handleChangeAccountable = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedAccountable(e.value);
    }
  };
  return (
    <>
      <div className={"w-100"}>
        <div className={`${tableStyles.ItemSmall} mt-5`}>
          <Row
            className="d-flex justify-content-space-between "
            style={{ width: "90%" }}
          >
            <Col xs={12} className="ml-5 d-flex">
              <button onClick={props.hideForm}>
                <img src={leftArrow} alt="back"></img>
              </button>
              <h2 className="text-dark mb-3" style={{ marginLeft: "4%" }}>
                Crear 5 W / 2 H
              </h2>
            </Col>
          </Row>
        </div>
        <div className={tableStyles.centeredContainer}>
          <div className={`${tableStyles.container1} mt-2`}>
            {/* /* -------------------------------- CARD ONE -------------------------------- */}
            <Card
              className={`${tableStyles.centeredContainer} ${tableStyles.shade} ${tableStyles.fourBorderRadiuses} pb-3 mb-5`}
              style={{ width: "70%" }}
            >
              <h6 className={`${tableStyles.clearBlueText} mt-2`}>
                Información Básica
              </h6>
              {/* /* ---------------------------------- ROW 1 --------------------------------- */}
              <Row className="d-flex ">
                <Col xs={6}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Tema
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    name="subject"
                    onChange={(e) => handleChangeInput(e)}
                    className="register-inputs"
                  />
                </Col>
                <Col xs={6}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Responsable
                  </p>
                  <Select
                    options={arrUsers}
                    styles={customSelectNew}
                    value={arrUsers.find(
                      (obj) => obj.value === selectedAccountable
                    )}
                    onChange={(e) => handleChangeAccountable(e)}
                  ></Select>
                </Col>
              </Row>
              {/* /* ---------------------------------- ROW 2 --------------------------------- */}
              <Row className="d-flex">
                <Col xs={12}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    <b>¿Qué?</b>
                    <span className={"text-danger"}>*</span>
                  </p>
                  <textarea
                    style={{ height: "3rem" }}
                    placeholder="Escribir..."
                    rows="10"
                    cols="100"
                    className="register-inputs"
                    name="what"
                    onChange={(e) => handleChangeInput(e)}
                  />
                </Col>
              </Row>
            </Card>
            {/* /* -------------------------------- CARD TWO -------------------------------- */}
            <Card
              className={`${tableStyles.centeredContainer} ${tableStyles.shade} ${tableStyles.fourBorderRadiuses} pb-3 mb-5`}
              style={{ width: "70%" }}
            >
              <h6 className={`${tableStyles.clearBlueText} mt-2`}>
                Desarrollo
              </h6>
              {/* /* ---------------------------------- ROW 1 --------------------------------- */}
              <Col xs={12}>
                <p className={`${tableStyles.crudModalLabel} text-dark`}>
                  <b>¿Quién?</b>
                  <span className={"text-danger"}>*</span>
                </p>
                <textarea
                  style={{ height: "3rem" }}
                  placeholder="Escribir..."
                  rows="10"
                  cols="100"
                  className="register-inputs"
                  name="who"
                  onChange={(e) => handleChangeInput(e)}
                />
              </Col>
              {/* /* ---------------------------------- ROW 2 --------------------------------- */}
              <Col xs={12}>
                <p className={`${tableStyles.crudModalLabel} text-dark`}>
                  <b>¿Dónde?</b>
                  <span className={"text-danger"}>*</span>
                </p>
                <textarea
                  style={{ height: "3rem" }}
                  placeholder="Escribir..."
                  rows="10"
                  cols="100"
                  className="register-inputs"
                  name="where"
                  onChange={(e) => handleChangeInput(e)}
                />
              </Col>
              {/* /* ---------------------------------- ROW 3 --------------------------------- */}
              <Col xs={12}>
                <p className={`${tableStyles.crudModalLabel} text-dark`}>
                  <b>¿Cúando?</b>
                  <span className={"text-danger"}>*</span>
                </p>
                <textarea
                  style={{ height: "3rem" }}
                  placeholder="Escribir..."
                  rows="10"
                  cols="100"
                  className="register-inputs"
                  name="when"
                  onChange={(e) => handleChangeInput(e)}
                />
              </Col>
              {/* /* ---------------------------------- ROW 4 --------------------------------- */}
              <Col xs={12}>
                <p className={`${tableStyles.crudModalLabel} text-dark`}>
                  <b>¿Porqué?</b>
                  <span className={"text-danger"}>*</span>
                </p>
                <textarea
                  style={{ height: "3rem" }}
                  placeholder="Escribir..."
                  rows="10"
                  cols="100"
                  className="register-inputs"
                  name="why"
                  onChange={(e) => handleChangeInput(e)}
                />
              </Col>
              {/* /* ---------------------------------- ROW 5 --------------------------------- */}
              <Col xs={12}>
                <p className={`${tableStyles.crudModalLabel} text-dark`}>
                  <b>¿Cómo?</b>
                  <span className={"text-danger"}>*</span>
                </p>
                <textarea
                  style={{ height: "3rem" }}
                  placeholder="Escribir..."
                  rows="10"
                  cols="100"
                  className="register-inputs"
                  name="how"
                  onChange={(e) => handleChangeInput(e)}
                />
              </Col>
              {/* /* ---------------------------------- ROW 6 --------------------------------- */}
              <Col xs={12}>
                <p className={`${tableStyles.crudModalLabel} text-dark`}>
                  <b>¿Cuanto?</b>
                  <span className={"text-danger"}>*</span>
                </p>
                <textarea
                  style={{ height: "3rem" }}
                  placeholder="Escribir..."
                  rows="10"
                  cols="100"
                  className="register-inputs"
                  name="howMuch"
                  onChange={(e) => handleChangeInput(e)}
                />
              </Col>
            </Card>

            {/* /* ------------------------------- CARD TRHEE ------------------------------- */}
            <Card
              className={`${tableStyles.centeredContainer} ${tableStyles.shade} ${tableStyles.fourBorderRadiuses} pb-3 `}
              style={{ width: "70%" }}
            >
              {/* /* ---------------------------------- ROW 1 --------------------------------- */}
              <Col xs={12}>
                <h4 className={`${tableStyles.crudModalLabel}`}>
                  <b>Causa raiz</b>
                </h4>
                <textarea
                  style={{ height: "3rem" }}
                  placeholder="Escribir..."
                  rows="10"
                  cols="100"
                  className="register-inputs"
                  name="mainReason"
                  onChange={(e) => handleChangeInput(e)}
                />
              </Col>
            </Card>
          </div>
        </div>
        <div className={`customTabs__Footer `} style={{ width: "80%" }}>
          <Button
            onClick={() => submitTool()}
            className={tableStyles.btnPrimary}
          >
            Crear
          </Button>
          <Button onClick={props.hideForm} className={tableStyles.btnSecondary}>
            Cancelar
          </Button>
        </div>
      </div>
    </>
  );
};
