//User's reducer

import {
    GET_PROFILES,
    GET_PROFILES_FROM_ACCOUNT,
    PERMISSION_MODULE_GET,
    PERMISSION_FUNCTIONALITY_GET,
    PERMISSION_GET,
    PERMISSION_PUT,
    SET_LOADING_PROFILE
  } from "../actions/actionTypes";
  
  const initialState = {
    profiles: [],
    displayError: false,
    error: null,
    Authorization:"",
    loading: false,

    modules: [],
    functionalities: [],
    permissions: [],

  };
  export const profileReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_PROFILES:
        return {
          ...state,
          profiles: action.payload,
          displayError: false,
          error: null,
          Authorization:action.Authorization
        };
      case GET_PROFILES_FROM_ACCOUNT:
        return {
          ...state,
          profiles: action.payload,
          displayError: false,
          error: null,
          Authorization:action.Authorization
        };


      case PERMISSION_MODULE_GET:
        return {
          ...state,
          modules: action.payload,
        };
      case PERMISSION_FUNCTIONALITY_GET:
        return {
          ...state,
          functionalities: action.payload,
        };
      case PERMISSION_GET:
        return {
          ...state,
          permissions: action.payload,
        };
      case SET_LOADING_PROFILE:
        return {
          ...state,
          loading: action.loading,
        };
        
      
      default:
        return state;
    }
  };
  