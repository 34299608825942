//Login's reducer

import {
  SAVE_TOOLS_LIST,
  LOADING_SAVE_TOOLS_LIST,
} from "../actions/actionTypes";

const initialState = {
  toolsList: [],
  toolsListLoading: false,
};
export const toolsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_TOOLS_LIST:
      return {
        ...state,
        toolsList: action.payload,
        toolsListLoading: action.loading,
      };
    case LOADING_SAVE_TOOLS_LIST:
      return {
        ...state,
        toolsListLoading: action.loading,
      };

    default:
      return state;
  }
};
