import React, { useEffect, useState } from "react";
import accountSelectIcon from "../../assets/img/icons/accountSelectIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import "reactjs-popup/dist/index.css";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {selectAccount, saveAccount} from "../../actions/loginActions";
import myStyles from "./AccountSelect.module.css";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

function AccountSelectRender(props){
    const history = useHistory();
    const counter = useSelector(state => state);
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
 
    const [trigger, setTrigger] = useState(0); //esto es para recargar la pagina
    

    useEffect(() => {
        dispatch(selectAccount( counter.loginReducer.user_data.id));
    },[trigger /*, counter.loginReducer.Authenticated*/]); //only trigger on start or if this state is changed

    const handleClick = () =>{
        history.push("/inicio");
        if(!!props.afterClick){
            props.afterClick();
        }
    }

    const renderAccounts = () =>{
        const accounts = counter.loginReducer.accounts;

        if(!!accounts===false || !!counter.loginReducer.error){
            return <div key={0} className={myStyles.flex1} onClick={()=>setTrigger(trigger+1)}>
            <p>Error de Conexion</p>
        </div>;
        }

        if(counter.loginReducer.loading){
            return <div key={0} className={myStyles.flex1} >
                        <p>Cargando...</p>
                    </div>;
        }

        if(accounts.length === 0){
            return <div key={0} className={myStyles.flex1} >
                        <p>Este Usuario no tiene cuentas accesibles</p>
                    </div>;
            
        }

        return accounts.map((acc) => {
            return <div key={acc.id} className={myStyles.flex1} onClick={()=>dispatch(saveAccount(acc, handleClick))}>
                    <img src={accountSelectIcon} className={myStyles.classIcon} />
                    <p className={myStyles.classTitle}>{acc.name}</p>
                </div>;
            })
        
    }

    return(
        <div className={myStyles.accountBoxWrap}>
            {renderAccounts()}
        </div>
    )
}

AccountSelectRender.propTypes = {
    afterClick: PropTypes.func
}


export default AccountSelectRender;