import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import RightsAndDuties from "../../assets/img/RightsAndDuties.png";
import UpdateTwoDotZ from "../../assets/img/UpdateTwoDotZ.png";
import subscribe from "../../assets/img/subscribe.png";
import { Link } from "react-router-dom";

export const DashAdvices = () => {
  return (
    <Row className="d-flex">
      <Col xs={6}>
        <Card className={"boxShadow borderAdviceBox boxShadowGray"}>
          <img
            // width="519px"
            height="152px"
            src={RightsAndDuties}
            alt="RightsAndDuties"
          ></img>

          <Card.Body>
            <Card.Text className={"subtitlesHomePage"}>Derechos y deberes de los pacientes</Card.Text>
            <Card.Text style={{ textAlign: "end" }}>
              <Link className={"small"} style={{ textDecoration: "none" }}>Ver más</Link>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={3}>
        <Card className={"boxShadow borderAdviceBox boxShadowGray"}>
          <img
            // width="291px"
            height="152px"
            src={UpdateTwoDotZ}
            alt="UpdateTwoDotZ"
          ></img>

          <Card.Body>
            <Card.Text className={"subtitlesHomePage"}>Actualización 2.0.8</Card.Text>
            <Card.Text style={{ textAlign: "end" }}>
              <Link className={"small"} style={{ textDecoration: "none" }}>Ver más</Link>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={3}>
        <Card className={"boxShadow borderAdviceBox boxShadowGray"} style={{ height: "95%" }}>
          <img
            // width="291px"
            height="156px"
            src={subscribe}
            alt="subscribe"
          ></img>
          <Card.Body>
            <Card.Text className={"subtitlesHomePage"}>
              Inscríbete al siguiente curso de gestión de calidad
              <Card.Text style={{ textAlign: "end" }}>
                <Link className={"small"} style={{ textDecoration: "none" }}>Ver más</Link>
              </Card.Text>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
