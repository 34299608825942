import React, { Component } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import Subasta from "../../assets/img/icons/auction.svg";
import { Link, matchPath } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import "./sidebar.scss";
import style from "../../routes/fullLayout.module.css";
import store from "../../store";
import CentrodeCosto from "../../assets/img/icons/CentrodeCosto.svg";
import Documents from "../../assets/img/icons/Documents.svg";
import Indicadores from "../../assets/img/icons/Indicadores.svg";
import Encuesta from "../../assets/img/icons/Encuesta.svg";
import PlanAccion from "../../assets/img/icons/PlanAccion.svg";
import Herramientas from "../../assets/img/icons/Herramientas.svg";
import Comites from "../../assets/img/icons/Comites.svg";
import IndicesAdversos from "../../assets/img/icons/IndicesAdversos.svg";
import engraneBlanco from "../../assets/img/icons/engraneBlanco.svg";
import MenuH from "../../assets/img/icons/menu.svg";

import Auditorias from "../../assets/img/icons/Auditorias.svg";

const selectedItem = {
  backgroundColor: "#3471f3",
  // width: 80;
  /* border-right-color: green; */
  borderRight: "3px solid #f8fafb",
};

const myStyles = {
  bottomLineSideBar: {
    // borderWidth: "10px",
    // borderBottom: "0.1em solid",
    color: "white",
  },
  whiteFont: {
    color: "white",
  },
};
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsedMenu: true,
      itemActive: "",
    };
  }

  onClickToggleMenu = () => {
    this.setState({ collapsedMenu: !this.state.collapsedMenu });
  };

  onClickToggleSubMenu = () => {
    if (this.state.collapsedMenu) {
      this.onClickToggleMenu();
    } else {
      let { itemActive } = this.state;
      if (itemActive !== "") {
        this.setState({ itemActive: "" });
      } else {
        this.setState({ itemActive: "pro-inner-item-active" });
      }
    }
  };

  render() {
    const myUrl = this.props.location.pathname;
    const permissionReq =
      this.props.loginReducer.currentAccount?.profile?.permission?.find(
        (x) => x.functionality?.prefix === "Req"
      );
    const permissionUnFun =
      this.props.loginReducer.currentAccount?.profile?.permission?.find(
        (x) => x.functionality?.prefix === "UnFun"
      );
    const permissionCCost =
      this.props.loginReducer.currentAccount?.profile?.permission?.find(
        (x) => x.functionality?.prefix === "CCost"
      );
    const permissionsCliEmp =
      this.props.loginReducer.currentAccount?.profile?.permission?.find(
        (x) => x.functionality?.prefix === "CliEmp"
      );
    return (
      <div
        className={`${style.fullLayoutSideBar} shadow rounded d-inline-block full-height`}
      >
        <ProSidebar
          collapsed={this.state.collapsedMenu}
          onMouseEnter={() =>
            this.setState({ collapsedMenu: !this.state.collapsedMenu })
          }
          onMouseLeave={() =>
            this.setState({ collapsedMenu: !this.state.collapsedMenu })
          }
        >
          <Menu iconShape="square">
            <MenuItem
              icon={
                <img
                  alt="Tabla maestra DE CONTABILIDAD"
                  src={MenuH}
                  class="Hamburger-sidebar"
                />
              }
            ></MenuItem>
            {/* /* ------------------------------- DOCUMENTOS ------------------------------- */}
            <SubMenu
              style={myStyles.bottomLineSideBar}
              title={`DOCUMENTOS`}
              icon={
                <div
                  id="engrane1"
                  style={{
                    backgroundColor: `${
                      !!matchPath(myUrl, "/Documentos/") ? "#3471f3" : null
                    }`,
                  }}
                >
                  <img
                    style={{ border: "none" }}
                    id="iconAdmin"
                    alt="Tabla maestra documentos"
                    src={Documents}
                    // style={{filter:"grayscale(100%)"}}
                    class="icons-sidebar"
                  />
                </div>
              }
              // className={`${this.state.itemActive} adminSideBar`}
              className={`${
                !!matchPath(myUrl, "/Documentos/") ? "faMenuActive" : null
              }  ${this.state.itemActive} adminSideBar`}
              onClick={this.onClickToggleSubMenu}
            >
              <MenuItem
                className={`${
                  !!matchPath(myUrl, "/Documentos/listadoMaestro")
                    ? "menuItemActive"
                    : null
                } activeHover`}
              >
                <Link to="/Documentos/listadoMaestro">
                  <label style={{ paddingLeft: "5px" }}></label>
                  <label style={myStyles.whiteFont}>Listado Maestro</label>
                </Link>
              </MenuItem>
              <MenuItem
                className={`${
                  !!matchPath(myUrl, "/Documentos/EnProceso")
                    ? "menuItemActive"
                    : null
                } activeHover`}
              >
                <Link to="/Documentos/EnProceso">
                  <label style={{ paddingLeft: "5px" }}></label>
                  <label style={myStyles.whiteFont}>En proceso</label>
                </Link>
              </MenuItem>
              <MenuItem
                className={`${
                  !!matchPath(myUrl, "/Documentos/Obsoletos")
                    ? "menuItemActive"
                    : null
                } activeHover`}
              >
                <Link to="/Documentos/Obsoletos">
                  <label style={{ paddingLeft: "5px" }}></label>
                  <label style={myStyles.whiteFont}>Obsoletos</label>
                </Link>
              </MenuItem>
            </SubMenu>

            {/*los condicionales (a)?b:c necesitan tener un else obligatoriamente
              normalmente un string vacio seria ignorado por react ""
              pero en la libreria react-pro-sidebar sucede un crash si se pone un "" en el else dentro del tag <Menu>
              asi que usen <span /> para que sea ignorado correctamente
            */}
            {/* /* ------------------------------- AUDITORIAS ------------------------------- */}
            <SubMenu
              title={`AUDITORIAS`}
              style={myStyles.bottomLineSideBar}
              icon={
                <div
                  style={{
                    backgroundColor: `${
                      !!matchPath(myUrl, "/Auditorias/") ? "#3471f3" : null
                    }`,
                  }}
                >
                  <img
                    alt="Tabla de auditorias"
                    src={Auditorias}
                    class="icons-sidebar"
                  />
                </div>
              }
              className={`${
                !!matchPath(myUrl, "/Auditorias/") ? "faMenuActive" : null
              }  ${this.state.itemActive}`}
              onClick={this.onClickToggleSubMenu}
            >
              <MenuItem
                className={`${
                  !!matchPath(myUrl, "/Auditorias/ListadoMaestro")
                    ? "menuItemActive"
                    : null
                } activeHover`}
              >
                <Link to="/Auditorias/ListadoMaestro">
                  <label style={{ paddingLeft: "5px" }}></label>
                  <label style={myStyles.whiteFont}>Listado maestro</label>
                </Link>
              </MenuItem>
            </SubMenu>
            <SubMenu
              title={`INDICADORES`}
              style={myStyles.bottomLineSideBar}
              icon={
                <div
                  style={{
                    backgroundColor: `${
                      !!matchPath(myUrl, "/indicadores/") ? "#3471f3" : null
                    }`,
                  }}
                >
                  <img
                    alt="Tabla maestra de compra"
                    src={Indicadores}
                    class="icons-sidebar"
                  />
                </div>
              }
              className={`${
                !!matchPath(myUrl, "/indicadores/") ? "faMenuActive" : null
              }  ${this.state.itemActive} `}
              onClick={this.onClickToggleSubMenu}
            >
              <MenuItem
                className={`${
                  !!matchPath(myUrl, "/indicadores/listadoMaestro")
                    ? "menuItemActive"
                    : null
                } activeHover`}
              >
                <Link to="/indicadores/listadoMaestro">
                  <label style={{ paddingLeft: "5px" }}></label>
                  <label style={myStyles.whiteFont}>Listado maestro</label>
                </Link>
              </MenuItem>
            </SubMenu>
            <SubMenu
              style={myStyles.bottomLineSideBar}
              title={`PLAN DE ACCIÓN`}
              icon={
                <div
                  style={{
                    backgroundColor: `${
                      !!matchPath(myUrl, "/planDeAccion/") ? "#3471f3" : null
                    }`,
                  }}
                >
                  <img
                    alt="plan de acción"
                    src={PlanAccion}
                    class="icons-sidebar"
                  />
                </div>
              }
              className={`${
                !!matchPath(myUrl, "/planDeAccion/") ? "faMenuActive" : null
              }  ${this.state.itemActive} `}
              onClick={this.onClickToggleSubMenu}
            >
              <MenuItem
                className={`${
                  !!matchPath(myUrl, "/planDeAccion/listadoDePlanes")
                    ? "menuItemActive"
                    : null
                } activeHover`}
              >
                <Link to="/planDeAccion/listadoDePlanes">
                  <label style={{ "padding-left": "5px" }}></label>
                  <label style={myStyles.whiteFont}>Listado de planes</label>
                </Link>
              </MenuItem>

              {/* {this.props.loginReducer.currentAccount?.profile?.prefijo == "ADM"
                ? [
                    <MenuItem
                      className={`${
                        !!matchPath(myUrl, "/gestionroles")
                          ? "menuItemActive"
                          : null
                      } activeHover`}
                    >
                      <Link to="/gestionroles">
                        <label style={{ "padding-left": "5px" }}></label>
                        <label
                        style={myStyles.whiteFont}
                        >Gestion de Roles</label>
                      </Link>
                    </MenuItem>,
                  ]
                : ""} */}
            </SubMenu>
            <SubMenu
              style={myStyles.bottomLineSideBar}
              title={`ENCUESTAS`}
              icon={
                <div
                  style={{
                    backgroundColor: `${
                      !!matchPath(myUrl, "/encuestas/") ? "#3471f3" : null
                    }`,
                  }}
                >
                  <img alt="ENCUESTAS" src={Encuesta} class="icons-sidebar" />
                </div>
              }
              className={`${
                !!matchPath(myUrl, "/encuestas/") ? "faMenuActive" : null
              }  ${this.state.itemActive} `}
              onClick={this.onClickToggleSubMenu}
            >
              <MenuItem>
                <Link to="/encuestas/ListadoDeEncuestas">
                  <label style={{ "padding-left": "5px" }}></label>
                  <label style={myStyles.whiteFont}>Listado de encuestas</label>
                </Link>
              </MenuItem>
            </SubMenu>
            <SubMenu
              style={myStyles.bottomLineSideBar}
              title={`HERRAMIENTAS`}
              icon={
                <div
                  style={{
                    backgroundColor: `${
                      !!matchPath(myUrl, "/herramientas/") ? "#3471f3" : null
                    }`,
                  }}
                >
                  <img
                    alt="Archivos maestros"
                    src={Herramientas}
                    class="icons-sidebar"
                  />
                </div>
              }
              className={`${
                !!matchPath(myUrl, "/herramientas/") ? "faMenuActive" : null
              }  ${this.state.itemActive} `}
            >
              <MenuItem
                className={`${
                  !!matchPath(myUrl, "/herramientas/5w2h")
                    ? "menuItemActive"
                    : null
                } activeHover`}
              >
                <Link to="/herramientas/5w2h">
                  <label style={{ "padding-left": "5px" }}></label>
                  <label style={myStyles.whiteFont}>5W2H</label>
                </Link>
              </MenuItem>
            </SubMenu>
            <SubMenu
              style={myStyles.bottomLineSideBar}
              title={`COMITÉS`}
              icon={
                <div
                  style={{
                    backgroundColor: `${
                      !!matchPath(myUrl, "/comites/") ? "#3471f3" : null
                    }`,
                  }}
                >
                  <img alt="COMITÉS" src={Comites} class="icons-sidebar" />
                </div>
              }
              className={`${
                !!matchPath(myUrl, "/comites/") ? "faMenuActive" : null
              }  ${this.state.itemActive} `}
              onClick={this.onClickToggleSubMenu}
            >
              <MenuItem
                className={`${
                  !!matchPath(myUrl, "/comites/listadoComites")
                    ? "menuItemActive"
                    : null
                } activeHover`}
              >
                <Link to="/comites/listadoComites">
                  <label style={{ paddingLeft: "5px" }}></label>
                  <label style={myStyles.whiteFont}>Listado de comités</label>
                </Link>
              </MenuItem>
            </SubMenu>
            <SubMenu
              style={myStyles.bottomLineSideBar}
              title={`EVENTOS ADVERSOS`}
              icon={
                <div
                  style={{
                    backgroundColor: `${
                      !!matchPath(myUrl, "/adversos/") ? "#3471f3" : null
                    }`,
                  }}
                >
                  <img
                    alt="EVENTOS ADVERSOS"
                    src={IndicesAdversos}
                    class="icons-sidebar"
                  />
                </div>
              }
              className={`${
                !!matchPath(myUrl, "/adversos/") ? "faMenuActive" : null
              }  ${this.state.itemActive} `}
              onClick={this.onClickToggleSubMenu}
            >
              <MenuItem
                className={`${
                  !!matchPath(myUrl, "/adversos/listadoDeEventosAdversos")
                    ? "menuItemActive"
                    : null
                } activeHover`}
              >
                <Link to="/adversos/listadoDeEventosAdversos">
                  <label style={{ paddingLeft: "5px" }}></label>
                  <label style={myStyles.whiteFont}>Listado de reportes</label>
                </Link>
              </MenuItem>
            </SubMenu>
            <SubMenu
              style={myStyles.bottomLineSideBar}
              title={`ADMINISTRACIÓN`}
              icon={
                <div
                  style={{
                    backgroundColor: `${
                      !!matchPath(myUrl, "/usuarios/") ? "#3471f3" : null
                    }`,
                  }}
                >
                  <img
                    alt="HABILIDADES DE SERVICIO"
                    src={engraneBlanco}
                    class="icons-sidebar"
                  />
                </div>
              }
              onClick={this.onClickToggleSubMenu}
              className={`${
                !!matchPath(myUrl, "/usuarios/") ? "faMenuActive" : null
              }  ${this.state.itemActive} `}
            >
              <MenuItem
                className={`${
                  !!matchPath(myUrl, "/usuarios/listadoDeUsuarios")
                    ? "menuItemActive"
                    : null
                } activeHover`}
              >
                <Link to="/usuarios/listadoDeUsuarios">
                  <label style={{ paddingLeft: "5px" }}></label>
                  <label style={myStyles.whiteFont}>Listado de usuarios</label>
                </Link>
              </MenuItem>
            </SubMenu>
          </Menu>
        </ProSidebar>
      </div>
    );
  }
}

export default withRouter(Sidebar);
