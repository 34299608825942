//Login's reducer

import {
    SAVE_ALL_POLL_STATUS,
    SAVE_ALL_TOOL_5_STATUS
  } from "../actions/actionTypes";
  
  const initialState = {
    pollStatusList: [],
    tool5StatusList:[]
  };
  export const statusReducer = (state = initialState, action) => {
    switch (action.type) {
      case SAVE_ALL_POLL_STATUS:
        return {
          ...state,
          pollStatusList: action.payload
        };
        case SAVE_ALL_TOOL_5_STATUS:
          return {
            ...state,
            tool5StatusList: action.payload
          };
  
      default:
        return state;
    }
  };
  