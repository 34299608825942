import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SAVE_ALL_POLL_STATUS } from "../../actions/actionTypes";
import { POLL } from "../../actions/statusType";
import Export from "../../assets/img/icons/exportar.svg";
import blueAddNew from "../../assets/img/icons/blueAddNew.svg";
import lupa from "../../assets/img/icons/lupa.svg";
import Pagination from "react-js-pagination";
import GenericTable from "../Layouts/GenericTable";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
/* ------------------------------------ x ----------------------------------- */

import { getPollKindForSelect } from "../../actions/pollActions";
import { getAllPolls } from "../../actions/pollActions";
import { PollForm } from "./PollForm";
import { getAllUsers } from "../../actions/userActions";
import { getAllStatusByType } from "../../actions/statusActions";
import { getAllBusinessUnits } from "../../actions/businessUnits";

function PollMasterTable(props) {
  const [showForm, setShowForm] = useState(false);
  const store = useSelector((state) => state); //entra aca para acceder a todos los reducers
  const arrPols = store.pollsReducer.polls;
  const arrFilter = store.pollsReducer.getPollKind;
  const arrUsers = store.userReducer.userList;
  // console.log("store", store);
  const arrPollStatus = store.statusReducer.pollStatusList;
  const dispatch = useDispatch(); //usa este metodo para ejecutar un action
  const [filters, setFilter] = useState({
    search: "",
    page: 1,
    perpage: 10,
  }); //valores de entrada de la funcion en el backend

  let i = 0;

  const [contador, setContador] = useState(0); //esto es para recargar la pagina

  useEffect(() => {
    dispatch(getAllPolls());
    dispatch(getAllBusinessUnits());
    dispatch(getPollKindForSelect());
    dispatch(getAllUsers());
    dispatch(getAllStatusByType(POLL, SAVE_ALL_POLL_STATUS));
  }, [contador, filters.page, filters.search, showForm]);

  const handlePageChange = (val) => {
    setFilter({
      ...filters, // División de objetos
      page: val,
    });
  };
  const handleSearchInput = (val) => {
    setFilter({
      ...filters, //el resto no los quiero cambiar
      search: val, //solo search
    });
  };

  const renderElement = (elem) => {
    return (
      <tr key={elem.code}>
        <td className="col-md-3 text-center">{elem.type}</td>
        <td className="col-md-6 text-center">{elem.name}</td>
        <td className="col-md-3 text-center">{elem.process}</td>
        <td className="col-md-2 text-center">{elem.accountable}</td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (arrPols !== undefined) {
      const elem2 = arrPols;
      elemMap = elem2.map((elem) => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };
  const renderHeaders = [
    <th style={{ borderTopLeftRadius: "10px" }} key={i}>
      Tipo de encuesta
    </th>,
    <th key={i + 1}>Nombre</th>,
    <th key={i + 1}>Proceso</th>,
    <th key={i + 1}>Responsable</th>,
    <th style={{ borderTopRightRadius: "10px" }} key={i + 1}></th>,
  ];

  const goToForm = () => {
    setShowForm(true);
  };

  return (
    <>
      {showForm ? (
        <PollForm hideForm={() => setShowForm(false)}></PollForm>
      ) : (
        <div className={tableStyles.container}>
          <h1
            className={`${tableStyles.clearBlueText} ${tableStyles.marginLeftsix}`}
          >
            Listado de encuestas
          </h1>

          <div className={tableStyles.tableArea}>
            <div className={tableStyles.mainRow}>
              <div className="d-flex justify-content-space-between filterDiv-ListView">
                <div>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Tipo
                  </p>
                  <select
                    placeholder={"Seleccione"}
                    className="select-register"
                    // onChange={(e) => handleChangeTypeSelect(e)}
                    placeholder="Seleccione"
                  >
                    <option value="Seleccione" selected>
                      Seleccione
                    </option>
                    {arrFilter
                      ? arrFilter.length > 0 &&
                        arrFilter.map((e) => {
                          return <option value={e.id}> {e.name} </option>;
                        })
                      : ""}
                  </select>
                </div>
                &nbsp;
                <div>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Responsable
                  </p>
                  <select
                    placeholder={"Seleccione"}
                    className="select-register"
                    // onChange={(e) => handleChangeTypeSelect(e)}
                    placeholder="Seleccione"
                  >
                    <option value="Seleccione" selected>
                      Seleccione
                    </option>
                    {arrUsers
                      ? arrUsers.length > 0 &&
                        arrUsers.map((e) => {
                          return <option value={e.id}> {e.name} </option>;
                        })
                      : ""}
                  </select>
                </div>
                &nbsp;
                <div>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Estado
                  </p>
                  <select
                    placeholder={"Seleccione"}
                    className="select-register"
                    // onChange={(e) => handleChangeTypeSelect(e)}
                    placeholder="Seleccione"
                  >
                    <option value="Seleccione" selected>
                      Seleccione
                    </option>
                    {arrPollStatus
                      ? arrPollStatus.length > 0 &&
                        arrPollStatus.map((e) => {
                          return <option value={e.id}> {e.name} </option>;
                        })
                      : ""}
                  </select>
                </div>
                &nbsp;
                <div className="mt-4">
                  <input
                    className={tableStyles.searchUsersAlt}
                    type="text"
                    placeholder="Buscar"
                    // onChange={(e) => handleChangeInputSearch(e)}
                  />

                  <button
                  // onClick={() => filterTable()}
                  >
                    <img
                      src={lupa}
                      alt="User icon"
                      className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                    />
                  </button>
                </div>
              </div>

              <div className={tableStyles.groupElems}>
                <small className="mt-4">
                  <b onClick={goToForm} className="buttonNewOrCreate">
                    Nuevo
                    <img
                      width="15.6px"
                      height="20px"
                      src={blueAddNew}
                      alt="Form"
                      className={`${tableStyles.iconSvg} buttonNewOrCreate_img`}
                    />
                  </b>
                </small>
              </div>
            </div>

            <GenericTable
              theadTheme={`${tableStyles.theadClearBlue}`}
              headers={renderHeaders}
            >
              {listElem()}
            </GenericTable>

            <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                Pag. {store.cupsReducer.total ? filters.page : ""}
                {" de "}
                {Math.ceil(store.cupsReducer.total / filters.perpage)
                  ? Math.ceil(store.cupsReducer.total / filters.perpage)
                  : ""}{" "}
                ({store.cupsReducer.total} encontrados)
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={10}
                totalItemsCount={store.cupsReducer.total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default PollMasterTable;
