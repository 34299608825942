import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { message, TokenIsExpired } from "../helpers/helpers";

import {
  LOADING_SAVE_TOOLS_LIST,
  SAVE_TOOLS_LIST,
} from "../actions/actionTypes";

export const getAllTools = () => (dispatch, getState) => {
  dispatch({
    type: LOADING_SAVE_TOOLS_LIST,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;

  fetch(`${URL_GATEWAY}${API_VERSION}/fiveWtwoH/getAll`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        //Si no es 200 va a verificar si es un 401, si no lo es no hace nada
        TokenIsExpired(data.statusCode, dispatch);
        return message("error", "Error", data.message);
      }
      dispatch({
        type: SAVE_TOOLS_LIST,
        payload: data.data,
        loading: false,
      });
    })
    .catch((err) => {
      console.error(err);
      // message("error", "Error", err.message + "catch");
    });
};
export const createNewTool = async (
  subject,
  accountable,
  what,
  who,
  where,
  when,
  why,
  how,
  howMuch,
  mainReason,
  token
) => {
  try {
    let data = {
      subject,
      accountable,
      what,
      who,
      where,
      when,
      why,
      how,
      howMuch,
      mainReason,
      token,
    };
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/fiveWtwoH/addNew`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
