import React, { useEffect, useState } from "react";
import dotThree from "../../assets/img/icons/dotThree.svg";
import { useSelector, useDispatch } from "react-redux";
import Export from "../../assets/img/icons/exportar.svg";
import blueAddNew from "../../assets/img/icons/blueAddNew.svg";
import lupa from "../../assets/img/icons/lupa.svg";
import Pagination from "react-js-pagination";
import GenericTable from "../Layouts/GenericTable";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
/* ------------------------------------ x ----------------------------------- */
import {
  getAllIndicators,
  getAllIndicatorTypes,
} from "../../actions/indicatorsActions";
import { isEmptyOrUndefined, message } from "../../helpers/helpers";
import { IndicatorsForm } from "./IndicatorsForm";
import { getAllBusinessUnits } from "../../actions/businessUnits";
import { Redirect, useHistory, Link } from "react-router-dom";
import { IndicatorsEditForm } from "./IndicatorsFeedForm";
import { SAVE_INDICATOR_ID } from "../../actions/actionTypes";

function IndicatorsMasterTable(props) {
  const [showForm, setShowForm] = useState(false);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const store = useSelector((state) => state); //entra aca para acceder a todos los reducers
  const arrIndicators = store.indicatorsReducer.indicators;
  const arrFilter = store.indicatorsReducer.indicatorsType;
  console.log("Filters=>", store);
  let token = store.loginReducer.Authorization;
  const dispatch = useDispatch(); //usa este metodo para ejecutar un action
  const [filters, setFilter] = useState({
    search: "",
    page: 1,
    perpage: 10,
  }); //valores de entrada de la funcion en el backend

  let history = useHistory();
  let i = 0;

  const [contador, setContador] = useState(0); //esto es para recargar la pagina

  useEffect(() => {
    dispatch(getAllBusinessUnits());
    dispatch(getAllIndicators());
    dispatch(getAllIndicatorTypes());
  }, [contador, filters.page, filters.search, showForm]);

  const handlePageChange = (val) => {
    setFilter({
      ...filters, // División de objetos
      page: val,
    });
  };
  const handleSearchInput = (val) => {
    setFilter({
      ...filters, //el resto no los quiero cambiar
      search: val, //solo search
    });
  };

  const renderElement = (elem) => {
    return (
      <tr key={elem.code}>
        <td className="col-md-2 text-center">{elem.name}</td>
        <td className="col-md-2 text-center">{elem.type}</td>
        <td className="col-md-2 text-center">{elem.accountable}</td>
        <td className="col-md-2 text-center">{elem.feeder}</td>
        <td className="col-md-2 text-center">{elem.branchOffice}</td>
        <td className="col-md-2 text-center">{elem.process}</td>
        <td className="col-md-2 text-center">{elem.trend}</td>
        <td className="col-md-2 text-center">{elem.maxGoal}</td>
        <td className="col-md-2 text-center">{elem.lastValue}</td>
        <td className="col-md-2 text-center">{elem.consolidated}</td>
        <td
          className="col-md-1 text-center"
          style={{ color: "blue", paddingRight: "10px" }}
        >
          <button
            onClick={() => goToEdit(elem.id)}
            style={{ margin: "0 auto" }}
          >
            <img src={dotThree}></img>
          </button>
        </td>
      </tr>
    );
  };

  const goToEdit = (id) => {
    history.push("/indicadores/editar");
    dispatch({
      type: SAVE_INDICATOR_ID,
      payload: id,
    });
  };

  const listElem = () => {
    let elemMap;
    if (arrIndicators !== undefined) {
      const elem2 = arrIndicators;
      elemMap = elem2.map((elem) => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };
  const renderHeaders = [
    <th style={{ borderTopLeftRadius: "10px" }} key={i}>
      Nombre
    </th>,
    <th key={i + 1}>Tipo</th>,
    <th key={i + 1}>Responsable</th>,
    <th key={i + 1}>R. Alimentador</th>,
    <th key={i + 1}>Sede</th>,
    <th key={i + 1}>Procesos</th>,
    <th key={i + 1}>Tendencia</th>,
    <th key={i + 1}>Meta</th>,
    <th key={i + 1}>Ult. Valor</th>,
    <th key={i + 1}>Consolidado</th>,
    <th style={{ borderTopRightRadius: "10px" }} key={i + 1}>
      &nbsp;
    </th>,
  ];

  const goToForm = () => {
    setShowForm(true);
  };
  const filterTable = async () => {
    // let result = await filterDocuments(
    //   //TODO:Change for filterIndicators
    //   selectedType,
    //   inputSearchValue,
    //   "",
    //   "",
    //   token
    // );
    // if (result.length > 0) {
    //   let elemMap;
    //   const elem2 = result;
    //   elemMap = elem2.map((elem) => {
    //     console.log("Lista filtrada=>", elem);
    //     return renderElement(elem);
    //   });
    //   return elemMap;
    // } else {
    //   message("warning", "No encontrado", "No se encontraron coincidencias");
    // }
  };
  /* -------------------------------------------------------------------------- */
  /*                            Handle Change Section                           */
  /* -------------------------------------------------------------------------- */
  const handleChangeTypeSelect = (e) => {
    let val = e.target.value;
    console.log(val);
    if (!isEmptyOrUndefined(val)) {
      if (val !== "Seleccione") {
        setSelectedType(val);
      }
    }
  };
  const handleChangeInputSearch = (e) => {
    let val = e.target.value;
    if (!isEmptyOrUndefined(val)) {
      if (val !== "Seleccione") {
        setInputSearchValue(val);
      }
    }
  };

  return (
    <>
      {showForm ? (
        <IndicatorsForm hideForm={() => setShowForm(false)}></IndicatorsForm>
      ) : (
        <div className={tableStyles.container}>
          <h1
            className={`${tableStyles.clearBlueText} ${tableStyles.marginLeftsix}`}
          >
            Listado de indicadores
          </h1>

          <div className={tableStyles.tableArea}>
            <div className={tableStyles.mainRow}>
              <div className="d-flex justify-content-space-between filterDiv-ListView">
                <div>
                  <p className={tableStyles.crudModalLabel}>Tipo</p>
                  <select
                    placeholder={"Seleccione"}
                    className="select-register"
                    onChange={(e) => handleChangeTypeSelect(e)}
                    placeholder="Seleccione"
                  >
                    <option value="Seleccione" selected>
                      Seleccione
                    </option>
                    {arrFilter
                      ? arrFilter.length > 0 &&
                        arrFilter.map((e) => {
                          return <option value={e.id}> {e.name} </option>;
                        })
                      : ""}
                  </select>
                </div>
                <div className="mt-4">
                  <input
                    className={tableStyles.searchUsersAlt}
                    type="text"
                    placeholder="Buscar"
                    onChange={(e) => handleChangeInputSearch(e)}
                  />

                  <button onClick={() => filterTable()}>
                    <img
                      src={lupa}
                      alt="User icon"
                      className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                    />
                  </button>
                </div>
              </div>

              <div className={tableStyles.groupElems}>
                <small className="mt-4">
                  <b onClick={goToForm} className="buttonNewOrCreate">
                    Nuevo
                    <img
                      width="15.6px"
                      height="20px"
                      src={blueAddNew}
                      alt="Form"
                      className={`${tableStyles.iconSvg} buttonNewOrCreate_img`}
                    />
                  </b>
                </small>
              </div>
            </div>

            <GenericTable
              theadTheme={`${tableStyles.theadClearBlue}`}
              headers={renderHeaders}
            >
              {listElem()}
            </GenericTable>

            {/* <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                Pag. {store.cupsReducer.total ? filters.page : ""}
                {" de "}
                {Math.ceil(store.cupsReducer.total / filters.perpage)
                  ? Math.ceil(store.cupsReducer.total / filters.perpage)
                  : ""}{" "}
                ({store.cupsReducer.total} encontrados)
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={10}
                totalItemsCount={store.cupsReducer.total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div> */}
          </div>
        </div>
      )}
    </>
  );
}
export default IndicatorsMasterTable;
