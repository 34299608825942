import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { message, TokenIsExpired } from "../helpers/helpers";
import {
  LOADING_SAVE_ADVERSE_EVENTS_LIST,
  SAVE_ADVERSE_EVENTS_LIST,
} from "./actionTypes";

export const getAllAdverseEvents = () => (dispatch, getState) => {
  dispatch({
    type: LOADING_SAVE_ADVERSE_EVENTS_LIST,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;

  fetch(`${URL_GATEWAY}${API_VERSION}/adverseEvent/getAll`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        console.log(data.statusCode);
        TokenIsExpired(data.statusCode, dispatch);
        return message("error", "Error", data.message);
      }
      //Si no es 200 va a verificar si es un 401, si no lo es no hace nada
      dispatch({
        type: SAVE_ADVERSE_EVENTS_LIST,
        payload: data.data,
        loading: false,
      });
    })
    .catch((err) => {
      console.error(err);
      message("error", "Error", err.message + "catch");
    });
};
export const createNewEvent = async (
  observer,
  workPosition,
  branchOffice,
  service,
  location,
  date,
  patient,
  idType,
  patientId,
  gender,
  age,
  insurance,
  pathologic,
  summary,
  token
) => {
  try {
    let data = {
      observer,
      workPosition,
      branchOffice,
      service,
      location,
      date,
      patient,
      idType,
      patientId,
      gender,
      age,
      insurance,
      pathologic,
      summary,
      token,
    };
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/adverseEvent/addNew`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
