import { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { Provider } from "react-redux";
import store from "../store";
import Login from "../components/Login/Login";
import Home from "../components/Home/Home";
import FullLayout from "./FullLayout";

/* -------------------------------------------------------------------------- */
/*          This below are the new routes, remember delete old routes              */
/* -------------------------------------------------------------------------- */
import { NewComiteForm } from "../components/Comites/NewComiteForm";
import DocumentsMasterTable from "../components/Documents/DocumentsMasterTable";
import InProccessTable from "../components/Documents/InProcess/InProcessTable";
import ObsoleteTable from "../components/Documents/Obsolete/ObsoleteTable";
import AuditoriesMasterTable from "../components/Auditories/AuditoriesMasterTable";
import IndicatorsMasterTable from "../components/Indicators/IndicatorsMasterTable";
import ActionPlanMasterTable from "../components/ActionPlan/ActionPlanMasterTable";
import PollMasterTable from "../components/Polls/PollMasterTable";
import ToolsMasterTable from "../components/Tools/ToolsMasterTable";
import ComitesMasterTable from "../components/Comites/ComitesMasterTable";
import AdverseEventsMasterTable from "../components/AdverseEvents/AdverseEventsMasterTable";
import UsersMasterTable from "../components/Users/UsersMasterTable";
import { IndicatorsFeedForm } from "../components/Indicators/IndicatorsFeedForm";
import { DocumentEdit } from "../components/Documents/DocumentEdit";
import { ViewDetail } from "../components/Documents/ViewDetail";
import { ComitesMinuteForm } from "../components/Comites/ComitesMinuteForm";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Router>
        <Provider store={store}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>

            <Route path="/login" component={Login} history={useHistory} />

            {/* <Route path="/accountselect" history={useHistory}>
              <AccountSelect />
            </Route> */}

            <PrivateRoute>
              <Route path="/inicio" history={useHistory}>
                <FullLayout>
                  <Home />
                </FullLayout>
              </Route>
              {/* /* ------------------------------- Documentos ------------------------------- */}
              <Route path="/Documentos/listadoMaestro">
                <FullLayout>
                  <DocumentsMasterTable />
                </FullLayout>
              </Route>

              <Route path="/Documentos/EnProceso">
                <FullLayout>
                  <InProccessTable />
                </FullLayout>
              </Route>

              <Route path="/Documentos/Obsoletos">
                <FullLayout>
                  <ObsoleteTable />
                </FullLayout>
              </Route>

              <Route path="/Documentos/Editar">
                <FullLayout>
                  <DocumentEdit />
                </FullLayout>
              </Route>
              <Route path="/Documentos/detalle">
                <FullLayout>
                  <ViewDetail />
                </FullLayout>
              </Route>
              {/* /* ------------------------------- Auditorias -------------------------------- */}
              <Route path="/Auditorias/ListadoMaestro">
                <FullLayout>
                  <AuditoriesMasterTable />
                </FullLayout>
              </Route>
              {/* ---------------------------------------usuarios----------------------------------- */}
              <Route path="/usuarios/listadoDeUsuarios">
                <FullLayout>
                  <UsersMasterTable />
                </FullLayout>
              </Route>
              {/* /* ------------------------------- indicadores ------------------------------ */}
              <Route path="/indicadores/listadoMaestro">
                <FullLayout>
                  <IndicatorsMasterTable />
                </FullLayout>
              </Route>
              <Route path="/indicadores/editar">
                <FullLayout>
                  <IndicatorsFeedForm />
                </FullLayout>
              </Route>
              {/* /* ------------------------------- Plan de acción ------------------------------ */}
              <Route path="/planDeAccion/listadoDePlanes">
                <FullLayout>
                  <ActionPlanMasterTable />
                </FullLayout>
              </Route>
              {/* /* -------------------------------- Encuestas ------------------------------- */}
              <Route path="/encuestas/ListadoDeEncuestas">
                <FullLayout>
                  <PollMasterTable />
                </FullLayout>
              </Route>
              {/* /* -------------------------------- Herramientas ------------------------------- */}
              <Route path="/herramientas/5w2h">
                <FullLayout>
                  <ToolsMasterTable />
                </FullLayout>
              </Route>
              {/* /* -------------------------------- Comités ------------------------------- */}
              <Route path="/comites/listadoComites">
                <FullLayout>
                  <ComitesMasterTable />
                </FullLayout>
              </Route>
              {/* <Route exact path="/comites/listadoComites/Creacion"> */}
              <Route exact path="/comites/creacion">
                <FullLayout>
                  <NewComiteForm />
                </FullLayout>
              </Route>
              <Route exact path="/comites/actas">
                <FullLayout>
                  <ComitesMinuteForm />
                </FullLayout>
              </Route>
              {/* /* -------------------------------- Eventos adversos ------------------------------- */}
              <Route path="/adversos/listadoDeEventosAdversos">
                <FullLayout>
                  <AdverseEventsMasterTable />
                </FullLayout>
              </Route>
            </PrivateRoute>
            {/* -------------------------------------------------------------------------- */}
            {/*                             Old project DELETE!                            */}
            {/* -------------------------------------------------------------------------- */}
            {/* <Route path="/servicios">
              <FullLayout>
                <TableServices />
              </FullLayout>
            </Route> */}
            {/* 
            <Route path="/sedes">
              <FullLayout>
                <TableSites />
              </FullLayout>
            </Route> */}

            {/* <Route path="/puc">
              <FullLayout>
                <TablePuc />
              </FullLayout>
            </Route> */}

            {/* <Route path="/cuentas">
              <FullLayout>
                <TableCuentas />
              </FullLayout>
            </Route>

            <Route path="/functional">
              <FullLayout>
                <TableFunctional />
              </FullLayout>
            </Route>

            <Route path="/storage">
              <FullLayout>
                <TableStorage />
              </FullLayout>
            </Route>

            <Route path="/costcenter">
              <FullLayout>
                <TableCostCenter />
              </FullLayout>
            </Route>

            <Route path="/impresiones-diagnosticas">
              <FullLayout>
                <TableCieten />
              </FullLayout>
            </Route>

            <Route path="/unidades-medida">
              <FullLayout>
                <TableMeasures />
              </FullLayout>
            </Route>

            <Route path="/articulos-activos">
              <FullLayout>
                <TableArticles />
              </FullLayout>
            </Route>

            <Route path="/familia-inventario">
              <FullLayout>
                <TableInventoryFamily />
              </FullLayout>
            </Route>
            <Route path="/centro-de-consumo">
              <FullLayout>
                <TableConsumptionCenter />
              </FullLayout>
            </Route>

            <Route path="/subastaindividual">
              <FullLayout>
                <TableIndividualAuction />
              </FullLayout>
            </Route>

            <Route path="/subastapendiente">
              <FullLayout>
                <TablePendingAuctions />
              </FullLayout>
            </Route>

            <Route path="/subastasvigentes">
              <FullLayout>
                <CurrentAuction />
              </FullLayout>
            </Route>

            <Route path="/requisicionnueva">
              <FullLayout>
                <RequisitionForm />
              </FullLayout>
            </Route>

            <Route path="/requisicionpendiente">
              <FullLayout>
                <RequisitionsPending />
              </FullLayout>
            </Route>

            <Route exact path="/proveedores/selector">
              <FullLayout>
                <ProvidersSelectorForm />
              </FullLayout>
            </Route>

            <Route exact path="/proveedores">
              <FullLayout>
                <TableProviders />
              </FullLayout>
            </Route>

            <Route exact path="/proveedores/selector/persona-juridica">
              <FullLayout>
                <FormLegalPerson store={store} />
              </FullLayout>
            </Route>

            <Route path="/cuentascontables">
              <FullLayout>
                <TableAccAccounts />
              </FullLayout>
            </Route>
            <Route path="/authorizeRequisitions">
              <FullLayout>
                <TableAuthorizeRequisitions />
              </FullLayout>
            </Route>
            <Route path="/corporateClients">
              <FullLayout>
                <TableCorporateClients />
              </FullLayout>
            </Route>
            <Route path="/tesoreria/MovimientosBancarios">
              <FullLayout>
                <MovimientosBancarios />
              </FullLayout>
            </Route>
            <Route path="/tesoreria/RecibosDeCaja">
              <FullLayout>
                <RecibosDeCaja />
              </FullLayout>
            </Route> */}
          </Switch>
        </Provider>
      </Router>
    );
  }
}

export default Routes;
