import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducer from "./reducers";

// Import the necessary methods for saving and loading
import { save, load } from "redux-localstorage-simple"

const initialData = save(
    {
        states: ['loginReducer', 'profileReducer', 'userReducer']
    }
);

/*
    Saving to LocalStorage is achieved using Redux 
    middleware. The 'save' method is called by Redux 
    each time an action is handled by your reducer.
*/

const createStoreWithMiddleware
    =
    compose(applyMiddleware(thunk, initialData))
        (createStore)

/*
    Loading from LocalStorage happens during
    creation of the Redux store.
*/

const store = createStoreWithMiddleware(
    reducer,
    load({
        states: ['loginReducer', 'userReducer'] //Cargar los reducers
    })
    //  + window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)


export default store;