//Login's reducer

import {
  USER_LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SAVE_USER_INFO,
} from "../actions/actionTypes";
import {
  ACCOUNT_SELECT_SAVE,
  ACCOUNT_SELECT_GET,
  ACCOUNT_LOADING,
} from "../actions/actionTypes";

const initialState = {
  user_data: {},
  password: "",
  accounts: [],
  currentAccount: null,
  error: null,
  loading: false,
  Authorization: "",
  Authenticated: false,
  userData: { hola: "hola" },
};
export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        user_data: action.payload,
        Authenticated: true,
        Authorization: action.Authorization,
      };
    case LOGOUT_SUCCESS:
      return {
        user_data: {},
        displayError: false,
        error: null,
        Authorization: null,
        Authenticated: false,
        accounts: [],
        currentAccount: null,
      };

    case ACCOUNT_LOADING:
      return {
        ...state,
        loading: action.loading,
        error: action.error,
      };
    case SAVE_USER_INFO:
      console.log("sdafsajf", action);
      return {
        ...state,
        userData: action.payload,
      };

    case ACCOUNT_SELECT_GET:
      return {
        ...state,
        accounts: action.payload,
        loading: action.loading,
      };

    case ACCOUNT_SELECT_SAVE:
      return {
        ...state,
        currentAccount: action.payload,
      };

    default:
      return state;
  }
};
