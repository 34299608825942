import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import tableStyles from "../Layouts/tableStyle.module.scss";

import pencil from "../../assets/img/icons/pencil.svg";
import printer from "../../assets/img/icons/printer.svg";
import lookingFor from "../../assets/img/icons/lookingFor.svg";
import leftArrow from "../../assets/img/icons/leftArrow.svg";
import { useSelector } from "react-redux";
import {
  getADocument,
  getAllUsers,
  sendApprovalRequest,
} from "../../actions/documentActions";
import { isEmptyOrUndefined, message } from "../../helpers/helpers";

import { useHistory } from "react-router-dom";
import { Template } from "./Template";
export const ViewDetail = (props) => {
  const store = useSelector((state) => state);
  const arrFilter = store.documentReducer.documentTypes;
  const documentId = store.documentReducer.documentId;
  const [filterList, setFilterList] = useState([]);
  const token = store.loginReducer.Authorization;
  const [arrUsers, setArrUsers] = useState([]);
  const [isInstitutional, setIsInstitutional] = useState(false);
  const [documentTitle, setDocumentTitle] = useState("");
  const history = useHistory();
  const [content, setContent] = useState("");
  const [details, setDetails] = useState(false);
  /* -------------------------------------------------------------------------- */
  /*                           GET DOCUMENT TEMPLATES                           */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (documentId === null) {
      handleBackButton();
    }
    cleanFilters();
    getUsers();
    getHtmlContent();
  }, []);
  const getUsers = async () => {
    const result = await getAllUsers(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrUs = [];
      result.forEach((e) => {
        arrUs.push({ value: e.id, label: e.fullName });
      });
      setArrUsers(arrUs);
    }
  };

  const cleanFilters = () => {
    let arrFilters = [];
    if (Array.isArray(arrFilters) && arrFilter.length > 0) {
      arrFilter.forEach((e) => {
        arrFilters.push({ value: e.id, label: e.name });
      });
      setFilterList(arrFilters);
    }
  };
  /* ---------------------------- GET HTML CONTENT ---------------------------- */
  const getHtmlContent = async () => {
    const result = await getADocument(documentId, token);
    if (result.length > 0) {
      let content = result[0].content;
      setContent(content);
    }
  };
  const sendToApproval = async () => {
    try {
      const result = await sendApprovalRequest(
        documentId,
        "commentTest",
        token
      );
      handleBackButton();
      return message(
        "success",
        "Éxito",
        "Documento enviado a aprobación exitosamente"
      );
    } catch (error) {
      console.log(error);
      return message("error", "error", error);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                           HANDLE CHANGES SECTION                           */
  /* -------------------------------------------------------------------------- */

  const handleChangeDocumentTitle = (e) => {
    if (!isEmptyOrUndefined(e.target.value)) {
      setDocumentTitle(e.target.value);
    }
  };

  const gotToDetail = () => {
    setDetails(!details);
  };
  const handleBackButton = () => {
    setContent("");
    history.push("/Documentos/EnProceso");
  };

  return (
    <>
      <div className={"w-100"}>
        <div className={`${tableStyles.ItemSmall} mt-5`}>
          <Row className="d-flex justify-content-space-between w-100">
            <Col xs={1} className="ml-5 d-flex">
              <button onClick={() => handleBackButton()}>
                <img src={leftArrow} alt="back"></img>
              </button>
            </Col>
            <Col xs={4}>
              <input
                className="register-inputs mb-4 backgroundColorApp inputDocumentTitle"
                name="requisition_date"
                type="text"
                placeholder="Titulo del documento"
                value={documentTitle}
                onChange={(e) => handleChangeDocumentTitle(e)}
              />
              <p className={"text-secondary"}>P-Procedimiento</p>
            </Col>
            <Col xs={6} className={`d-flex`}>
              <div className={`${tableStyles.groupElems} d-flex`}>
                <small className="mt-4">
                  <b className="buttonNewOrCreate">
                    <img
                      width="15.6px"
                      height="20px"
                      src={pencil}
                      alt="Form"
                      className={`${tableStyles.iconSvg} buttonNewOrCreate_img`}
                    />
                  </b>
                </small>
                <small className="mt-4">
                  {content === "" ? (
                    <Spinner animation="grow" size="sm" />
                  ) : (
                    <b className="buttonNewOrCreate">
                      <img
                        width="15.6px"
                        height="20px"
                        src={lookingFor}
                        onClick={() => gotToDetail()}
                        alt="Form"
                        className={`${tableStyles.iconSvg} buttonNewOrCreate_img`}
                      />
                    </b>
                  )}
                </small>
                <small className="mt-4">
                  <b className="buttonNewOrCreate">
                    <img
                      width="15.6px"
                      height="20px"
                      src={printer}
                      alt="Form"
                      className={`${tableStyles.iconSvg} buttonNewOrCreate_img`}
                    />
                  </b>
                </small>
              </div>
            </Col>
          </Row>
        </div>
        <div className={tableStyles.centeredContainer}>
          <div className={`${tableStyles.container1} mt-2`}>
            {!details ? (
              <>
                <Card
                  className={`${tableStyles.fourBorderRadiuses} ${tableStyles.container} ${tableStyles.bgColorClearBlue}`}
                  style={{ borderWidth: 0 }}
                >
                  <h6 className={`${tableStyles.clearBlueText} mt-2`}>
                    Información General
                  </h6>
                  {/* ---------------------------- ROW 1 ---------------------------------- */}
                  <Row className="d-flex" style={{ width: "100%" }}>
                    <Col xs={2}>
                      <p className={`${tableStyles.crudModalLabel} `}>Código</p>
                      <input
                        className="register-inputs text-readonly"
                        name="name"
                        disabled={true}
                        // onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                    <Col xs={4}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Plantilla
                        <span className={"text-danger"}>*</span>
                      </p>
                      <input
                        className="register-inputs "
                        name="name"
                        // onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Creado por
                      </p>
                      <input
                        className="register-inputs "
                        name="name"
                        // onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                  </Row>
                  {/* /* ---------------------------------- ROW 2 --------------------------------- */}
                  <Row className="d-flex" style={{ width: "100%" }}>
                    <Col xs={1}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Versión
                      </p>
                      <input
                        className="register-inputs text-readonly"
                        name="name"
                        disabled={true}
                        // onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                    <Col xs={2}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Creación
                        <span className={"text-danger"}>*</span>
                      </p>
                      <input
                        className="register-inputs "
                        name="name"
                        type="date"
                        // onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                    <Col xs={2}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Vigencia
                        <span className={"text-danger"}>*</span>
                      </p>
                      <input
                        className="register-inputs "
                        name="name"
                        type="date"
                        // onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                    <Col xs={1} className="d-flex mt-4">
                      <input
                        // onChange={(e) => handleChangeIsInstitutional(e)}
                        value={isInstitutional}
                        className="mt-2"
                        type="checkbox"
                      />
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Es institutional
                      </p>
                    </Col>

                    <Col xs={2}>
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Revisión
                      </p>
                      <input
                        type="date"
                        className="register-inputs "
                        name="name"
                        // onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                    <Col xs={2}>
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Proceso
                      </p>
                      <input
                        className="register-inputs "
                        name="name"
                        // onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                    <Col xs={2}>
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Estado
                      </p>
                      <input
                        className="register-inputs "
                        name="name"
                        // onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                  </Row>
                </Card>

                {/* /* -------------------------------- PICTURES SECTION------------------------------- */}
                <h6
                  className={`${tableStyles.clearBlueText} mt-2 `}
                  style={{ marginLeft: "1%" }}
                >
                  Responsables
                </h6>
                {/* ---------------------------- ---------------------------------- */}
                <Row className="d-flex" style={{ width: "100%" }}>
                  {/* {dataInvited.map((e, i) => {
                    return (
                      <Col xs={3}>
                        <Card className="mt-2 ml-3" style={{ height: "3rem" }}>
                          <div className="d-flex">
                            <div>
                              <img
                                className="mt-1"
                                width="40"
                                height="40"
                                src={`https://lledogrupo.com/wp-content/uploads/2018/04/user-img-1.png`}
                                style={{ borderRadius: "100%" }}
                              ></img>
                            </div>
                            <div className={`${tableStyles.fZSmall} mt-2`}>
                              <b>Chaira Webster Cabarcas</b>
                              <p>Asistente de calidad</p>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    );
                  })} */}

                  <Col xs={3}>
                    <Card className="mt-2 ml-3" style={{ height: "3rem" }}>
                      <div className="d-flex">
                        <div>
                          <img
                            className="mt-1"
                            width="40"
                            height="40"
                            src={`https://lledogrupo.com/wp-content/uploads/2018/04/user-img-1.png`}
                            style={{ borderRadius: "100%" }}
                          ></img>
                        </div>
                        <div className={`${tableStyles.fZSmall} mt-2`}>
                          <b>Chaira Webster Cabarcas</b>
                          <p>Asistente de calidad</p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xs={3}>
                    <Card className="mt-2" style={{ height: "3rem" }}>
                      <div className="d-flex">
                        <div>
                          <img
                            className="mt-1"
                            width="40"
                            height="40"
                            src={`https://www.nicepng.com/png/detail/128-1280036_jpg-free-stock-female-vector-user-user-female.png`}
                            style={{ borderRadius: "100%" }}
                          ></img>
                        </div>
                        <div className={`${tableStyles.fZSmall}`}>
                          <b>Chaira Webster Cabarcas</b>
                          <p>Asistente de calidad</p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xs={3}>
                    <Card className="mt-2" style={{ height: "3rem" }}>
                      <div className="d-flex">
                        <div>
                          <img
                            className="mt-1"
                            width="40"
                            height="40"
                            src={`https://lledogrupo.com/wp-content/uploads/2018/04/user-img-1.png`}
                            style={{ borderRadius: "100%" }}
                          ></img>
                        </div>
                        <div className={`${tableStyles.fZSmall} mt-2`}>
                          <b>Chaira Webster Cabarcas</b>
                          <p>Asistente de calidad</p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xs={3}>
                    <Card className="mt-2" style={{ height: "3rem" }}>
                      <div className="d-flex">
                        <div>
                          <img
                            className="mt-1"
                            width="40"
                            height="40"
                            src={`https://www.nicepng.com/png/detail/128-1280036_jpg-free-stock-female-vector-user-user-female.png`}
                            style={{ borderRadius: "100%" }}
                          ></img>
                        </div>
                        <div className={`${tableStyles.fZSmall} mt-2`}>
                          <b>Chaira Webster Cabarcas</b>
                          <p>Asistente de calidad</p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
                {/* /* ------------------------------- VERSIONING ------------------------------- */}
                <p className={`${tableStyles.clearBlueText} mt-4 fontBold`}>
                  ● Versionamiento
                </p>
                <Row className="d-flex " style={{ width: "100%" }}>
                  <Col xs={6}>
                    <span
                      style={{ padding: "10px" }}
                      className={`${tableStyles.clearBlueText} small`}
                    >
                      Version 001 // Rev 1 // FV.4 de octubre 2021
                    </span>
                    <div className={"div-versionamiento-textLike"}>
                      CAMBIOS Sed ut perspiciatis unde omnis iste natus error
                      sit voluptatem accusantium doloremque laudantium, totam
                      rem aperiam, eaque ipsa quae ab illo inventore veritatis
                      et quasi architecto beatae vitae dicta sunt explicabo.
                      Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                      aut odit aut fugit, sed quia consequuntur magni dolores
                      eos qui ratione voluptatem sequi nesciunt. Neque porro
                      quisquam est, qui dolorem ipsum quia dolor sit amet,
                      consectetur, adipisci velit, sed quia non numquam eius
                      modi tempora incidunt ut labore et dolore magnam aliquam
                      quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
                      exercitationem ullam corporis suscipit laboriosam, nisi ut
                      aliquid ex ea commodi consequatur? Quis autem vel eum iure
                      reprehenderit qui in ea voluptate velit esse quam nihil
                      molestiae consequatur, vel illum qui dolorem eum fugiat
                      quo voluptas nulla pariatur? Sed ut perspiciatis unde
                      omnis iste natus error sit voluptatem accusantium
                      doloremque laudantium, totam rem aperiam, eaque ipsa quae
                      ab illo inventore veritatis et quasi architecto beatae
                      vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                      quia voluptas sit aspernatur aut odit aut fugit, sed quia
                      consequuntur magni dolores eos qui ratione voluptatem
                      sequi nesciunt. Neque porro quisquam est, qui dolorem
                      ipsum quia dolor sit amet, consectetur, adipisci velit,
                      sed quia non numquam eius modi tempora incidunt ut labore
                      et dolore magnam aliquam quaerat voluptatem. Ut enim ad
                      minima veniam, quis nostrum exercitationem ullam corporis
                      suscipit laboriosam, nisi ut aliquid ex ea commodi
                      consequatur? Quis autem vel eum iure reprehenderit qui in
                      ea voluptate velit esse quam nihil molestiae consequatur,
                      vel illum qui dolorem eum fugiat quo voluptas nulla
                      pariatur?
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Template data={content}></Template>
                <div
                  className={`customTabs__Footer mt-5`}
                  style={{ width: "100%" }}
                >
                  <Button
                    onClick={() => sendToApproval()}
                    className={tableStyles.btnPrimary}
                  >
                    Enviar a aprobación
                  </Button>
                  <Button
                    onClick={props.hideForm}
                    className={tableStyles.btnSecondary}
                  >
                    Cancelar
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
