import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { message, TokenIsExpired } from "../helpers/helpers";

import {
  LOADING_SAVE_INDICATORS_LIST,
  SAVE_INDICATORS_LIST,
  SAVE_INDICATOR,
  SAVE_INDICATORS_TYPE
} from "../actions/actionTypes";

export const getAllIndicators = () => (dispatch, getState) => {
  dispatch({
    type: LOADING_SAVE_INDICATORS_LIST,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;

  fetch(`${URL_GATEWAY}${API_VERSION}/indicator/getAll`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        //Si no es 200 va a verificar si es un 401, si no lo es no hace nada
        TokenIsExpired(data.statusCode, dispatch);
        return message("error", "Error", data.message);
      }
      dispatch({
        type: SAVE_INDICATORS_LIST,
        payload: data.data,
        loading: false,
      });
    })
    .catch((err) => {
      console.error(err);
      // message("error", "Error", err.message + "catch");
    });
};
export const getAnIndicator = (id) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;

  let data = { id };

  fetch(`${URL_GATEWAY}${API_VERSION}/indicator/get`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        //Si no es 200 va a verificar si es un 401, si no lo es no hace nada
        TokenIsExpired(data.statusCode, dispatch);
        return message("error", "Error", data.message);
      }
      dispatch({
        type: SAVE_INDICATOR,
        payload: data.data,
      });
    })
    .catch((err) => {
      console.error(err);
      // message("error", "Error", err.message + "catch");
    });
};
export const getAllIndicatorsKind = async (token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/indicatorKinds/getAll`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const createNewIndicator = async (
  name,
  type,
  branchOffice,
  process,
  summary,
  unitMeasure,
  unitFrequency,
  accountable,
  accountableFeeder,
  kind,
  trend,
  numerator,
  denominator,
  minGoal,
  nomGoal,
  maxGoal,
  token
) => {
  try {
    let data = {
      name,
      type,
      branchOffice,
      process,
      summary,
      unitMeasure,
      unitFrequency,
      accountable,
      accountableFeeder,
      kind,
      trend,
      numerator,
      denominator,
      minGoal,
      nomGoal,
      maxGoal,
    };
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/indicator/addNew`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const feedIndicator = async (
  indicator,
  date,
  numerator,
  denominator,
  calculated,
  notes,
  token
) => {
  try {
    let data = {
      indicator,
      date,
      numerator,
      denominator,
      calculated,
      notes,
      token,
    };
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/indicator/feed`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const getAllIndicatorsTypes = async (token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/indicatorTypes/getAll`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};


export const getAllIndicatorTypes = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;

  fetch(`${URL_GATEWAY}${API_VERSION}/indicatorTypes/getAll`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        //Si no es 200 va a verificar si es un 401, si no lo es no hace nada
        TokenIsExpired(data.statusCode, dispatch);
        return message("error", "Error", data.message);
      }
      dispatch({
        type: SAVE_INDICATORS_TYPE,
        payload: data.data,
      });
    })
    .catch((err) => {
      console.error(err);
      // message("error", "Error", err.message + "catch");
    });
};