import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import blueAddNew from "../../assets/img/icons/blueAddNew.svg";
import lupa from "../../assets/img/icons/lupa.svg";
import Pagination from "react-js-pagination";
import GenericTable from "../Layouts/GenericTable";
import dotThree from "../../assets/img/icons/dotThree.svg";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "../Layouts/tableStyle.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
/* ------------------------------------ x ----------------------------------- */

import { getAllUsers } from "../../actions/userActions";
import { isEmptyOrUndefined, message } from "../../helpers/helpers";

function UsersMasterTable(props) {
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [showForm, setShowForm] = useState(false);
  const store = useSelector((state) => state);
  const arrUserList = store.userReducer.userList;
  let token = store.loginReducer.Authorization;
  const dispatch = useDispatch();
  const [filters, setFilter] = useState({
    search: "",
    page: 1,
    perpage: 10,
  }); //valores de entrada de la funcion en el backend

  let i = 0;

  const [contador, setContador] = useState(0); //esto es para recargar la pagina

  useEffect(() => {
    dispatch(getAllUsers());
  }, [contador, filters.page, showForm]);

  const renderElement = (elem) => {
    return (
      <tr key={elem.fullName}>
        <td className="col-md-4 text-center">{elem.fullName}</td>
        <td className="col-md-3 text-center">{elem.position}</td>
        <td className="col-md-3 text-center">{elem.email}</td>
        <td className="col-md-2 text-center">{elem.phone}</td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (arrUserList !== undefined) {
      const elem2 = arrUserList;
      if (elem2.length > 0) {
        elemMap = elem2.map((elem) => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };
  const renderHeaders = [
    <th key={i + 1}>Nombre</th>,
    <th key={i + 1}>Cargo</th>,
    <th key={i + 1}>Correo</th>,
    <th key={i + 1}>Teléfono</th>,
  ];
  const goToForm = () => {
    setShowForm(true);
  };
  /* -------------------------------------------------------------------------- */
  /*                            Handle Change Section                           */
  /* -------------------------------------------------------------------------- */
  const handleChangeTypeSelect = (e) => {
    let val = e.target.value;
    console.log(val);
    if (!isEmptyOrUndefined(val)) {
      if (val !== "Seleccione") {
        setSelectedType(val);
      }
    }
  };
  const handleChangeInputSearch = (e) => {
    let val = e.target.value;
    if (!isEmptyOrUndefined(val)) {
      if (val !== "Seleccione") {
        setInputSearchValue(val);
      }
    }
  };
  const handlePageChange = (val) => {
    setFilter({
      ...filters, // División de objetos
      page: val,
    });
  };
  const handleSearchInput = (val) => {
    setFilter({
      ...filters, //el resto no los quiero cambiar
      search: val, //solo search
    });
  };

  return (
    <>
      <div className={tableStyles.container}>
        <h1
          className={`${tableStyles.clearBlueText} ${tableStyles.marginLeftsix}`}
        >
          Listado de usuarios
        </h1>

        <div className={tableStyles.tableArea}>
          <div className={tableStyles.mainRow}>
            <div className="d-flex justify-content-space-between filterDiv-ListView">
              <div className="mt-4">
                <input
                  className={tableStyles.searchUsersAlt}
                  type="text"
                  placeholder="Buscar"
                  onChange={(e) => handleChangeInputSearch(e)}
                  style={{ minHeight: "30px" }}
                />

                <button>
                  <img
                    src={lupa}
                    alt="User icon"
                    className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                  />
                </button>
              </div>
            </div>

            {/* <div className={tableStyles.groupElems}>
              <small className="mt-4">
                <b onClick={goToForm} className="buttonNewOrCreate">
                  Nuevo
                  <img
                    width="15.6px"
                    height="20px"
                    src={blueAddNew}
                    alt="Form"
                    className={`${tableStyles.iconSvg} buttonNewOrCreate_img`}
                  />
                </b>
              </small>
            </div> */}
          </div>

          <GenericTable
            theadTheme={`${tableStyles.theadClearBlue}`}
            headers={renderHeaders}
          >
            {listElem()}
          </GenericTable>

          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {store.cupsReducer.total ? filters.page : ""}
              {" de "}
              {Math.ceil(store.cupsReducer.total / filters.perpage)
                ? Math.ceil(store.cupsReducer.total / filters.perpage)
                : ""}{" "}
              ({store.cupsReducer.total} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={10}
              totalItemsCount={store.cupsReducer.total}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default UsersMasterTable;
