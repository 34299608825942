//Login's reducer

import {
  SAVE_ADVERSE_EVENTS_LIST,
  LOADING_SAVE_ADVERSE_EVENTS_LIST,
} from "../actions/actionTypes";

const initialState = {
  adverseEventsLoading: false,
  adverseEventsList: [],
};
export const adverseEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_ADVERSE_EVENTS_LIST:
      return {
        ...state,
        adverseEventsList: action.payload,
        adverseEventsLoading: action.loading,
      };

    case LOADING_SAVE_ADVERSE_EVENTS_LIST:
      return {
        ...state,
        adverseEventsLoading: action.loading,
      };

    default:
      return state;
  }
};
