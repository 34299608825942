import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { message, TokenIsExpired } from "../helpers/helpers";
import {
  LOADING_SAVE_UDITORIES_LIST,
  SAVE_UDITORIES_LIST,
} from "./actionTypes";

export const getAllAuditories = () => (dispatch, getState) => {
  dispatch({
    type: LOADING_SAVE_UDITORIES_LIST,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;

  fetch(`${URL_GATEWAY}${API_VERSION}/audit/getAll`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        console.log(data.statusCode);
        TokenIsExpired(data.statusCode, dispatch);
        return message("error", "Error", data.message);
      }
      //Si no es 200 va a verificar si es un 401, si no lo es no hace nada
      dispatch({
        type: SAVE_UDITORIES_LIST,
        payload: data.data,
        loading: false,
      });
    })
    .catch((err) => {
      console.error(err);
      message("error", "Error", err.message + "catch");
    });
};
export const createNewAuditory = async (
  name,
  startDate,
  endDate,
  accountable,
  type,
  process,
  auditor,
  audited,
  goal,
  checkList,
  summary,
  token
) => {
  try {
    let data = {
      name,
      startDate,
      endDate,
      accountable,
      type,
      process,
      auditor,
      audited,
      goal,
      checkList,
      summary,
    };
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/audit/addNew`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error1", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const getAuditoriesTypes = async (token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/audit/getTypes`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const getAuditProcess = async (token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/audit/getTypes`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const getAuditCheckList = async (idBunit, token) => {
  let data = { idBunit };
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/document/getCheckLists`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
