import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Aprove from "../../../assets/img/icons/Aprove.svg";
import Export from "../../../assets/img/icons/exportar.svg";
import Refresh from "../../../assets/img/icons/Refresh.svg";
import pencil from "../../../assets/img/icons/pencil.svg";
import lupa from "../../../assets/img/icons/lupa.svg";
import dotThree from "../../../assets/img/icons/dotThree.svg";
import Pagination from "react-js-pagination";
import GenericTable from "../../Layouts/GenericTable";
import "../../TableUsers/tableUserStyle.scss";
import tableStyles from "../../Layouts/tableStyle.module.scss";
import paginationStyles from "../../Layouts/pagination.module.scss";
import {
  approveDocument,
  getInprocessDocuments,
  getAllDocumentType
} from "../../../actions/documentActions";
import { SAVE_DOCUMENT_ID } from "../../../actions/actionTypes";
import { useHistory } from "react-router-dom";
import { isEmptyOrUndefined, message } from "../../../helpers/helpers";
import Swal from "sweetalert2";

function InProcessTable(props) {
  const [showForm, setShowForm] = useState(false);
  const store = useSelector((state) => state); //entra aca para acceder a todos los reducers
  let token = store.loginReducer.Authorization;
  const dispatch = useDispatch(); //usa este metodo para ejecutar un action
  const arrInProcessDocs = store.documentReducer.inProcessDocuments;
  const arrFilter = store.documentReducer.documentTypes;
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [selectedType, setSelectedType] = useState("");
  let i = 0;
  const history = useHistory();
  const [filters, setFilter] = useState({
    search: "",
    page: 1,
    perpage: 10,
  }); //valores de entrada de la funcion en el backend

  const [contador, setContador] = useState(0); //esto es para recargar la pagina
  useEffect(
    () => {
      dispatch(getInprocessDocuments());
      dispatch(getAllDocumentType());
    },
    [contador, filters.page] //cada vez que esto cambie, ejecutar la funcion de arriba.
  );

  const handlePageChange = (val) => {
    setFilter({
      ...filters, // División de objetos
      page: val,
    });
  };

  const handleSearchInput = (val) => {
    setFilter({
      ...filters, //el resto no los quiero cambiar
      search: val, //solo search
    });
  };

  const goToForm = () => {
    setShowForm(true);
  };
  const goToDetail = async (idDocument) => {
    history.push("/Documentos/detalle");
    dispatch({
      type: SAVE_DOCUMENT_ID,
      payload: idDocument,
    });
  };
  const approve = async (id) => {
    try {
      const res = await approveDocument(id, token);
      console.log(res);
      dispatch(getInprocessDocuments());
      return message("success", "Éxito!",   "Documento aprobado exitosamente");
    } catch (error) {
      console.log(error.message);
      return message("error", "Error", error.message);
    }
  };

  const handleChangeTypeSelect = (e) => {
    let val = e.target.value;
    console.log(val);
    if (!isEmptyOrUndefined(val)) {
      if (val !== "Seleccione") {
        setSelectedType(val);
      }
    }
  };

  const sendToApprove = (id) => {
    Swal.fire({
      icon: "info",
      title: "Aprobar documento",
      text: "¿Desea aprobar este documento ?",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Confirmar",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        approve(id);
      }
    });
  };
  const renderElement = (elem) => {
    return (
      <tr key={elem.code}>
        <td className="col-md-2 text-center">{elem.type}</td>
        <td className="col-md-2 text-center text-primary">
          {" "}
          <u title="Revisar documento">
            <a onClick={() => goToDetail(elem.id)}>{elem.name}</a>
          </u>
        </td>
        <td className="col-md-2 text-center">{elem.creation}</td>
        <td className="col-md-2 text-center">
          <div
            style={{
              fontWeight: "700",
              backgroundColor: `${elem.bgColor}`,
              color: `${elem.fontColor}`,
              minWidth: "87px",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            {elem.status}
          </div>
        </td>
        <td className="col-md-3 text-center">{elem.accountables}</td>
        <td
          className="col-md-1 text-center d-flex"
          style={{ color: "blue", paddingRight: "10px" }}
        >
          <button
            title="Editar documento"
            onClick={() => GoToEditDocument(elem.id)}
            style={{ margin: "0 auto" }}
          >
            <img src={dotThree}></img>
          </button>
          <button
            className="ml-3"
            title="Aprobar documento"
            onClick={() => sendToApprove(elem.id)}
            style={{ margin: "0 auto", "margin-left":"15px" }}
          >
            <img src={Aprove}></img>
          </button>
        </td>
      </tr>
    );
  };

  const GoToEditDocument = async (idDocument) => {
    history.push("/Documentos/Editar");
    dispatch({
      type: SAVE_DOCUMENT_ID,
      payload: idDocument,
    });
  };
  const listElem = () => {
    let elemMap;
    if (arrInProcessDocs !== undefined) {
      const elem2 = arrInProcessDocs;
      elemMap = elem2.map((elem) => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };
  const renderHeaders = [
    <th style={{ borderTopLeftRadius: "10px" }} key={i}>Tipo</th>,
    <th key={i + 1}>Nombre</th>,
    <th key={i + 1}>Creación</th>,
    <th key={i + 1}>Estado</th>,
    <th key={i + 1}>Responsables</th>,
    <th style={{ borderTopRightRadius: "10px" }} key={i + 1}>
      &nbsp;
    </th>,
  ];

  return (
    <>
      {showForm ? (
        <h1>YAY</h1>
      ) : (
        <div className={tableStyles.container}>
          <h1
            className={`${tableStyles.clearBlueText} ${tableStyles.marginLeftsix}`}
          >
            Documentos en proceso &nbsp;
            <a>
              <img src={Refresh}></img>
            </a>
          </h1>

          <div className={tableStyles.tableArea}>
            <div className={tableStyles.mainRow}>
              <div className="d-flex justify-content-space-between filterDiv-ListView">
                
              <div>
                  <p className={tableStyles.crudModalLabel}>Tipo</p>
                  <select
                    placeholder={"Seleccione"}
                    className="select-register"
                    onChange={(e) => handleChangeTypeSelect(e)}
                    placeholder="Seleccione"
                    style={{ "min-height": "30px" }}
                  >
                    <option value="Seleccione" selected>
                      Seleccione
                    </option>
                    {arrFilter
                      ? arrFilter.length > 0 &&
                        arrFilter.map((e) => {
                          return <option value={e.id}> {e.name} </option>;
                        })
                      : ""}
                  </select>
                </div>
                <div>
                  <input
                    className={`${tableStyles.searchUsersAlt} mt-4`}
                    type="text"
                    placeholder="Buscar"
                    onChange={(e) => handleSearchInput(e.target.value)}
                  />
                </div>
                <button onClick={() => setContador(contador + 1)}>
                  <img
                    src={lupa}
                    alt="User icon"
                    className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin} mt-4`}
                  />
                </button>
              </div>

              {/* <div className={tableStyles.groupElems}>
                <small className="mt-4">
                  <b>Nuevo</b>
                </small>
                <img
                  width="15.6px"
                  height="20px"
                  onClick={goToForm}
                  src={Export}
                  alt="Form"
                  className={tableStyles.iconSvg}
                />
              </div> */}
            </div>

            <GenericTable
              theadTheme={`${tableStyles.theadClearBlue}`}
              headers={renderHeaders}
            >
              {listElem()}
            </GenericTable>

            <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                Pag. {store.cupsReducer.total ? filters.page : ""}
                {" de "}
                {Math.ceil(store.cupsReducer.total / filters.perpage)
                  ? Math.ceil(store.cupsReducer.total / filters.perpage)
                  : ""}{" "}
                ({store.cupsReducer.total} encontrados)
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={10}
                totalItemsCount={store.cupsReducer.total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default InProcessTable;
