//Login's reducer

import {
  LOADING_SAVE_DOCUMENT_LIST,
  SAVE_DOCUMENT_LIST,
  SAVE_DOCUMENT_TYPES,
  SAVE_INPROCESS_DOCUMENT_LIST,
  LOADING_SAVE_INPROCESS_DOCUMENT_LIST,
  SAVE_OBSOLETE_DOCUMENTS,
  LOADING_SAVE_OBSOLETE_DOCUMENTS,
  SAVE_DOCUMENT_ID,
} from "../actions/actionTypes";

const initialState = {
  documents: [],
  documentsLoading: false,
  inProcessDocuments: [],
  inProcessdocumentsLoading: false,
  documentTypes: [],
  obsoleteDocuments: [],
  obsoleteDocumentsLoading: false,
  documentId: null,
};
export const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DOCUMENT_LIST:
      return {
        ...state,
        documents: action.payload,
        documentsLoading: action.loading,
      };
    case SAVE_INPROCESS_DOCUMENT_LIST:
      return {
        ...state,
        inProcessDocuments: action.payload,
        inProcessdocumentsLoading: action.loading,
      };
    case LOADING_SAVE_INPROCESS_DOCUMENT_LIST:
      return {
        ...state,
        inProcessdocumentsLoading: action.loading,
      };

    case SAVE_DOCUMENT_TYPES:
      return {
        ...state,
        documentTypes: action.payload,
      };

    case LOADING_SAVE_DOCUMENT_LIST:
      return {
        ...state,
        documentsLoading: action.loading,
      };
    case SAVE_OBSOLETE_DOCUMENTS:
      return {
        ...state,
        obsoleteDocuments: action.payload,
        obsoleteDocumentsLoading: action.loading,
      };
    case LOADING_SAVE_OBSOLETE_DOCUMENTS:
      return {
        ...state,
        obsoleteDocumentsLoading: action.loading,
      };
    case SAVE_DOCUMENT_ID:
      return {
        ...state,
        documentId: action.payload,
      };

    default:
      return state;
  }
};
