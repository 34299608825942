import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Select from "react-select";
import tableStyles from "../Layouts/tableStyle.module.scss";
import checkIcon from "../../assets/img/icons/checkIcon.svg";
import shortAnswer from "../../assets/img/icons/shortAnswer.svg";
import paragraph from "../../assets/img/icons/paragraph.svg";
import radial from "../../assets/img/icons/radial.svg";
import whitePlus from "../../assets/img/icons/whitePlus.svg";
import leftArrow from "../../assets/img/icons/leftArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { createNewDocument, getAllUsers } from "../../actions/documentActions";
import { isEmptyOrUndefined, message } from "../../helpers/helpers";
import { customSelectNew } from "../Layouts/react-select-custom";
import { createNewPoll, getPollKind } from "../../actions/pollActions";
import {
  getAllAreas,
  getAllBusinessUnits,
  getProcessByIdbUnit,
} from "../../actions/businessUnits";
import deepcopy from "deepcopy";

export const PollForm = (props) => {
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const arrTagTypes = [
    {
      value: 1,
      label: (
        <p>
          <img src={shortAnswer} />
          &nbsp; Respuesta corta
        </p>
      ),
    },
    {
      value: 2,
      label: (
        <>
          <p>
            <img src={paragraph} />
            &nbsp; Párrafo
          </p>
        </>
      ),
    },
    {
      value: 3,
      label: (
        <>
          <p className={`mt-3`}>
            <img src={radial} />
            &nbsp; Opción múltiple
          </p>
        </>
      ),
    },
    {
      value: 4,
      label: (
        <>
          <p>
            <img src={checkIcon} />
            &nbsp; Casilla
          </p>
        </>
      ),
    },
  ];
  const [selectedTagType, setSelectedTagType] = useState();
  const [conditionalRender, setConditionalRender] = useState(
    <input
      // onChange={(e) => handleChangeValidity(e)}
      className="register-inputs"
    />
  );
  const [arrKind, setArrKind] = useState([]);
  const [addNumberRender, setAddNumberRender] = useState([
    {
      questionTitle: "",
      questionType: "",
    },
  ]);
  const [selectedKind, setSelectedKind] = useState("");
  const [arrUsers, setArrUsers] = useState([]);
  const arrBusinessList = store.businessUnitsReducer.businessUnits;
  // console.log("🚀 ~ file", arrBusinessList);
  const [businessList, setBusinessList] = useState([]);
  console.log("🚀 ~ f222", businessList);
  const [arrAuditProcess, setArrAuditProcess] = useState([]);
  const [selectedAuditProcess, setSelectedAuditProcess] = useState("");
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState("");
  const [selectedAccountable, setSelectedAccountable] = useState("");
  const [dataUserInput, setDataUserInput] = useState({});
  const [arrAreas, setArrAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(-1);
  const dispatch = useDispatch();
  /* -------------------------------------------------------------------------- */
  /*                           GET DOCUMENT TEMPLATES                           */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    dispatch(getAllBusinessUnits());
    getKind();
    getUsers();
    cleanFilters();
  }, []);
  const getUsers = async () => {
    const result = await getAllUsers(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrUs = [];
      result.forEach((e) => {
        arrUs.push({ value: e.id, label: e.fullName });
      });
      setArrUsers(arrUs);
    }
  };
  const getKind = async () => {
    const result = await getPollKind(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrKind = [];
      result.forEach((e) => {
        arrKind.push({ value: e.id, label: e.name });
      });
      setArrKind(arrKind);
    }
  };
  const cleanFilters = () => {
    console.log("entroo");
    let arrBusiness = [];
    if (Array.isArray(arrBusinessList) && arrBusinessList.length > 0) {
      arrBusinessList.forEach((e) => {
        arrBusiness.push({ value: e.id, label: e.name });
      });
      console.log(arrBusiness);
      setBusinessList(arrBusiness);
    }
  };

  const getProcess = async (bUnit) => {
    let result = await getProcessByIdbUnit(bUnit, token);
    if (Array.isArray(result) && result.length > 0) {
      let arrProcess = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrProcess.push({ value: e.id, label: e.name });
      });
      setArrAuditProcess(arrProcess);
    }
  };
  const getAreas = async (bUnit) => {
    let result = await getAllAreas(bUnit, token);
    if (Array.isArray(result) && result.length > 0) {
      let arrAreas = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrAreas.push({ value: e.id, label: e.name });
      });
      setArrAreas(arrAreas);
    }
  };
  /* ----------------------------- helper function ---------------------------- */

  const addActivity = (e, i) => {
    const activity = {
      questionTitle: "",
      questionType: "",
    };
    setAddNumberRender([...addNumberRender, activity]);
  };

  /* -------------------------------------------------------------------------- */
  /*                               CREATE NEW POLL                              */
  /* -------------------------------------------------------------------------- */
  const submitPoll = async () => {
    if (
      isEmptyOrUndefined(selectedBusinessUnit)
      // isEmptyOrUndefined(validity) ||
      // isEmptyOrUndefined(processSelectValue) ||
      // isEmptyOrUndefined(subProcessSelectedValue) ||
      // isEmptyOrUndefined(selectedTagType) ||
      // isEmptyOrUndefined(selectedEditor) ||
      // isEmptyOrUndefined(selectedReviewer) ||
      // isEmptyOrUndefined(selectedApprover) ||
      // isEmptyOrUndefined(documentTitle) ||
      // isEmptyOrUndefined(filterSelectValue)
    ) {
      return message(
        "warning",
        "Advertencia",
        "Por favor llene todos los campos  obligatorios"
      );
    }
    const savePoll = await createNewPoll(
      dataUserInput.name,
      selectedBusinessUnit,
      selectedKind,
      selectedAuditProcess,
      selectedAccountable,
      selectedArea,
      ["Nothing"],
      token
    );
    if (savePoll) {
      if (!isEmptyOrUndefined(savePoll.docId)) {
        props.hideForm();
        return message("success", "Éxito", "Encuesta creada exitosamente");
      } else {
        return message("error", "Error", "No se pudo guardar el documento");
      }
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                           HANDLE CHANGES SECTION                           */
  /* -------------------------------------------------------------------------- */
  console.log(dataUserInput);

  const handleChangeTagType = (value) => {
    if (!isEmptyOrUndefined(value)) {
      if (value === 1) {
        setConditionalRender(
          <input
            // onChange={(e) => handleChangeValidity(e)}
            className="register-inputs"
          />
        );
      }
      if (value === 2) {
        setConditionalRender(
          <textarea
            style={{ height: "5.3rem" }}
            placeholder="Escribir..."
            rows="10"
            cols="100"
            className="register-inputs"
          />
        );
      }
      if (value === 3) {
        setConditionalRender(
          <>
            <div className="d-flex">
              <input className="mt-3" type="radio" />
              &nbsp;
              <input className="register-inputs" type="text" />
            </div>
          </>
        );
      }
      if (value === 4) {
        setConditionalRender(
          <div className="d-flex">
            <input className="mt-3" type="checkbox" />
            &nbsp;
            <input className="register-inputs" type="text" />
          </div>
        );
      }
      setSelectedTagType(value);
    }
  };

  const handleChangeKind = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedKind(e.value);
    }
  };
  const handleChangeBusinessUnit = (e) => {
    setSelectedAuditProcess(-1);

    if (!isEmptyOrUndefined(e.value)) {
      getProcess(e.value);
      getAreas(e.value);
      setSelectedBusinessUnit(e.value);
    }
  };
  const handleChangeAuditProcess = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedAuditProcess(e.value);
    }
  };
  const handleChangeAccountable = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedAccountable(e.value);
    }
  };
  const handleChangeArea = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedArea(e.value);
    }
  };
  const handleChangeInput = (e) => {
    let { value, name } = e.target;
    setDataUserInput({ ...dataUserInput, [name]: value });
  };
  const handleChangeActivity = (e, i) => {
    const { name, value } = e.target;
    let copy = deepcopy(addNumberRender);
    copy[i][name] = value;
    setAddNumberRender(copy);
  };
  const handleChangeActivitySelect = (e) => {
    const { name, value, i } = e;
    handleChangeTagType(value);
    if (!isEmptyOrUndefined(value)) {
      setSelectedTagType(value);
    }
    // console.log("nameSelect=>", name, value, i);
    let copy = deepcopy(addNumberRender);
    copy[i][name] = value;
    console.log(copy[i][name]);
    setAddNumberRender(copy);
  };

  return (
    <>
      <div className={"w-100"}>
        <div className={`${tableStyles.ItemSmall} mt-5`}>
          <Row
            className="d-flex justify-content-space-between "
            style={{ width: "90%" }}
          >
            <Col xs={12} className="ml-5 d-flex">
              <button onClick={props.hideForm}>
                <img src={leftArrow} alt="back"></img>
              </button>
              <h2 className="text-dark mb-3" style={{ marginLeft: "4%" }}>
                Crear encuesta
              </h2>
            </Col>
          </Row>
        </div>
        <div className={tableStyles.centeredContainer}>
          <div className={`${tableStyles.container1} mt-2`}>
            {/* /* -------------------------------- CARD ONE -------------------------------- */}
            <Card
              className={`${tableStyles.centeredContainer} ${tableStyles.shade} ${tableStyles.fourBorderRadiuses} mb-5`}
              style={{ width: "70%" }}
            >
              <h6 className={`${tableStyles.clearBlueText} mt-2`}>
                Información Básica
              </h6>
              {/* /* ---------------------------------- ROW 1 --------------------------------- */}
              <Row className="d-flex ">
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Unidad de negocio
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={businessList}
                    styles={customSelectNew}
                    value={businessList.find(
                      (obj) => obj.value === selectedBusinessUnit
                    )}
                    onChange={(e) => handleChangeBusinessUnit(e)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Nombre
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    className="register-inputs"
                    name="name"
                    onChange={(e) => handleChangeInput(e)}
                  />
                </Col>
                <Col xs={6}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Objetivo
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    className="register-inputs"
                    name="goal"
                    onChange={(e) => handleChangeInput(e)}
                  />
                </Col>
              </Row>
              {/* /* ---------------------------------- ROW 2 --------------------------------- */}
              <Row className="d-flex">
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Tipo encuesta
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    placeholder={""}
                    options={arrKind}
                    styles={customSelectNew}
                    value={arrKind.find((obj) => obj.value === selectedKind)}
                    onChange={(e) => handleChangeKind(e)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Proceso
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrAuditProcess}
                    styles={customSelectNew}
                    value={arrAuditProcess.find(
                      (obj) => obj.value === selectedAuditProcess
                    )}
                    onChange={(e) => handleChangeAuditProcess(e)}
                  ></Select>
                </Col>
                <Col xs={6}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Responsable
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrUsers}
                    styles={customSelectNew}
                    value={arrUsers.find(
                      (obj) => obj.value === selectedAccountable
                    )}
                    onChange={(e) => handleChangeAccountable(e)}
                  ></Select>
                </Col>
              </Row>
              {/* /* ---------------------------------- ROW 3 --------------------------------- */}
              <Row className="d-flex mb-4">
                <Col xs={6}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Aplicado al área de
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    placeholder={""}
                    options={arrAreas}
                    styles={customSelectNew}
                    value={arrAreas.find((obj) => obj.value === selectedArea)}
                    onChange={(e) => handleChangeArea(e)}
                  ></Select>
                </Col>
              </Row>
            </Card>
            {/* /* -------------------------------- CARD TWO -------------------------------- */}
            <Card
              className={`${tableStyles.centeredContainer} ${tableStyles.shade} ${tableStyles.fourBorderRadiuses} mb-5`}
              style={{ width: "70%" }}
            >
              <h6 className={`${tableStyles.clearBlueText} mt-2`}>
                Formulario
              </h6>

              {/* --------------------------------- ROW 3-CONDITIONAL-------------------------- */}
              {addNumberRender.length > 0 &&
                addNumberRender.map((e, i) => {
                  return (
                    <>
                      <Row className="d-flex">
                        <Col xs={8}>
                          <input
                            name="questionTitle"
                            onChange={(e) => handleChangeActivity(e, i)}
                            className="register-inputs"
                            type="text"
                          ></input>
                        </Col>
                        <Col xs={4} className={"mb-3"}>
                          <Select
                            placeholder={"Seleccione"}
                            placeholder={""}
                            selectOption={selectedTagType}
                            options={arrTagTypes}
                            styles={customSelectNew}
                            value={arrTagTypes.find(
                              (obj) => obj.value === selectedTagType
                            )}
                            onChange={(e) => {
                              handleChangeActivitySelect({
                                ...e,
                                name: `questionType`,
                                i: i,
                              });
                            }}
                          ></Select>
                        </Col>
                      </Row>
                      {/* /* ---------------------------------- ROW 2 --------------------------------- */}
                      <Row className="d-flex mb-5">
                        <Col xs={8}>{conditionalRender}</Col>
                      </Row>
                    </>
                  );
                })}
              <Button
                className={"btn-md mt-5 mb-3 btn-light"}
                // onClick={addActivity}
                disabled={true}
                style={{ backgroundColor: "white", margin: "0 auto" }}
                disabled={true}
              >
                <img src={whitePlus}></img>
                Agregar nueva pregunta
              </Button>
            </Card>
          </div>
        </div>
        <div className={`customTabs__Footer mt-5`} style={{ width: "84%" }}>
          <Button
            onClick={() => submitPoll()}
            className={tableStyles.btnPrimary}
          >
            Crear
          </Button>
          <Button onClick={props.hideForm} className={tableStyles.btnSecondary}>
            Cancelar
          </Button>
        </div>
      </div>
    </>
  );
};
