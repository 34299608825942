/* ---------------------------- NEW ACTION TYPES ---------------------------- */
/* -------------------------------- DOCUMENTS ------------------------------- */
export const SAVE_DOCUMENT_LIST = "SAVE_DOCUMENT_LIST";
export const SAVE_DOCUMENT_TYPES = "SAVE_DOCUMENT_TYPES";
export const LOADING_SAVE_DOCUMENT_LIST = "LOADING_SAVE_DOCUMENT_LIST";
export const SAVE_INPROCESS_DOCUMENT_LIST = "LOADING_SAVE_DOCUMENT_LIST";
export const SAVE_GET_DOCUMENT = "SAVE_GET_DOCUMENT";
export const LOADING_SAVE_INPROCESS_DOCUMENT_LIST =
  "LOADING_SAVE_DOCUMENT_LIST";

export const SAVE_OBSOLETE_DOCUMENTS = "SAVE_OBSOLETE_DOCUMENTS";
export const LOADING_SAVE_OBSOLETE_DOCUMENTS =
  "LOADING_SAVE_OBSOLETE_DOCUMENTS";
export const SAVE_DOCUMENT_ID = "SAVE_DOCUMENT_ID";
/* ------------------------------- AUDITORIES ------------------------------- */
export const SAVE_UDITORIES_LIST = "SAVE_UDITORIES_LIST";
export const LOADING_SAVE_UDITORIES_LIST = "LOADING_SAVE_UDITORIES_LIST";
/* ------------------------------- INDICATORS ------------------------------- */
export const SAVE_INDICATORS_LIST = "SAVE_INDICATORS_LIST";
export const LOADING_SAVE_INDICATORS_LIST = "LOADING_SAVE_INDICATORS_LIST";
export const SAVE_INDICATOR_ID = "SAVE_INDICATOR_ID";
export const SAVE_INDICATOR = "SAVE_INDICATOR";
export const SAVE_INDICATORS_TYPE = "SAVE_INDICATORS_TYPE";
/* ------------------------------- ACTION PLAN ------------------------------ */
export const SAVE_ACTION_PLAN_LIST = "SAVE_ACTION_PLAN_LIST";
export const LOADING_SAVE_ACTION_PLAN_LIST = "LOADING_SAVE_ACTION_PLAN_LIST";
/* ------------------------------- POLLS ------------------------------------ */
export const SAVE_POLL_LIST = "SAVE_POLL_LIST";
export const LOADING_SAVE_POLL_LIST = "LOADING_SAVE_POLL_LIST";
export const SAVE_POLL_TYPES = "SAVE_POLL_TYPES";
/* ------------------------------- TOOLS ------------------------------------ */
export const SAVE_TOOLS_LIST = "SAVE_TOOLS_LIST";
export const LOADING_SAVE_TOOLS_LIST = "LOADING_SAVE_TOOLS_LIST";
/* ------------------------------- COMITES------------------------------------ */
export const SAVE_COMITES_LIST = "SAVE_COMITES_LIST";
export const LOADING_SAVE_COMITES_LIST = "LOADING_SAVE_COMITES_LIST";
export const SAVE_COMITES_TYPES = "SAVE_COMITES_TYPES";
/* ------------------------------- ADVERSE EVENTS------------------------------ */
export const SAVE_ADVERSE_EVENTS_LIST = "SAVE_ADVERSE_EVENTS_LIST";
export const LOADING_SAVE_ADVERSE_EVENTS_LIST =
  "LOADING_SAVE_ADVERSE_EVENTS_LIST";
/* ------------------------------- BUSINESS UNITS------------------------------ */
export const SAVE_BUSINESS_UNITS_LIST = "SAVE_BUSINESS_UNITS_LIST";
export const LOADING_SAVE_BUSINESS_UNITS_LIST =
  "LOADING_SAVE_BUSINESS_UNITS_LIST";
export const SAVE_IDENTITIES_LIST = "SAVE_IDENTITIES_LIST";
export const SAVE_GENDER_LIST = "SAVE_GENDER_LIST";
export const SAVE_INSURANCES_LIST = "SAVE_INSURANCES_LIST";
/*--------------------------------- USERS ----------------------------------*/
export const LOADING_SAVE_USERS_LIST = "LOADING_SAVE_USERS_LIST";
export const SAVE_USERS_LIST = "SAVE_USERS_LIST";
// --------------------------------- STATUS ----------------------------------
export const SAVE_ALL_POLL_STATUS = "SAVE_ALL_POLL_STATUS";
export const SAVE_ALL_TOOL_5_STATUS = "SAVE_ALL_TOOL_5_STATUS";

/* ------------------------------------ X ----------------------------------- */
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const SHOW_ACCOUNT_INFO = "SHOW_ACCOUNT_INFO";
export const SAVE_USER_INFO = "SAVE_USER_INFO";
// export const DISPLAY_INFO_MESSAGE_LOGIN = "DISPLAY_INFO_MESSAGE_LOGIN";
export const HIDE_INFO_MESSAGE_LOGIN = "HIDE_INFO_MESSAGE_LOGIN";
// export const GET_USERS = "GET_USERS";
// export const GET_ALL_USERS = "GET_ALL_USERS";
// export const SET_INDEX_USER = "SET_INDEX_USER";

// export const SET_LOADING_USER = "SET_LOADING_USER";
export const SET_LOADING_PROFILE = "SET_LOADING_PROFILE";

export const GET_PROFILES_FROM_ACCOUNT = "GET_PROFILES_FROM_ACCOUNT";
// export const SET_INDEX_FILTER_USER = "SET_INDEX_FILTER_USER";
// export const USER = "USER";
// export const GET_DIAGNOSTICS = "GET_DIAGNOSTICS";
// export const SET_INDEX_DIAGNOSTICS = "SET_INDEX_DIAGNOSTICS";
// export const CREATE_DIAGNOSTIC = "CREATE_DIAGNOSTIC";
export const CREATE_USER = "CREATE_USER";
export const GET_PROFILES = "GET_PROFILES";

export const PERMISSION_MODULE_GET = "PERMISSION_MODULE_GET";
export const PERMISSION_FUNCTIONALITY_GET = "PERMISSION_FUNCTIONALITY_GET";
export const PERMISSION_GET = "PERMISSION_GET";
export const PERMISSION_PUT = "PERMISSION_PUT";
export const CUPS_GET = "CUPS_GET";
export const CUPS_EDIT = "CUPS_EDIT";

// export const CLASSIFICATION_GET = "CLASSIFICATION_GET";
// export const CLASSIFICATION_GETONE = "CLASSIFICATION_GETONE";
// export const CLASSIFICATION_EDIT = "CLASSIFICATION_EDIT";
// export const CLASSIFICATION_POST = "CLASSIFICATION_POST";
// export const CLASSIFICATION_DELETE = "CLASSIFICATION_DELETE";

// export const GET_SITES = "GET_SITES";
// export const CREATE_SITE_SUCCESS = "CREATE_SITE_SUCCESS";
// export const ERROR_CREATE_SITE = "ERROR_CREATE_SITE";
// export const SET_INDEX_SITE = "SET_INDEX_SITE";
// export const GET_CIETEN = "GET_CIETEN";
// export const SET_INDEX_CIETEN = "SET_INDEX_CIETEN";
// export const CHANGE_PAGE_CIETEN = "CHANGE_PAGE_CIETEN";
// export const COUNT_USERS = "COUNT_USERS";

export const GET_NIIF_ACCOUNTS = "GET_NIIF_ACCOUNTS";
export const GET_LOCAL_ACCOUNTS = "GET_LOCAL_ACCOUNTS";
export const SET_INDEX_NIIF = "SET_INDEX_NIIF";
export const SET_INDEX_LOCAL_ACCOUNTS = "SET_INDEX_LOCAL_ACCOUNTS";
export const GET_ALL_ACCOUNTS = "GET_ALL_ACCOUNTS";
export const SET_INDEX_PUC = "SET_INDEX_PUC";
export const SET_TOTAL_INDEX_PUC = "SET_TOTAL_INDEX_PUC";
export const GET_PUC_ACCOUNTS = "GET_PUC_ACCOUNTS";
export const RENDER_ACCOUNTS = "RENDER_ACCOUNTS";
export const CREATE_PUC_ACCOUNT_SUCCESS = "CREATE_PUC_ACCOUNT_SUCCESS";
export const ERROR_CREATE_PUC_ACCOUNT = "ERROR_CREATE_PUC_ACCOUNT";

// export const CUENTAS_GET = "CUENTAS_GET";
// export const CUENTAS_GETONE = "CUENTAS_GETONE";
// export const CUENTAS_EDIT = "CUENTAS_EDIT";
// export const CUENTAS_POST = "CUENTAS_POST";

// export const CITY_GET = "CITY_GET";
// export const PROVINCE_GET = "PROVINCE_GET";
// export const COUNTRY_GET = "COUNTRY_GET";

// export const FUNCTIONAL_GET = "FUNCTIONAL_GET";
// export const FUNCTIONAL_GETONE = "FUNCTIONAL_GETONE";
// export const FUNCTIONAL_EDIT = "FUNCTIONAL_EDIT";
// export const FUNCTIONAL_POST = "FUNCTIONAL_POST";
// export const FUNCTIONAL_GET_NIIF = "FUNCTIONAL_GET_NIIF";
// export const FUNCTIONAL_GET_SITES = "FUNCTIONAL_GET_SITES";
// export const FUNCTIONAL_ACTIVES = "FUNCTIONAL_ACTIVES";

// export const COSTCENTER_GET = "COSTCENTER_GET";
// export const COSTCENTER_GETONE = "COSTCENTER_GETONE";
// export const COSTCENTER_EDIT = "COSTCENTER_EDIT";
// export const COSTCENTER_POST = "COSTCENTER_POST";
// export const GET_ALL_COST_CENTER = "GET_ALL_COST_CENTER";

// export const GET_MU = "GET_MU";
// export const SET_INDEX_MU = "SET_INDEX_MU";

// export const GET_LIST_INVENTORY_FAMILIES_TO_SELECT =
//   "GET_LIST_INVENTORY_FAMILIES_TO_SELECT";
// export const GET_LIST_MANUFACTURERS_TO_SELECT =
//   "GET_LIST_MANUFACTURERS_TO_SELECT";
// export const CREATE_ARTICLE = "CREATE_ARTICLE";
// export const GET_ALL_ARTICLES = "GET_ALL_ARTICLES";
// export const SET_INDEX_ARTICLES = "SET_INDEX_ARTICLES";
// export const SET_TOTAL_INDEX_ARTICLES = "SET_TOTAL_INDEX_ARTICLES";
// export const SUCCESS_UPLOAD = "SUCCESS_UPLOAD";
// export const LOADING = "LOADING";
// export const GET_ALL_MU = "GET_ALL_MU";

// export const GET_NIIF_AUX = "GET_NIIF_AUX";
// export const GET_ADD_INFO = "GET_ADD_INFO";
// export const INFO_ID = "INFO_ID";
// export const GET_INVENTORY_FAMILY = "GET_INVENTORY_FAMILY";
// export const SET_INDEX_INVENTORY_FAMILY = "SET_INDEX_INVENTORY_FAMILY";
// export const GET_INVENTORY_ACTIVE = "GET_INVENTORY_ACTIVE";

// export const GET_CON_CENTER = "GET_CON_CENTER";
// export const GET_CON_CENTER_ONE = "GET_CON_CENTER_ONE";
// export const SET_INDEX_CON_CENTER = "SET_INDEX_CON_CENTER";
// export const GET_AUX_NIIF = "GET_AUX_NIIF";

// export const STORAGE_SUPPLIER = "STORAGE_SUPPLIER";
// export const STORAGE_GET = "STORAGE_GET";
// export const STORAGE_EDIT = "STORAGE_EDIT";
// export const STORAGE_POST = "STORAGE_POST";
export const ARTICLE_GET = "ARTICLE_GET";
// export const DELETE_WAREHOUSE_ARTICLE = "DELETE_WAREHOUSE_ARTICLE";

export const ACCOUNT_SELECT_GET = "ACCOUNT_SELECT_GET";
export const ACCOUNT_SELECT_SAVE = "ACCOUNT_SELECT_SAVE";
export const ACCOUNT_LOADING = "ACCOUNT_LOADING";
export const CUPS_FILTER = "CUPS_FILTER";

// export const REQUISITION_FILTER = "REQUISITION_FILTER";
// export const REQUISITION_POST = "REQUISITION_POST";
// export const REQUISITION_GET = "REQUISITION_GET";
// export const REQUISITION_ARTICLE = "REQUISITION_ARTICLE";
// export const REQUISITION_LOADING = "REQUISITION_LOADING";

// export const REQUISITION_ARTICLES_FROM_LIST = "REQUISITION_ARTICLES_FROM_LIST";
// export const REQUISITION_LOADING2 = "REQUISITION_LOADING2";
// export const REQUISITION_LOADING3 = "REQUISITION_LOADING3";

// export const INDIVIDUALAUCTION_GET_ONE = "INDIVIDUALAUCTION_GET_ONE";
// export const INDIVIDUALAUCTION_GET_ONE_LOADING =
//   "INDIVIDUALAUCTION_GET_ONE_LOADING";
// export const INDIVIDUALAUCTION_FILTER = "INDIVIDUALAUCTION_FILTER";
// export const QUOTATION_FILTER = "QUOTATION_FILTER";
// export const QUOTATION_FILTER_LOADING = "QUOTATION_FILTER_LOADING";

// export const GET_SPECIALITY = "GET_SPECIALITY";
// export const GET_SUBSPECIALITY = "GET_SUBSPECIALITY";
// export const GET_ALL_SPECIALITY = "GET_ALL_SPECIALITY";
// export const SET_INDEX_SPECIALITY = "SET_INDEX_SPECIALITY";
// export const SET_INDEX_SUBSPECIALITY = "SET_INDEX_SUBSPECIALITY";

// export const REQUISITION_CONSECUTIVE = "REQUISITION_CONSECUTIVE";

// export const INDIVIDUALAUCTION_POST = "INDIVIDUALAUCTION_POST";
// export const INDIVIDUALAUCTION_ARTICLE = "INDIVIDUALAUCTION_ARTICLE";
// export const INDIVIDUALAUCTION_CONSECUTIVE = "INDIVIDUALAUCTION_CONSECUTIVE";
// export const CURRENTAUCTION = "CURRENTAUCTION";
// export const GET_PROVIDERS = "GET_PROVIDERS";
// export const PROVINCE_GET_ALTERNATIVE = "PROVINCE_GET_ALTERNATIVE";
// export const CITY_GET_ALTERNATIVE = "CITY_GET_ALTERNATIVE";
// export const GET_COUNTRIES = "GET_COUNTRIES";
// export const GET_CIIU = "GET_CIIU";
// export const GET_PROVIDER_CLASSIFICATION = "GET_PROVIDER_CLASSIFICATION";
// export const CREATE_DRAFT_PROVIDER = "CREATE_DRAFT_PROVIDER";
// export const PROVINCE_GET_ALL = "PROVINCE_GET_ALL";
// export const GET_EDIT_PROVIDER = "GET_EDIT_PROVIDER";
// export const CITY_GET_ALL = "CITY_GET_ALL";
// export const GET_CORPORATECLIENTS = "GET_CORPORATECLIENTS";
// export const SET_CORPORATECLIENTS = "SET_CORPORATECLIENTS";
// export const GET_BANKING_ENTITIES = "GET_BANKING_ENTITIES";
