//Login's reducer

import {
  SAVE_INDICATORS_LIST,
  LOADING_SAVE_INDICATORS_LIST,
  SAVE_INDICATOR_ID,
  SAVE_INDICATOR,
  SAVE_INDICATORS_TYPE,
} from "../actions/actionTypes";

const initialState = {
  indicators: [],
  indicatorsLoading: false,
  indicatorId: null,
  indicatorData:null,
  indicatorsType:[]
};
export const indicatorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_INDICATORS_LIST:
      return {
        ...state,
        indicators: action.payload,
        indicatorsLoading: action.loading,
      };
    case SAVE_INDICATOR_ID:
      return {
        ...state,
        indicatorId: action.payload,
      };
    case SAVE_INDICATORS_TYPE:
      return {
        ...state,
        indicatorsType: action.payload,
      };

    case LOADING_SAVE_INDICATORS_LIST:
      return {
        ...state,
        indicatorsLoading: action.loading,
      };

    case SAVE_INDICATOR:
      return {
        ...state,
        indicatorData: action.payload,
      };

    default:
      return state;
  }
};
