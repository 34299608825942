import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Select from "react-select";
import tableStyles from "../Layouts/tableStyle.module.scss";
import question from "../../assets/img/icons/question.svg";
import verticaLine from "../../assets/img/icons/verticaLine.svg";
import leftArrow from "../../assets/img/icons/leftArrow.svg";
import { useSelector } from "react-redux";
import { getAllUsers } from "../../actions/documentActions";
import { isEmptyOrUndefined, message } from "../../helpers/helpers";
import { customSelectNew } from "../Layouts/react-select-custom";
import { getAuditoriesTypes } from "../../actions/auditoriesAction";
import {
  getAllMeasurementFrequency,
  getAllMeasurementUnit,
  getBranchOfficeBybUnit,
  getProcessByIdbUnit,
} from "../../actions/businessUnits";
import {
  createNewIndicator,
  getAllIndicatorsKind,
  getAllIndicatorsTypes,
} from "../../actions/indicatorsActions";
export const IndicatorsForm = (props) => {
  const store = useSelector((state) => state);
  const arrBusinessList = store.businessUnitsReducer.businessUnits;
  const token = store.loginReducer.Authorization;
  const [arrUsers, setArrUsers] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState("");
  const [arrTypes, setArrTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedAccountable, setSelectedAccountable] = useState("");
  const [selectedFeeder, setSelectedFeeder] = useState("");
  const [arrAuditProcess, setArrAuditProcess] = useState([]);
  const [selectedAuditProcess, setSelectedAuditProcess] = useState("");
  const [dataUserInput, setDataUserInput] = useState({});
  const [arrBranchOffices, setArrBranchOffices] = useState([]);
  const [selectedBranchOffice, setSelectedBranchOffice] = useState("");
  const [measurement, setMeasurement] = useState([]);
  const [selectedMeasurement, setSelectedMeasurement] = useState(-1);
  const [mesurementFrequency, setMesurementFrequency] = useState([]);
  const [selectedMesurementFrequency, setSelectedMesurementFrequency] =
    useState(-1);
  const [arrIndicatorKind, setArrIndicatorKind] = useState([]);
  const [selectedIndicatorKind, setSelectedIndicatorKind] = useState(-1);
  const [trend, setTrend] = useState(false);

  useEffect(() => {
    cleanFilters();
    getUsers();
    getTypes();
    getMeasurement();
    getMeasurementFrequency();
    getIndicatorsKind();
  }, []);
  const getTypes = async () => {
    const result = await getAllIndicatorsTypes(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrTypes = [];
      result.forEach((e) => {
        arrTypes.push({ value: e.id, label: e.name });
      });
      setArrTypes(arrTypes);
    }
  };
  const getUsers = async () => {
    const result = await getAllUsers(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrUs = [];
      result.forEach((e) => {
        arrUs.push({ value: e.id, label: e.fullName });
      });
      setArrUsers(arrUs);
    }
  };
  const getProcess = async (bUnit) => {
    let result = await getProcessByIdbUnit(bUnit, token);
    if (Array.isArray(result) && result.length > 0) {
      let arrProcess = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrProcess.push({ value: e.id, label: e.name });
      });
      setArrAuditProcess(arrProcess);
    }
  };
  const cleanFilters = () => {
    let arrBusiness = [];
    if (Array.isArray(arrBusinessList) && arrBusinessList.length > 0) {
      arrBusinessList.forEach((e) => {
        arrBusiness.push({ value: e.id, label: e.name });
      });
      setBusinessList(arrBusiness);
    }
  };
  const getMeasurement = async () => {
    let result = await getAllMeasurementUnit(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrMeasurement = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrMeasurement.push({ value: e.id, label: e.name });
      });
      setMeasurement(arrMeasurement);
    }
  };
  const getMeasurementFrequency = async () => {
    let result = await getAllMeasurementFrequency(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrMeasurementFrequency = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrMeasurementFrequency.push({ value: e.id, label: e.name });
      });
      setMesurementFrequency(arrMeasurementFrequency);
    }
  };
  const getBranchOffices = async (bUnit) => {
    let result = await getBranchOfficeBybUnit(bUnit, token);
    if (Array.isArray(result) && result.length > 0) {
      let arrBranchOffices = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrBranchOffices.push({ value: e.id, label: e.name });
      });
      setArrBranchOffices(arrBranchOffices);
    }
  };
  const getIndicatorsKind = async (bUnit) => {
    let result = await getAllIndicatorsKind(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrIndKind = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrIndKind.push({ value: e.id, label: e.name });
      });
      setArrIndicatorKind(arrIndKind);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                              CREATE INDICATER                              */
  /* -------------------------------------------------------------------------- */
  const sumbitIndicator = async () => {
    if (
      isEmptyOrUndefined(dataUserInput.name) ||
      isEmptyOrUndefined(selectedType) ||
      isEmptyOrUndefined(selectedBranchOffice) ||
      isEmptyOrUndefined(selectedAuditProcess) ||
      isEmptyOrUndefined(selectedMeasurement) ||
      isEmptyOrUndefined(selectedMesurementFrequency) ||
      isEmptyOrUndefined(selectedAccountable) ||
      isEmptyOrUndefined(selectedFeeder) ||
      isEmptyOrUndefined(selectedIndicatorKind) ||
      isEmptyOrUndefined(dataUserInput.numerator) ||
      isEmptyOrUndefined(dataUserInput.denominator) ||
      isEmptyOrUndefined(dataUserInput.minGoal) ||
      isEmptyOrUndefined(dataUserInput.nomGoal) ||
      isEmptyOrUndefined(dataUserInput.maxGoal)
    ) {
      props.hideForm();
      return message(
        "warning",
        "Advertencia",
        "Por favor llene todos los campos  obligatorios"
      );
    }
    const saveIndicator = await createNewIndicator(
      dataUserInput.name,
      selectedType,
      selectedBranchOffice,
      selectedAuditProcess,
      dataUserInput.description,
      selectedMeasurement,
      selectedMesurementFrequency,
      selectedAccountable,
      selectedFeeder,
      selectedIndicatorKind,
      trend,
      dataUserInput.numerator,
      dataUserInput.denominator,
      dataUserInput.minGoal,
      dataUserInput.nomGoal,
      dataUserInput.maxGoal,
      token
    );
    if (!isEmptyOrUndefined(saveIndicator)) {
      props.hideForm();
      return message("success", "Éxito", "Indicador creado exitosamente.");
    } else {
      return message("error", "Error", "No se pudo guardar el documento");
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                           HANDLE CHANGES SECTION                           */
  /* -------------------------------------------------------------------------- */
  console.log(dataUserInput);
  const handleChangeInput = (e) => {
    let { value, name } = e.target;
    // console.log(value, name);
    setDataUserInput({ ...dataUserInput, [name]: value });
  };
  const handleChangeAccountable = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedAccountable(e.value);
    }
  };
  const handleChangeFeeder = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedFeeder(e.value);
    }
  };
  const handleChangeTypes = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedType(e.value);
    }
  };
  const handleChangeBranchOffice = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedBranchOffice(e.value);
    }
  };
  const handleChangeBusinessUnit = (e) => {
    setSelectedAuditProcess(-1);
    setSelectedBranchOffice(-1);
    if (!isEmptyOrUndefined(e.value)) {
      getProcess(e.value);
      getBranchOffices(e.value);
      setSelectedBusinessUnit(e.value);
    }
  };
  const handleChangeAuditProcess = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedAuditProcess(e.value);
    }
  };
  const handleChangeMesurement = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedMeasurement(e.value);
    }
  };
  const handleChangeMesurementFrequency = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedMesurementFrequency(e.value);
    }
  };
  const handleChangeIndicatorKind = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedIndicatorKind(e.value);
    }
  };

  return (
    <>
      <div className={"w-100"}>
        <div className={`${tableStyles.ItemSmall} mt-5`}>
          <Row
            className="d-flex justify-content-space-between "
            style={{ width: "100%" }}
          >
            <Col xs={12} className="ml-5 d-flex" style={{ width: "80%" }}>
              <button onClick={props.hideForm}>
                <img src={leftArrow} alt="back"></img>
              </button>
              <h2 className="text-dark mb-3" style={{ marginLeft: "4%" }}>
                Crear Indicador
              </h2>
            </Col>
          </Row>
        </div>
        <div className={tableStyles.centeredContainer}>
          <div className={`${tableStyles.container1} mt-2`}>
            <Card
              className={`${tableStyles.fourBorderRadiuses} ${tableStyles.container} ${tableStyles.bgColorClearBlue}`}
              style={{ borderWidth: 0 }}
            >
              <h6 className={`${tableStyles.clearBlueText} mt-2`}>
                Información Básica
              </h6>
              {/* ---------------------------- ROW 1 ---------------------------------- */}
              <Row className="d-flex" style={{ width: "100%" }}>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Nombre de indicador
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    className="register-inputs"
                    name="name"
                    onChange={(e) => handleChangeInput(e)}
                  />
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Unidad de negocio
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={businessList}
                    styles={customSelectNew}
                    value={businessList.find(
                      (obj) => obj.value === selectedBusinessUnit
                    )}
                    onChange={(e) => handleChangeBusinessUnit(e)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Unidad de medición
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={measurement}
                    styles={customSelectNew}
                    value={measurement.find(
                      (obj) => obj.value === selectedMeasurement
                    )}
                    onChange={(e) => handleChangeMesurement(e)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Frecuencia de medición
                    <span className={"text-danger"}>*</span>
                    <img src={question}></img>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={mesurementFrequency}
                    styles={customSelectNew}
                    value={mesurementFrequency.find(
                      (obj) => obj.value === selectedMesurementFrequency
                    )}
                    onChange={(e) => handleChangeMesurementFrequency(e)}
                  ></Select>
                </Col>
              </Row>
              {/* ---------------------------- ROW 2 ---------------------------------- */}
              <Row className="d-flex" style={{ width: "100%" }}>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Tipo
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrTypes}
                    styles={customSelectNew}
                    value={arrTypes.find((obj) => obj.value === selectedType)}
                    name="type"
                    onChange={(e) => handleChangeTypes(e)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Sede
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrBranchOffices}
                    styles={customSelectNew}
                    value={arrBranchOffices.find(
                      (obj) => obj.value === selectedBranchOffice
                    )}
                    onChange={(e) => handleChangeBranchOffice(e)}
                  ></Select>
                </Col>

                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Responsable
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrUsers}
                    styles={customSelectNew}
                    value={arrUsers.find(
                      (obj) => obj.value === selectedAccountable
                    )}
                    onChange={(e) => handleChangeAccountable(e)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Responsable alimentador
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrUsers}
                    styles={customSelectNew}
                    value={arrUsers.find((obj) => obj.value === selectedFeeder)}
                    onChange={(e) => handleChangeFeeder(e)}
                  ></Select>
                </Col>
              </Row>
              {/* ---------------------------- ROW 3 ---------------------------------- */}
              <Row className="d-flex" style={{ width: "100%" }}>
                <Col xs={6}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Proceso<span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrAuditProcess}
                    styles={customSelectNew}
                    value={arrAuditProcess.find(
                      (obj) => obj.value === selectedAuditProcess
                    )}
                    onChange={(e) => handleChangeAuditProcess(e)}
                  ></Select>
                </Col>
                <Col xs={6}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Clase de indicador<span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrIndicatorKind}
                    styles={customSelectNew}
                    value={arrIndicatorKind.find(
                      (obj) => obj.value === selectedIndicatorKind
                    )}
                    onChange={(e) => handleChangeIndicatorKind(e)}
                  ></Select>
                </Col>
              </Row>
              {/* ---------------------------- ROW 4 ---------------------------------- */}
              <Row className="d-flex mb-2" style={{ width: "100%" }}>
                <Col xs={6}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Descripción
                  </p>
                  <textarea
                    name="description"
                    style={{ height: "3rem" }}
                    placeholder="Escribir..."
                    rows="5"
                    cols="40"
                    className="register-inputs"
                    onChange={(e) => handleChangeInput(e)}
                  />
                </Col>
              </Row>
            </Card>
            {/* /* -------------------------------- BELOW ROW ------------------------------- */}
            <div style={{ padding: "15px" }}>
              <h6 className={`${tableStyles.clearBlueText} mt-2`}>
                Descripción
              </h6>
              {/* /* ---------------------------------- ROW 1 --------------------------------- */}
              <Row className="d-flex" style={{ width: "100%" }}>
                <Col xs={4}>
                  <div className="d-flex ">
                    <input
                      className="mt-3"
                      type="checkbox"
                      onChange={() => setTrend(!trend)}
                    />
                    &nbsp;
                    <small className={tableStyles.fZSmall}>
                      Es de tendencia positiva
                      <p className="text-muted">
                        Corresponde a la tendencia de valor esperados
                      </p>
                    </small>
                  </div>
                </Col>
                <Col xs={1}>
                  <div className="text-center">
                    <img src={verticaLine}></img>
                  </div>
                </Col>

                <Col xs={4}>
                  <div className="d-flex ">
                    <input
                      className="mt-3"
                      type="checkbox"
                      checked={false}
                      disabled={true}
                    />
                    &nbsp;
                    <small className={tableStyles.fZSmall}>
                      Es de meta variable
                      <p className="text-muted">
                        La meta puede ser definida por el indicador
                      </p>
                    </small>
                  </div>
                </Col>
              </Row>
              {/* /* ---------------------------------- ROW 2 --------------------------------- */}
              <Row className="d-flex" style={{ width: "100%" }}>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Descripción del numerador
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    name="numerator"
                    onChange={(e) => handleChangeInput(e)}
                    className="register-inputs"
                  />
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Descripción del denominador
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    name="denominator"
                    onChange={(e) => handleChangeInput(e)}
                    className="register-inputs"
                  />
                </Col>

                <Col xs={6}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Fórmula
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    disabled={true}
                    value={"[NUM]/[DEN]*100"}
                    onChange={(e) => handleChangeInput(e)}
                    className="register-inputs"
                  />
                </Col>
              </Row>
              {/* /* ---------------------------------- ROW 3 --------------------------------- */}
              <Row className="d-flex" style={{ width: "100%" }}>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Meta mínima
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    type="number"
                    name="minGoal"
                    onChange={(e) => handleChangeInput(e)}
                    className="register-inputs"
                  />
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Meta nominal
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    name="nomGoal"
                    type="number"
                    onChange={(e) => handleChangeInput(e)}
                    className="register-inputs"
                  />
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Meta máxima
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    type="number"
                    name="maxGoal"
                    onChange={(e) => handleChangeInput(e)}
                    className="register-inputs"
                  />
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Consolidación de datos
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    disabled={true}
                    value={"Promedio"}
                    // onChange={(e) => handleChangeInput(e)}
                    className="register-inputs"
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className={`customTabs__Footer mt-5`} style={{ width: "90%" }}>
          <Button
            onClick={() => sumbitIndicator()}
            className={tableStyles.btnPrimary}
          >
            Crear Indicador
          </Button>
          <Button
            onClick={props.hideForm}
            onClick={props.hideForm}
            className={tableStyles.btnSecondary}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </>
  );
};
