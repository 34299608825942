import React, { Component } from "react";
import "../TableUsers/tableUserStyle.scss";
import tableStyles from "./tableStyle.module.scss";

const GenericTable = (props) => {
  return (
    <table
      className={`table ${tableStyles.genericTable} ${tableStyles.boxShadow} `}
    >
      <thead className={props.theadTheme}>
        <tr>{props.headers}</tr>
      </thead>
      <tbody>{props.children}</tbody>
    </table>
  );
};

export default GenericTable;
