//Login's reducer

import {
  SAVE_COMITES_LIST,
  LOADING_SAVE_COMITES_LIST,
  SAVE_COMITES_TYPES
} from "../actions/actionTypes";

const initialState = {
  comitesList: [],
  comitesTypes: [],
  actionPlanLoading: false
};
export const comitesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_COMITES_LIST:
      return {
        ...state,
        comitesList: action.payload,
        actionPlanLoading: action.loading,
      };
    case LOADING_SAVE_COMITES_LIST:
      return {
        ...state,
        actionPlanLoading: action.loading,
      };
    case SAVE_COMITES_TYPES:
      return {
        ...state,
        comitesTypes: action.payload,
      };

    default:
      return state;
  }
};
