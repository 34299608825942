// function to validate a email structure
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import { logOutUser } from "../actions/loginActions";

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const isEmptyOrUndefined = (value) => {
  if (
    value === undefined ||
    value === null ||
    value === "" ||
    value === NaN ||
    value === -1
  ) {
    return true;
  } else {
    return false;
  }
};

export const message = (icon, title, text) => {
  Swal.fire({
    icon,
    title,
    text,
  });
};

export const TokenIsExpired = (statusCode, dispatch, props) => {
  if (statusCode === 401) {
    dispatch(logOutUser());
    message(
      "warning",
      "Advertencia!",
      "Su sesión ha expirado, por favor ingrese nuevamente"
    );
    console.log("entro a token noseke", statusCode);
    window.location.href = "/login";
  }
};
