//Login's reducer

import {
  SAVE_ACTION_PLAN_LIST,
  LOADING_SAVE_ACTION_PLAN_LIST,
} from "../actions/actionTypes";

const initialState = {
  actionPlans: [],
  actionPlanLoading: false,
};
export const actionPlansReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_ACTION_PLAN_LIST:
      return {
        ...state,
        actionPlans: action.payload,
        actionPlanLoading: action.loading,
      };
    case LOADING_SAVE_ACTION_PLAN_LIST:
      return {
        ...state,
        actionPlanLoading: action.loading,
      };

    default:
      return state;
  }
};
