import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import leftArrow from "../../assets/img/icons/leftArrow.svg";
import tableStyles from "../Layouts/tableStyle.module.scss";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { convertDateToLatinFormat } from "../../helpers/convertDateFormat";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmptyOrUndefined, message } from "../../helpers/helpers";
import { feedIndicator, getAnIndicator } from "../../actions/indicatorsActions";

export const IndicatorsFeedForm = (props) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const idIndicator = store.indicatorsReducer.indicatorId;
  const token = store.loginReducer.Authorization;
  const history = useHistory();
  const [dateCalendar, setDateCalendar] = useState(new Date());
  const [convertedDate, setConvertedDate] = useState("");
  const [dataUserInput, setDataUserInput] = useState({});
  const [calculatedValue, setCalculatedValue] = useState(0);

  const indicatorData = store.indicatorsReducer.indicatorData
    ? store.indicatorsReducer.indicatorData
    : {};

  useEffect(() => {
    if (idIndicator === null) {
      handleBackButton();
    }
    let dataCalc = (dataUserInput.numerator / dataUserInput.denominator) * 100;
    let dataRounded = Math.round(dataCalc, 2);
    setCalculatedValue(isNaN(dataRounded) ? 0 : dataRounded);
    dispatch(getAnIndicator(idIndicator));
  }, [dataUserInput.numerator, dataUserInput.denominator]);

  const handleBackButton = () => {
    history.push("/indicadores/listadoMaestro");
  };
  /* ---------------------------- Feed Indicator ---------------------------- */
  const feed = async () => {
    try {
      // if (
      //   isEmptyOrUndefined(convertedDate)
      //   // isEmptyOrUndefined(dataUserInput.numerator) ||
      //   // isEmptyOrUndefined(dataUserInput.denominator) ||
      //   // isEmptyOrUndefined(calculatedValue) ||
      //   // isEmptyOrUndefined(dataUserInput.notes)
      // ) {
      //   return message(
      //     "warning",
      //     "Advertencia",
      //     "Por favor llene todos los campos  obligatorios"
      //   );
      // }
      const feedInd = await feedIndicator(
        idIndicator,
        convertedDate,
        dataUserInput.numerator,
        dataUserInput.denominator,
        calculatedValue,
        dataUserInput.notes,
        token
      );
      if (feedInd) {
        if (!isEmptyOrUndefined(feedInd.id)) {
          handleBackButton();
          return message(
            "success",
            "Éxito",
            "Indicador alimentado exitosamente"
          );
        } else {
          return message("error", "Error", "No se pudo alimentar el indicador");
        }
      }
    } catch (error) {
      return message("error", "Error", error);
    }
  };

  /* -------------------------- Handle change section ------------------------- */
  const handleChangeCalendar = (e) => {
    let date = convertDateToLatinFormat(e);
    setConvertedDate(date);
    setDateCalendar(e);
  };

  const handleChangeInput = (e) => {
    let { value, name } = e.target;
    setDataUserInput({ ...dataUserInput, [name]: value });
  };

  return (
    <>
      <div className={"w-100"}>
        <div className={`${tableStyles.ItemSmall} mt-5`}>
          <Row
            className="d-flex justify-content-space-between "
            style={{ width: "100%" }}
          >
            <Col xs={12} className="ml-5 d-flex" style={{ width: "80%" }}>
              <button onClick={() => handleBackButton()}>
                <img src={leftArrow} alt="back"></img>
              </button>
              <h2 className="text-dark mb-3" style={{ marginLeft: "4%" }}>
                Alimentar Indicador
              </h2>
            </Col>
          </Row>
          <Row
            className="d-flex justify-content-space-between "
            style={{ width: "88%", marginLeft: "7%" }}
          >
            <Col xs={4}>
              <Card className={`${tableStyles.boxLessShadow}`}>
                <Table responsive style={{ margin: "0" }}>
                  <thead>
                    <tr>
                      <th
                        className={`${tableStyles.clearBlueText}`}
                        style={{ border: "inherit" }}
                      >
                        Información básica
                      </th>
                      <th style={{ border: "inherit" }}></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Nombre:</td>
                      <td>{indicatorData.name}</td>
                    </tr>
                    <tr>
                      <td>Tipo:</td>
                      <td>{indicatorData.type}</td>
                    </tr>
                    <tr>
                      <td>Sede:</td>
                      <td>{indicatorData.branchOffice}</td>
                    </tr>
                    <tr>
                      <td>Proceso:</td>
                      <td>{indicatorData.process}</td>
                    </tr>
                    <tr>
                      <td>Unidad de medición:</td>
                      <td>{indicatorData.measurementUnit}</td>
                    </tr>
                    <tr>
                      <td>Responsable:</td>
                      <td>{indicatorData.accountable}</td>
                    </tr>
                    <tr>
                      <td>ResponsableAlimentador:</td>
                      <td>{indicatorData.feeder}</td>
                    </tr>
                    <tr>
                      <td>Clase de indicador:</td>
                      <td>{indicatorData.kind}</td>
                    </tr>
                    <tr>
                      <td>Descripción:</td>
                      <td>{indicatorData.summary}</td>
                    </tr>
                    <tr>
                      <td>Fecha de creación:</td>
                      <td>{indicatorData.creationDate}</td>
                    </tr>
                    <tr>
                      <td>Creado por:</td>
                      <td>{indicatorData.creator}</td>
                    </tr>
                    <tr>
                      <td>Última modificación:</td>
                      <td>{indicatorData.lastUpdate}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>

            <Col xs={4}>
              <Card className={`${tableStyles.boxLessShadow}`}>
                <Table responsive style={{ margin: "0" }}>
                  <thead>
                    <tr>
                      <th
                        className={`${tableStyles.clearBlueText}`}
                        style={{ border: "inherit" }}
                      >
                        Información básica
                      </th>
                      <th style={{ border: "inherit" }}></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Frecuencia de medición:</td>
                      <td>{indicatorData.measurementFrequency}</td>
                    </tr>
                    <tr>
                      <td>Usa numerador y denominador:</td>
                      <td>Si</td>
                    </tr>
                    <tr>
                      <td>Sede:</td>
                      <td>COFCA</td>
                    </tr>
                    <tr>
                      <td>Descripción del numerador:</td>
                      <td>{indicatorData.numerator}</td>
                    </tr>
                    <tr>
                      <td>Descripción del denominador:</td>
                      <td>{indicatorData.denominator}</td>
                    </tr>
                    <tr>
                      <td>Es de tendencia positiva:</td>
                      <td>{indicatorData.trend == 1 ? "Si" : "No"}</td>
                    </tr>
                    <tr>
                      <td>Es de meta variable:</td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <td>Clase de indicador:</td>
                      <td>Productividad</td>
                    </tr>
                    <tr>
                      <td>Meta mínima:</td>
                      <td>{indicatorData.minGoal}</td>
                    </tr>
                    <tr>
                      <td>Meta Nominal</td>
                      <td>{indicatorData.nomGoal}</td>
                    </tr>
                    <tr>
                      <td>Meta Máxima</td>
                      <td>{indicatorData.maxGoal}</td>
                    </tr>
                    <tr>
                      <td>Fórmula</td>
                      <td>[NUM]/[DEN]*100</td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>

            <Col xs={4}>
              <div className={`${tableStyles.fourBorderRadiuses}`}>
                <p className={`${tableStyles.clearBlueText} text-start`}>
                  <b>Fecha de carga de valores</b>
                </p>
                <Calendar
                  className={`${tableStyles.calendarRadius} boxShadow w-100`}
                  locale={"Es"}
                  onChange={(e) => handleChangeCalendar(e)}
                  value={dateCalendar}
                />
              </div>
              <Row>
                <Col xs={6}>
                  <p className={`${tableStyles.crudModalLabel} text-dark `}>
                    Valor del numerador
                  </p>
                  <input
                    className="register-inputs  text-muted"
                    name="numerator"
                    onChange={(e) => handleChangeInput(e)}
                    type="number"
                  />
                </Col>
                <Col xs={6}>
                  <p className={`${tableStyles.crudModalLabel} text-dark `}>
                    Valor del denominador
                  </p>
                  <input
                    className="register-inputs  text-muted"
                    name="denominator"
                    onChange={(e) => handleChangeInput(e)}
                    type="number"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <p className={`${tableStyles.crudModalLabel}`}>Fórmula</p>
                  <input
                    className="register-inputs  text-muted"
                    type="text"
                    value="[NUM]/[DEM]*100"
                    readoOnly={true}
                  />
                </Col>
                <Col xs={6}>
                  <p className={`${tableStyles.crudModalLabel} `}>
                    Valor calculado
                  </p>
                  <input
                    className="register-inputs  text-muted"
                    name="x"
                    type="text"
                    value={calculatedValue}
                    readoOnly={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Notas
                  </p>
                  <textarea
                    name="notes"
                    style={{ height: "3rem" }}
                    rows="5"
                    cols="40"
                    onChange={(e) => handleChangeInput(e)}
                    className="register-inputs"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className={`customTabs__Footer mt-5`} style={{ width: "90%" }}>
          <Button onClick={() => feed()} className={tableStyles.btnPrimary}>
            Alimentar indicador
          </Button>
          <Button
            onClick={() => handleBackButton()}
            className={tableStyles.btnSecondary}
          >
            Cancelar
          </Button>
        </div>{" "}
      </div>
    </>
  );
};
