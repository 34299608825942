//Login's reducer

import { SAVE_POLL_LIST, LOADING_SAVE_POLL_LIST, SAVE_POLL_TYPES } from "../actions/actionTypes";

const initialState = {
  polls: [],
  pollsLoading: false,
  getPollKind:[]
};
export const pollsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_POLL_LIST:
      return {
        ...state,
        polls: action.payload,
        pollsLoading: action.loading,
      };

    case LOADING_SAVE_POLL_LIST:
      return {
        ...state,
        pollsLoading: action.loading,
      };

      case SAVE_POLL_TYPES:
        return {
          ...state,
          getPollKind: action.payload,
        };

    default:
      return state;
  }
};
