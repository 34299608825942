import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Header from "../Header/HeaderLogin";
import { Button, Card, Col, Row } from "react-bootstrap";
import UserIcon from "../../assets/img/icons/user.svg";
import LockPassword from "../../assets/img/icons/lock.svg";
import "./login-style.scss";
import "../../index.scss";
import { Link, matchPath } from "react-router-dom";
import imageLogin from "../../assets/img/icons/image_login.svg";
import {
  DoLogin,
  getAllDocs,
  pruebaFunction,
  validateUser,
} from "../../actions/loginActions";
import { useHistory } from "react-router-dom";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const reducer = useSelector((state) => state);
  const isAuth = reducer.loginReducer.Authenticated;

  useEffect(() => {
    if (isAuth) {
      history.push("/inicio");
    } else {
      history.push("/login");
    }
  }, [isAuth]);

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const onChange = (e) => {
    let name = e.target.name;
    const value = e.target.value;
    if (name === "username") setUserName(value);
    if (name === "password") setPassword(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      dispatch(validateUser(username, password));
    } catch (error) {
      console.log("Fail: ", error);
    }
  };

  return (
    <>
      <Row>
        <Card className="centeredContainer col-4" 
          style={{ 
            "border-radius":"15px",
            "border-color": "transparent", 
            "margin-top":"130px",
            "padding":"16px",
            "box-shadow": "0px 4px 30px rgba(55, 61, 80, 0.1)"   
          }}>
          <div>
            <Col xs={12}>
              <img
                src={imageLogin}
                alt="Imagen Login"
                style={{ width: "100%", height: "269px" }}
              />
            </Col>

            <Col xs={12} className="container-form-login" style={{ margin: "0 auto" }}>
              <form id="formLogin" onSubmit={handleSubmit}>
                <div
                  style={{ display: "table-caption" }}
                  className="w-75 mx-auto mt-2 "
                ></div>
                <div className="form-group-login">
                  <div
                    style={{ "background-color": "#FFFF" }}
                    className="input-group mb-3 input-blue-border mt-3 w-75 mx-auto"
                  >
                    <img
                      src={UserIcon}
                      alt="User icon"
                      className="input-user-icon input-group-prepend"
                    />
                    <input
                      type="email"
                      onChange={onChange}
                      placeholder="USUARIO"
                      name="username"
                      className="p-3 border-0 input-text-login  w-75"
                      style={{
                        "background-color": "#FFFF",
                        "outline-style": "none",
                      }}
                      aria-describedby="basic-addon1"
                      aria-label="Usuario"
                    />
                  </div>
                  <div
                    style={{ "background-color": "#FFFF" }}
                    className="input-group mb-3 input-blue-border mt-3 w-75 mx-auto"
                  >
                    <img
                      src={LockPassword}
                      alt="Lock icon"
                      className="input-user-icon input-group-prepend"
                    />
                    <input
                      type="password"
                      placeholder="CONTRASEÑA"
                      name="password"
                      onChange={onChange}
                      className=" p-3 border-0 input-text-login  w-75"
                      style={{
                        "background-color": "#FFFF",
                        "outline-style": "none",
                      }}
                    />
                  </div>
                </div>
                <Button
                  variant="primary"
                  type="submit"
                  className="text-uppercase mt-3 w-75 p-3 border-radius:7px button"
                  disabled={!password || !username}
                >
                  acceder
                </Button>
                <div
                  style={{
                    display: "flex",
                    "justify-content": "space-between",
                  }}
                  className="w-75 mx-auto bg-transparent"
                >
                  <div className="">
                    <div className="textAbove">
                      <input
                        classname="myCheckbox"
                        style={{
                          "margin-right": "4px",
                          width: "20px",
                          " height": "20px",
                          "border-radius": "4px",
                          color: "#005DBF",
                        }}
                        type="checkbox"
                      ></input>
                    </div>
                    <label
                      style={{
                        color: "#005DBF",
                        "text-decoration": "None",
                        "font-style": "normal",
                        "font-size": "13px",
                        "line-height": "15px",
                        letterSpacing: "0.06px",
                      }}
                    >
                      Recordarme
                    </label>
                  </div>
                  <div className="">
                    <label>
                      <Link
                        to="/"
                        style={{
                          color: "#005DBF",
                          "text-decoration": "None",
                          "vertical-align": "-webkit-baseline-middle",
                          "font-style": "normal",
                          "font-size": "13px",
                          "line-height": "15,23px",
                          letterSpacing: "0.06px",
                        }}
                      >
                        Olvidó su contraseña?
                      </Link>
                    </label>
                  </div>
                </div>
              </form>
            </Col>
          </div>
        </Card>
      </Row>
    </>
  );
};
export default Login;
