import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Export from "../../../assets/img/icons/exportar.svg";
import Refresh from "../../../assets/img/icons/Refresh.svg";
import lupa from "../../../assets/img/icons/lupa.svg";
import fals from "../../../assets/img/icons/False.svg";
import Pagination from "react-js-pagination";
import GenericTable from "../../Layouts/GenericTable";
import "../../TableUsers/tableUserStyle.scss";
import tableStyles from "../../Layouts/tableStyle.module.scss";
import paginationStyles from "../../Layouts/pagination.module.scss";
import {
  getAllDocumentType,
  getAllObsoleteDocuments,
} from "../../../actions/documentActions";

function ObsoleteTable(props) {
  const store = useSelector((state) => state); //entra aca para acceder a todos los reducers
  const arrInProcessDocs = store.documentReducer.obsoleteDocuments;
  const arrFilter = store.documentReducer.documentTypes;
  const dispatch = useDispatch(); //usa este metodo para ejecutar un action
  let i = 0;

  const [filters, setFilter] = useState({
    search: "",
    page: 1,
    perpage: 10,
  }); //valores de entrada de la funcion en el backend

  const [contador, setContador] = useState(0); //esto es para recargar la pagina
  useEffect(
    () => {
      dispatch(getAllObsoleteDocuments());
      dispatch(getAllDocumentType());
    },
    [contador, filters.page] //cada vez que esto cambie, ejecutar la funcion de arriba.
  );

  const handlePageChange = (val) => {
    setFilter({
      ...filters, // División de objetos
      page: val,
    });
  };

  const handleSearchInput = (val) => {
    setFilter({
      ...filters, //el resto no los quiero cambiar
      search: val, //solo search
    });
  };

  const renderElement = (elem) => {
    return (
      <tr key={elem.code}>
        <td className="col-md-2 text-center">{elem.id}</td>
        <td className="col-md-8 text-center text-primary">
          {" "}
          <u>
            <a href="#">{elem.type}</a>
          </u>
        </td>
        <td className="col-md-2 text-center">{elem.name}</td>
        <td className="col-md-2 text-center">{elem.code}</td>
        <td className="col-md-2 text-center">{elem.version}</td>
        <td className="col-md-3 text-center">{elem.creation}</td>
        <td className="col-md-3 text-center">{elem.validity}</td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (arrInProcessDocs !== undefined) {
      const elem2 = arrInProcessDocs;
      elemMap = elem2.map((elem) => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };
  const renderHeaders = [
    <th style={{ borderTopLeftRadius: "10px" }} key={i}>
      ID
    </th>,
    <th key={i + 1}>Tipo</th>,
    <th key={i + 1}>Nombre</th>,
    <th key={i + 1}>Código</th>,
    <th key={i + 1}>Versión</th>,
    <th key={i + 1}>Creación</th>,
    <th style={{ borderTopRightRadius: "10px" }} key={i + 1}>
      Expirado
    </th>,
  ];

  return (
    <div className={tableStyles.container}>
      <h1
        className={`${tableStyles.clearBlueText} ${tableStyles.marginLeftsix}`}
      >
        Documentos obsoletos &nbsp;
        <a>
          <img src={fals}></img>
        </a>
      </h1>

      <div className={tableStyles.tableArea}>
        <div className={tableStyles.mainRow}>
          <div className="d-flex justify-content-space-between filterDiv-ListView">
            <div>
              <p className={tableStyles.crudModalLabel}>Tipo</p>
              <select placeholder={"Seleccione"} className="select-register">
                {arrFilter
                  ? arrFilter.length > 0 &&
                    arrFilter.map((e) => {
                      return <option value={e.id}> {e.name} </option>;
                    })
                  : ""}
              </select>
            </div>
            <div className="mt-4">
              <input
                className={tableStyles.searchUsersAlt}
                type="text"
                placeholder="Buscar"
                onChange={(e) => handleSearchInput(e.target.value)}
              />

              <button onClick={() => setContador(contador + 1)}>
                <img
                  src={lupa}
                  alt="User icon"
                  className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                />
              </button>
            </div>
          </div>
        </div>

        <GenericTable
          theadTheme={`${tableStyles.theadClearBlue}`}
          headers={renderHeaders}
        >
          {listElem()}
        </GenericTable>

        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {store.cupsReducer.total ? filters.page : ""}
            {" de "}
            {Math.ceil(store.cupsReducer.total / filters.perpage)
              ? Math.ceil(store.cupsReducer.total / filters.perpage)
              : ""}{" "}
            ({store.cupsReducer.total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={10}
            totalItemsCount={store.cupsReducer.total}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>
    </div>
  );
}
export default ObsoleteTable;
