import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Select from "react-select";
import tableStyles from "../Layouts/tableStyle.module.scss";
import minus from "../../assets/img/icons/minus.svg";
import Add from "../../assets/img/icons/Add.svg";
import blueBall from "../../assets/img/icons/blueBall.svg";
import leftArrow from "../../assets/img/icons/leftArrow.svg";
import { useSelector } from "react-redux";
import { getAllUsers } from "../../actions/documentActions";
import { isEmptyOrUndefined, message } from "../../helpers/helpers";
import { customSelectNew } from "../Layouts/react-select-custom";
import deepcopy from "deepcopy";
import {
  createNewActionPlan,
  getAllReasons,
  getAllSources,
} from "../../actions/actionPlanAction";
import { getAllMeasurementFrequency } from "../../actions/businessUnits";
import { AccordionForm } from "./AccordionForm";
import { useHistory } from "react-router-dom";
import { createNewMinute } from "../../actions/comitesAction";
export const ComitesMinuteForm = (props) => {
  console.log("🚀 ~ file: ComitesMinuteForm ~ props", props);
  const store = useSelector((state) => state);
  const arrBusinessList = store.businessUnitsReducer.businessUnits;
  const token = store.loginReducer.Authorization;
  const [arrUsers, setArrUsers] = useState([]);
  const [businessList, setBusinessList] = useState([]);

  const [arrSources, setArrSources] = useState([]);

  const [mesurementFrequency, setMesurementFrequency] = useState([]);

  const [arrReasons, setArrReasons] = useState([]);

  const [dataUserInput, setDataUserInput] = useState({});
  const [addNumberRender, setAddNumberRender] = useState([
    { activity: "", accountable: "" },
    { activity: "", accountable: "" },
    { activity: "", accountable: "" },
  ]);
  const [general, setGeneral] = useState(true);
  const history = useHistory();
  const [topics, setTopics] = useState([]);
  /* -------------------------------------------------------------------------- */
  /*                           GET DOCUMENT TEMPLATES                           */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    cleanFilters();
    getUsers();
    getSources();
    getMeasurementFrequency();
    getReasons();
  }, []);

  let dataComite = props.dataComite.length > 0 && props.dataComite[0];
  let dataInvited = props.dataComite.length > 0 && props.dataComite[0].invitees;
  let dataTopics = props.dataComite.length > 0 && props.dataComite[0].topics;
  const { id } = dataComite;
  const getSources = async () => {
    const result = await getAllSources(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrSources = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrSources.push({ value: e.id, label: e.name });
      });
      setArrSources(arrSources);
    }
  };
  const getUsers = async () => {
    const result = await getAllUsers(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrUs = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrUs.push({ value: e.id, label: e.fullName });
      });
      setArrUsers(arrUs);
    }
  };

  const cleanFilters = () => {
    let arrBusiness = [];
    if (Array.isArray(arrBusinessList) && arrBusinessList.length > 0) {
      arrBusinessList.forEach((e) => {
        arrBusiness.push({ value: e.id, label: e.name });
      });
      setBusinessList(arrBusiness);
    }
  };
  const getMeasurementFrequency = async () => {
    let result = await getAllMeasurementFrequency(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrMeasurementFrequency = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrMeasurementFrequency.push({ value: e.id, label: e.name });
      });
      setMesurementFrequency(arrMeasurementFrequency);
    }
  };
  const getReasons = async () => {
    let result = await getAllReasons(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrReasons = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrReasons.push({ value: e.id, label: e.name });
      });
      setArrReasons(arrReasons);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                            Helper JSX Functions                            */
  /* -------------------------------------------------------------------------- */

  const addActivity = (e, i) => {
    const activity = {
      activity: "",
      accountable: "",
    };
    setAddNumberRender([...addNumberRender, activity]);
  };

  const removeActivity = (e, i) => {
    //console.log("Index a elimnar", i);
    console.log("Original ", addNumberRender);
    let copy = deepcopy(addNumberRender);
    console.log("Copia ", copy);
    let index = copy.indexOf(i);
    console.log("INDEX ", i);
    copy.splice(1, 1);
    console.log("Copia despues de borrar ", copy);
    setAddNumberRender(copy);
  };
  /* --------------------------------------------------------------------------- */
  /*                              CREATE MINUTE                                 */
  /* -------------------------------------------------------------------------- */
  const submitNewMinute = async () => {
    // if (isEmptyOrUndefined(dataUserInput.subject)) {
    //   return message(
    //     "warning",
    //     "Advertencia",
    //     "Por favor llene todos los campos  obligatorios"
    //   );
    // }
    try {
      const saveMinute = await createNewMinute(
        id,
        dataUserInput.intro,
        dataUserInput.subject,
        dataUserInput.location,
        dataUserInput.goal,
        dataUserInput.date,
        dataUserInput.time,
        dataUserInput.estimatedDuration,
        topics,
        token
      );
      if (!isEmptyOrUndefined(saveMinute)) {
        message("success", "Éxito", "Acta creada de manera exitosa");
        props.hideForm();
      }
    } catch (error) {
      return message("error", "Error", error.message);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                           HANDLE CHANGES SECTION                           */
  /* -------------------------------------------------------------------------- */
  const handleChangeInput = (e) => {
    let { value, name } = e.target;
    setDataUserInput({ ...dataUserInput, [name]: value });
  };

  const handleChangeActivity = (e, i) => {
    const { name, value } = e.target;
    console.log("name=>", name, value, i);
    let copy = deepcopy(addNumberRender);
    copy[i][name] = value;
    setAddNumberRender(copy);
  };

  const handleClickGeneral = () => {
    setGeneral(!general);
  };
  const handleClickTopics = () => {
    setGeneral(!general);
  };

  const handleChangeTopics = (e) => {
    let { value, name } = e.target;
    setTopics({ ...topics, [name]: value });
  };
  // console.log("User=> ", dataUserInput);
  // console.log("Topics=> ", topics);
  return (
    <>
      <div className={"w-100"}>
        <div className={`${tableStyles.ItemSmall} mt-5`}>
          <Row
            className="d-flex justify-content-space-between "
            style={{ width: "100%" }}
          >
            <Col xs={12} className="d-flex" style={{ width: "80%" }}>
              <button onClick={props.hideForm}>
                <img src={leftArrow} alt="back"></img>{" "}
              </button>
              <h2 className="text-dark mb-3" style={{ marginLeft: "4%" }}>
                <b>{dataComite.name}</b>
              </h2>
            </Col>
            <Col xs={8} className="pl-4 ml-5 d-flex" style={{ width: "80%" }}>
              <h3 className="text-muted ">Acta No. 6</h3>
            </Col>
          </Row>
        </div>
        <div
          className={`${tableStyles.fourBorderRadiuses} ${tableStyles.boxShadow}`}
        >
          <div className={`${tableStyles.container1} mt-2`}>
            <div
              className={`${tableStyles.bgColorClearBlue} d -flex `}
              style={{ width: "100%" }}
            >
              <Col xs={12}>
                <button
                  className={`btn btn_tabs ${
                    general && tableStyles.slideEffectbtn
                  }`}
                  style={{ marginLeft: "15px" }}
                  onClick={handleClickGeneral}
                >
                  General
                </button>

                <button
                  onClick={handleClickTopics}
                  className={`btn btn_tabs ${
                    !general && tableStyles.slideEffectbtn
                  }`}
                >
                  Temas
                </button>
              </Col>
            </div>
            <div className={`d -flex `} style={{ align: "center" }}>
              <Col xs={12} className={``}>
                <h5
                  className={`${tableStyles.clearBlueText}  div-document-title-header fontBold`}
                  style={{ textAlign: "center" }}
                >
                  {general
                    ? "Detalles de la reunion"
                    : "Desarrollo de la reunion"}
                </h5>
              </Col>
            </div>
            {general ? (
              <>
                <Card
                  className={`${tableStyles.fourBorderRadiuses} ${tableStyles.container} ${tableStyles.bgColorClearBlue} pb-4`}
                  style={{ borderWidth: 0 }}
                >
                  <h6 className={`${tableStyles.clearBlueText} mt-2`}>
                    ● Definición
                  </h6>
                  {/* ---------------------------- ROW 1 ---------------------------------- */}
                  <Row className="d-flex" style={{ width: "100%" }}>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Tema principal
                        <span className={"text-danger"}>*</span>
                      </p>
                      <input
                        // value={dataComite.name}
                        // disabled={true}
                        name="subject"
                        value={dataUserInput.subject}
                        className="register-inputs"
                        onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Lugar
                        <span className={"text-danger"}>*</span>
                      </p>
                      <input
                        // value={"Sala de reuniones"}
                        // disabled={true}
                        name="location"
                        className="register-inputs"
                        value={dataUserInput.location}
                        onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                  </Row>

                  {/* ---------------------------- ROW 2 ---------------------------------- */}
                  <Row className="d-flex" style={{ width: "100%" }}>
                    <Col xs={4}>
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Objetivo
                        <span className={"text-danger"}>*</span>
                      </p>
                      <input
                        // value={dataComite.goal}
                        // disabled={true}
                        value={dataUserInput.goal}
                        name="goal"
                        className="register-inputs"
                        onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Fecha
                        <span className={"text-danger"}>*</span>
                      </p>
                      <input
                        // value={dataComite.firstMeetDate}
                        // disabled={true}
                        value={dataUserInput.date}
                        type="date"
                        name="date"
                        className="register-inputs"
                        onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Hora
                        <span className={"text-danger"}>*</span>
                      </p>
                      <input
                        value={dataUserInput.time}
                        // value={dataComite.firstMeetTime}
                        // disabled={true}
                        name="time"
                        type="time"
                        className="register-inputs"
                        onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                    <Col xs={2}>
                      <p className={`${tableStyles.crudModalLabel} text-dark`}>
                        Duración estimada
                        <span className={"text-danger"}>*</span>
                      </p>
                      <input
                        // value={"Dos horas"}
                        // disabled={true}
                        value={dataUserInput.estimatedDuration}
                        name="estimatedDuration"
                        className="register-inputs"
                        onChange={(e) => handleChangeInput(e)}
                      />
                    </Col>
                  </Row>
                </Card>
                {/* /* -------------------------------- PICTURES SECTION------------------------------- */}
                <h6
                  className={`${tableStyles.clearBlueText} mt-2 `}
                  style={{ marginLeft: "1%" }}
                >
                  ●Asistentes
                </h6>
                {/* ---------------------------- ROW 1 ---------------------------------- */}
                <Row className="d-flex" style={{ width: "100%" }}>
                  {/* {dataInvited.map((e, i) => {
                    return (
                      <Col xs={3}>
                        <Card className="mt-2 ml-3" style={{ height: "3rem" }}>
                          <div className="d-flex">
                            <div>
                              <img
                                className="mt-1"
                                width="40"
                                height="40"
                                src={`https://lledogrupo.com/wp-content/uploads/2018/04/user-img-1.png`}
                                style={{ borderRadius: "100%" }}
                              ></img>
                            </div>
                            <div className={`${tableStyles.fZSmall} mt-2`}>
                              <b>Chaira Webster Cabarcas</b>
                              <p>Asistente de calidad</p>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    );
                  })} */}

                  <Col xs={3}>
                    <Card className="mt-2 ml-3" style={{ height: "3rem" }}>
                      <div className="d-flex">
                        <div>
                          <img
                            className="mt-1"
                            width="40"
                            height="40"
                            src={`https://lledogrupo.com/wp-content/uploads/2018/04/user-img-1.png`}
                            style={{ borderRadius: "100%" }}
                          ></img>
                        </div>
                        <div className={`${tableStyles.fZSmall} mt-2`}>
                          <b>Chaira Webster Cabarcas</b>
                          <p>Asistente de calidad</p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xs={3}>
                    <Card className="mt-2" style={{ height: "3rem" }}>
                      <div className="d-flex">
                        <div>
                          <img
                            className="mt-1"
                            width="40"
                            height="40"
                            src={`https://www.nicepng.com/png/detail/128-1280036_jpg-free-stock-female-vector-user-user-female.png`}
                            style={{ borderRadius: "100%" }}
                          ></img>
                        </div>
                        <div className={`${tableStyles.fZSmall}`}>
                          <b>Chaira Webster Cabarcas</b>
                          <p>Asistente de calidad</p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xs={3}>
                    <Card className="mt-2" style={{ height: "3rem" }}>
                      <div className="d-flex">
                        <div>
                          <img
                            className="mt-1"
                            width="40"
                            height="40"
                            src={`https://lledogrupo.com/wp-content/uploads/2018/04/user-img-1.png`}
                            style={{ borderRadius: "100%" }}
                          ></img>
                        </div>
                        <div className={`${tableStyles.fZSmall} mt-2`}>
                          <b>Chaira Webster Cabarcas</b>
                          <p>Asistente de calidad</p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xs={3}>
                    <Card className="mt-2" style={{ height: "3rem" }}>
                      <div className="d-flex">
                        <div>
                          <img
                            className="mt-1"
                            width="40"
                            height="40"
                            src={`https://www.nicepng.com/png/detail/128-1280036_jpg-free-stock-female-vector-user-user-female.png`}
                            style={{ borderRadius: "100%" }}
                          ></img>
                        </div>
                        <div className={`${tableStyles.fZSmall} mt-2`}>
                          <b>Chaira Webster Cabarcas</b>
                          <p>Asistente de calidad</p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
                {/* ---------------------------- ROW 2 ---------------------------------- */}
                <Row className="d-flex" style={{ width: "100%" }}>
                  <Col xs={3}>
                    <Card className="mt-2 ml-3" style={{ height: "3rem" }}>
                      <div className="d-flex">
                        <div>
                          <img
                            className="mt-1"
                            width="40"
                            height="40"
                            src={`https://lledogrupo.com/wp-content/uploads/2018/04/user-img-1.png`}
                            style={{ borderRadius: "100%" }}
                          ></img>
                        </div>
                        <div className={`${tableStyles.fZSmall} mt-2`}>
                          <b>Chaira Webster Cabarcas</b>
                          <p>Asistente de calidad</p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xs={3}>
                    <Card className="mt-2" style={{ height: "3rem" }}>
                      <div className="d-flex">
                        <div>
                          <img
                            className="mt-1"
                            width="40"
                            height="40"
                            src={`https://www.nicepng.com/png/detail/128-1280036_jpg-free-stock-female-vector-user-user-female.png`}
                            style={{ borderRadius: "100%" }}
                          ></img>
                        </div>
                        <div className={`${tableStyles.fZSmall}`}>
                          <b>Chaira Webster Cabarcas</b>
                          <p>Asistente de calidad</p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xs={3}>
                    <Card className="mt-2" style={{ height: "3rem" }}>
                      <div className="d-flex">
                        <div>
                          <img
                            className="mt-1"
                            width="40"
                            height="40"
                            src={`https://lledogrupo.com/wp-content/uploads/2018/04/user-img-1.png`}
                            style={{ borderRadius: "100%" }}
                          ></img>
                        </div>
                        <div className={`${tableStyles.fZSmall} mt-2`}>
                          <b>Chaira Webster Cabarcas</b>
                          <p>Asistente de calidad</p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xs={3}>
                    <Card className="mt-2" style={{ height: "3rem" }}>
                      <div className="d-flex">
                        <div>
                          <img
                            className="mt-1"
                            width="40"
                            height="40"
                            src={`https://www.nicepng.com/png/detail/128-1280036_jpg-free-stock-female-vector-user-user-female.png`}
                            style={{ borderRadius: "100%" }}
                          ></img>
                        </div>
                        <div className={`${tableStyles.fZSmall} mt-2`}>
                          <b>Chaira Webster Cabarcas</b>
                          <p>Asistente de calidad</p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>

                <div style={{ paddingLeft: "15px" }}>
                  {/* /* ---------------------------------- ROW 3 --------------------------------- */}
                  <Row className="d-flex" style={{ width: "100%" }}>
                    <Col xs={6}>
                      <button onClick={addActivity}>
                        <div className="d-flex">
                          <img src={Add}></img>
                          <p className={`${tableStyles.crudModalLabel} `}>
                            Agregar invitado
                          </p>
                        </div>
                      </button>
                    </Col>
                  </Row>
                </div>
                <h6
                  className={`${tableStyles.clearBlueText} mt-2 `}
                  style={{ marginLeft: "1%" }}
                >
                  ●Agenda de la reunión
                </h6>
                {/* ---------------------------- ROW 4 CONDITIONAL -------------------------------- */}
                {addNumberRender.length > 0 &&
                  addNumberRender.map((e, i) => {
                    return (
                      <Row
                        key={i}
                        className="d-flex mb-3 "
                        style={{ width: "100%", marginLeft: "1.4%" }}
                      >
                        <Col xs={1} style={{ width: "1.333333%" }}>
                          <button
                            // onClick={i === 0 ? null : (e) => removeActivity(e, i)}
                            onClick={(e) => removeActivity(e, i)}
                          >
                            <img
                              // src={i === 0 ? blueBall : minus}
                              src={minus}
                              style={{ margin: "0 auto", width: "16px" }}
                            ></img>
                          </button>
                        </Col>
                        <Col xs={3}>
                          <div>
                            <input
                              name="activity"
                              onChange={(e) => handleChangeActivity(e, i)}
                              className="register-inputs"
                              type="text"
                            ></input>
                          </div>
                        </Col>
                        <Col xs={6}>
                          <input
                            placeHolder="Descripción"
                            name="activity"
                            onChange={(e) => handleChangeActivity(e, i)}
                            className="register-inputs"
                            type="text"
                          ></input>
                        </Col>
                      </Row>
                    );
                  })}
                {/* ---------------------------- ROW 5 ---------------------------------- */}
                <Row
                  className="d-flex"
                  style={{
                    width: "100%",
                    marginLeft: "1.4%",
                  }}
                >
                  <Col xs={6}>
                    <button onClick={addActivity}>
                      <div className="d-flex">
                        <img src={Add}></img>
                        <p className={`${tableStyles.crudModalLabel} `}>
                          Agregar invitado
                        </p>
                      </div>
                    </button>
                  </Col>
                </Row>
              </>
            ) : (
              /* --------------------------------- INICIO --------------------------------- */
              <AccordionForm
                onChangeInputs={(e) => handleChangeInput(e)}
                onChangeTopics={(e) => handleChangeTopics(e)}
                dataUserInput={dataUserInput}
                topics={topics}
                dataComite={dataComite}
              ></AccordionForm>
              /* ----------------------------------- FIN ---------------------------------- */
            )}
          </div>
        </div>

        <div className={`customTabs__Footer mt-5`} style={{ width: "90%" }}>
          <Button
            onClick={() => submitNewMinute()}
            className={tableStyles.btnPrimary}
            // disabled={general ? true : false}
          >
            Crear acta
          </Button>
          <Button
            onClick={props.hideForm}
            // onClick=
            className={tableStyles.btnSecondary}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </>
  );
};
