//Login's reducer

import {
  SAVE_UDITORIES_LIST,
  LOADING_SAVE_UDITORIES_LIST,
} from "../actions/actionTypes";

const initialState = {
  auditoriesLoding: false,
  auditories: [],
};
export const auditoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_UDITORIES_LIST:
      return {
        ...state,
        auditories: action.payload,
        auditoriesLoding: action.loading,
      };

    case LOADING_SAVE_UDITORIES_LIST:
      return {
        ...state,
        auditoriesLoding: action.loading,
      };

    default:
      return state;
  }
};
