import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import logoFocaHorizontal from "../../assets/img/header/foca-logo-horizontal.png";
import logotTTNNew from "../../assets/img/header/Logottn1.png";
import userLogo from "../../assets/img/header/user-img.png";
import PhoneIcon from "../../assets/img/icons/telefono.svg";
import EditIcon from "../../assets/img/icons/editar.svg";
import OpenLockIcon from "../../assets/img/icons/candado.svg";
import mailIcon from "../../assets/img/icons/sobre.svg";
import { logOutUser } from "../../actions/loginActions";
import { showAccountInfo } from "../../actions/headerActions";
import "./header-style.scss";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editProfile: false,
      editPhone: true,
      editPassword: false,
      user: {},
    };
  }

  componentDidMount() {
    this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.userData !== prevProps.userData) {
      this.getInitialState();
    }
  }

  getInitialState = () => {
    var userData = this.props.userData;
    if (userData) {
      this.setState({
        editProfile: false,
        editPhone: true,
        editPassword: false,
        showInputNewPassword: false,
        showButtons: false,
        user: {
          id: userData.id,
          first_name: userData.first_name,
          first_surname: userData.first_surname,
          second_surname: userData.second_surname,
          email: userData.email,
          cellphone: userData.cellphone,
          password: "",
          newPassword: "",
          newPasswordConfirm: "",
        },
      });
    }
  };
  onClickPopUp = () => {
    let showAccount = !this.props.showAccount;
    this.props.showAccountInfo(showAccount);
  };
  onClickLogOut = () => {
    this.props.logOutUser();
  };
  handleToggleProfile = () => {
    this.setState({
      editProfile: !this.state.editProfile,
      showButtons: !this.state.showButtons,
    });
  };
  handleTogglePassword = () => {
    this.setState({
      editProfile: false,
      editPassword: !this.state.editPassword,
      showInputNewPassword: !this.state.showInputNewPassword,
      showButtons: !this.state.showButtons,
    });
  };
  toggleDisabledInput = (e) => {
    const name = e.target.name;
    let { editPhone, editPassword, showInputNewPassword } = this.state;
    switch (name) {
      case "editPhone":
        editPhone = !this.state.editPhone;
        break;
      case "editPassword":
        editPassword = !this.state.editPassword;
        showInputNewPassword = !this.state.showInputNewPassword;
        break;
      default:
        break;
    }
    this.setState({ editPassword, editPhone, showInputNewPassword });
  };

  handleDisard = () => {
    document.getElementById("form-edit-profile").reset();
    this.getInitialState();
  };

  onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let { user } = this.state;
    user[name] = value;
    this.setState({ user });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.getInitialState();
    if (this.state.editPassword) {
      return this.props.updateUserInfo(this.state.user);
    } else {
      return this.props.updateCellphone(this.state.user);
    }
  };

  render() {
    return (
      <div className="header-container-inicio w-100">
        {window.location.pathname === "/login" ? (
          <span></span>
        ) : (
          <Link to="/inicio">
            <img
              className="foca-logo-header m-3"
              src={logotTTNNew}
              alt="logo foca header"
            />
          </Link>
        )}

        {this.props.isAuth && (
          <div className="right-side-icons">
            <img
              className="user-img-header hoverPointer m-5"
              src={userLogo}
              alt="imagen usuario header"
              onClick={this.onClickPopUp}
            />
            {this.props.showAccount && (
              <div className="popUp-user-profile">
                <h4 className="text-primary p-3">Mi cuenta</h4>
                <div className="d-flex justify-content-between">
                  <img
                    className="hoverPointer w-25 mx-3 my-3 h-25 pb-3"
                    src={userLogo}
                    alt="imagen usuario header"
                    onClick={this.onClickPopUp}
                  />
                  <div className="d-flex flex-column mx-auto">
                    <h5 className="text-primary fw-bold text-capitalize">
                      {this.state.user?.first_name}{" "}
                      {this.state.user?.first_surname}{" "}
                      {this.state.user?.second_surname}
                    </h5>

                    <h6 className="text-primary fw-bold text-capitalize">
                      Cuenta: {this.props.currentAccount?.name}
                    </h6>

                    {!this.state.editProfile && (
                      <span className="hoverPointer">
                        {this.state.user.email}
                      </span>
                    )}
                    {!this.state.editProfile && !this.state.editPassword && (
                      <span
                        className="hoverPointer"
                        onClick={this.handleToggleProfile}
                      >
                        Mi perfil
                      </span>
                    )}

                    {!this.state.editProfile &&
                      !this.state.editPassword &&
                      this.props.currentAccount && (
                        <Link className="link-text" to="/accountselect">
                          Cambiar Cuenta
                        </Link>
                      )}

                    {!this.state.editProfile && !this.state.editPassword && (
                      <span
                        className="hoverPointer"
                        onClick={this.handleTogglePassword}
                      >
                        Cambiar contraseña
                      </span>
                    )}
                    {!this.state.editProfile && !this.state.editPassword && (
                      <span
                        className="hoverPointer"
                        onClick={this.onClickLogOut}
                      >
                        Cerrar sesión
                      </span>
                    )}
                    {/* Profile info */}
                    {this.state.editProfile && (
                      <div className="d-flex flex-column mx-3 pb-5">
                        <div className="input-group input-blue-border mt-3 w-100 mx-auto bg-transparent">
                          <img
                            src={mailIcon}
                            alt="User icon"
                            className="input-group-prepend input-user-icon-edit-profile"
                          />
                          <input
                            type="email"
                            placeholder="USUARIO"
                            name="username"
                            className="form-control p-3 border-0 input-text-header"
                            value={this.state.user.email}
                            // onChange={this.onChange}
                            aria-describedby="basic-addon1"
                            aria-label="Usuario"
                            disabled
                          />
                        </div>
                        <div className="input-group input-blue-border mt-3 w-100 mx-auto bg-transparent container-input-header">
                          <img
                            src={PhoneIcon}
                            alt="User icon"
                            className="input-group-prepend input-user-icon-edit-profile"
                          />
                          <input
                            type="number"
                            placeholder="Teléfono"
                            name="cellphone"
                            className="form-control p-3 border-0 input-text-header input-edit-max-width"
                            value={this.state.user.cellphone}
                            onChange={this.onChange}
                            aria-describedby="basic-addon1"
                            aria-label="telefono"
                            disabled={this.state.editPhone ? "disabled" : ""}
                          />
                          <span
                            onClick={this.toggleDisabledInput}
                            className="hoverPointer border-edit-input"
                          >
                            <img
                              src={EditIcon}
                              alt="Edit icon"
                              name="editPhone"
                              className="input-group-prepend input-edit-icon-profile"
                            />
                          </span>
                        </div>
                      </div>
                    )}
                    {this.state.editPassword && (
                      <div>
                        <div className="d-flex input-group input-blue-border mt-3 w-100 mx-auto bg-transparent">
                          <img
                            src={OpenLockIcon}
                            alt="candado icon"
                            className="input-group-prepend input-user-icon-edit-profile"
                          />
                          <input
                            type="password"
                            placeholder="Contraseña actual"
                            name="password"
                            className="form-control p-3  border-0 input-text-header"
                            value={this.state.user.password}
                            onChange={this.onChange}
                            aria-describedby="basic-addon1"
                            aria-label="contraseña actual"
                          />
                        </div>

                        <div className="d-flex input-group input-blue-border mt-3 w-100 mx-auto bg-transparent">
                          <img
                            src={OpenLockIcon}
                            alt="candado icon"
                            className="input-group-prepend input-user-icon-edit-profile"
                          />
                          <input
                            type="password"
                            placeholder="Nueva contraseña"
                            name="newPassword"
                            className="form-control p-3  border-0 input-text-header"
                            value={this.state.user.newPassword}
                            onChange={this.onChange}
                            aria-describedby="basic-addon1"
                            aria-label="nueva contraseña"
                          />
                        </div>
                        <div className="d-flex input-group mb-5 input-blue-border mt-3 w-100 mx-auto bg-transparent">
                          <img
                            src={OpenLockIcon}
                            alt="candado icon"
                            className="input-group-prepend input-user-icon-edit-profile"
                          />
                          <input
                            type="password"
                            placeholder="Confirmar contraseña"
                            name="newPasswordConfirm"
                            className="form-control p-3  border-0 input-text-header"
                            value={this.state.user.newPasswordConfirm}
                            onChange={this.onChange}
                            aria-describedby="basic-addon1"
                            aria-label="nueva contraseña"
                          />
                        </div>
                      </div>
                    )}
                    {this.state.showButtons && (
                      <form
                        id="form-edit-profile"
                        className="d-flex w-100 justify-content-around position-absolute bottom-0 start-50 translate-middle"
                        onSubmit={this.handleSubmit}
                      >
                        <button
                          className="btn btn-primary px-5"
                          onClick={this.handleDisard}
                        >
                          Cancelar
                        </button>
                        <button className="btn btn-primary px-5" type="submit">
                          Guardar
                        </button>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  isAuth: state.loginReducer.Authorization,
  currentAccount: state.loginReducer.currentAccount,
  userData: state.userReducer.user_data,
  showAccount: state.headerReducer.showAccount,
});

const mapDispatchToProps = (dispatch) => ({
  showAccountInfo: (status) => {
    dispatch(showAccountInfo(status));
  },
  logOutUser: () => {
    dispatch(logOutUser());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
