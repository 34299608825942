import React from "react";

import { Route, withRouter, Redirect } from "react-router-dom";

import { connect } from "react-redux";

const PrivateRouteComponent = (props) => (
  <Route
    {...props.routeProps}
    render={() =>
      props.Authenticated ? (
        <div>{props.children}</div>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const mapStateToProps = (state, ownProps) => {
  return {
    Authenticated: state.loginReducer.Authenticated,
    currentAccount: state.loginReducer.currentAccount,
    location: ownProps.path,
    routeProps: {
      exact: ownProps.exact,
      path: ownProps.path,
    },
  };
};

const PrivateRoute = connect(mapStateToProps)(
  withRouter(PrivateRouteComponent)
);

export default PrivateRoute;
