import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Select from "react-select";
import tableStyles from "../Layouts/tableStyle.module.scss";
import flow from "../../assets/img/icons/flow.svg";
import leftArrow from "../../assets/img/icons/leftArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewDocument,
  getAllUsers,
  getProcessByIdTemplate,
  getSubProcessByIdProcess,
  getTemplates,
  getADocument,
  saveDocumentContent,
} from "../../actions/documentActions";
import { isEmptyOrUndefined, message } from "../../helpers/helpers";
import { customSelectNew } from "../Layouts/react-select-custom";
import { CkEditorForm } from "./CkEditorForm";
import { useHistory } from "react-router-dom";
import GenericModal from "../Layouts/GenericModal";
import ModalNew from "../Layouts/ModalNew";
import { Template } from "./Template";

export const DocumentEdit = (props) => {
  const store = useSelector((state) => state);
  const arrFilter = store.documentReducer.documentTypes;
  const documentId = store.documentReducer.documentId;
  const [arrTemplates, setArrTemplates] = useState([]);
  const [templateSelected, setTemplateSelected] = useState("");
  const [filterSelectValue, setFilterSelectValue] = useState("");
  const [arrProcess, setArrProcess] = useState([]);
  const [processSelectValue, setProcessSelectValue] = useState({
    value: "",
    label: "",
  });
  const [filterList, setFilterList] = useState([]);
  const token = store.loginReducer.Authorization;
  const [arrSubProcess, setArrSubProcess] = useState([]);
  const [subProcessSelectedValue, setSubProcessSelectValue] = useState("");
  const [arrUsers, setArrUsers] = useState([]);
  const [arrDocument, setArrDocument] = useState([]);
  const [selectedCreator, setSelectedCreator] = useState("");
  const [selectedEditor, setSelectedEditor] = useState("");
  const [selectedReviewer, setSelectedReviewer] = useState("");
  const [selectedApprover, setSelectedApprover] = useState("");
  const [validity, setValidity] = useState("");
  const [isInstitutional, setIsInstitutional] = useState(false);
  const [documentTitle, setDocumentTitle] = useState("");
  const [general, setGeneral] = useState(true);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  let history = useHistory();
  const [data, setData] = useState("");

  /* -------------------------------------------------------------------------- */
  /*                           GET DOCUMENT TEMPLATES                           */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getTemplatesIn();
    cleanFilters();
    getUsers();
    getDocById();
  }, []);

  const getDocById = async () => {
    const res = await getADocument(documentId, token);
    if (res.length > 0) {
      setArrDocument(res[0]);
    }
  };

  const getUsers = async () => {
    const result = await getAllUsers(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrUs = [];
      result.forEach((e) => {
        arrUs.push({ value: e.id, label: e.fullName });
      });
      setArrUsers(arrUs);
    }
  };
  const getTemplatesIn = async () => {
    const result = await getTemplates(token);
    if (Array.isArray(result) && result.length > 0) {
      let arrTmpl = [];
      result.forEach((e) => {
        arrTmpl.push({ value: e.id, label: e.name });
      });
      setArrTemplates(arrTmpl);
    }
  };
  const cleanFilters = () => {
    let arrFilters = [];
    if (Array.isArray(arrFilters) && arrFilter.length > 0) {
      arrFilter.forEach((e) => {
        arrFilters.push({ value: e.id, label: e.name });
      });
      setFilterList(arrFilters);
    }
  };
  const getProcess = async (idTemplate) => {
    let result = await getProcessByIdTemplate(idTemplate, token);
    if (Array.isArray(result) && result.length > 0) {
      let arrProcess = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrProcess.push({ value: e.id, label: e.name });
      });
      setArrProcess(arrProcess);
    }
  };
  const getSubProcess = async (idProcess) => {
    let result = await getSubProcessByIdProcess(idProcess, token);
    if (Array.isArray(result) && result.length > 0) {
      let arrSubProcess = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrSubProcess.push({ value: e.id, label: e.name });
      });
      setArrSubProcess(arrSubProcess);
    }
  };
  const handleBackButton = () => {
    history.push("/Documentos/EnProceso");
  };
  /* ---------------------------- Send elaboration ---------------------------- */
  const preview = async () => {
    setShowModal(true);
  };
  const submitDocumentEdited = async () => {
    if (data === "") {
      return message(
        "warning",
        "Advertencia",
        "Por favor escriba un cuerpo de documento"
      );
    }
    try {
      const saveContent = await saveDocumentContent(documentId, data, token);
      handleBackButton();
      return message("success", "Éxito", "Documento editado exitosamente.");
    } catch (error) {
      console.log(error);
      return message("error", "Error", error.message);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                           HANDLE CHANGES SECTION                           */
  /* -------------------------------------------------------------------------- */
  const handleChangeFilters = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setFilterSelectValue(e.value);
    }
  };
  const handleChangeProcess = (e) => {
    setSubProcessSelectValue(-1);
    setArrSubProcess([]);
    if (!isEmptyOrUndefined(e.value)) {
      setProcessSelectValue(e.value);
      getSubProcess(e.value);
    }
  };
  const handleChangeSubProcess = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSubProcessSelectValue(e.value);
    }
  };
  const handleOnChangeTemplate = (e) => {
    setProcessSelectValue(-1);
    setSubProcessSelectValue(-1);
    setArrProcess([]);
    setArrSubProcess([]);
    if (!isEmptyOrUndefined(e.value)) {
      setTemplateSelected(e.value);
      getProcess(e.value);
    }
  };
  const handleChangeCreator = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedCreator(e.value);
    }
  };
  const handleChangeEditor = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedEditor(e.value);
    }
  };
  const handleChangeReviewer = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedReviewer(e.value);
    }
  };
  const handleChangeApprover = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedApprover(e.value);
    }
  };
  const handleChangeValidity = (e) => {
    if (!isEmptyOrUndefined(e.target.value)) {
      setValidity(e.target.value);
    }
  };

  const handleChangeIsInstitutional = (e) => {
    setIsInstitutional(isInstitutional === false ? true : false);
  };

  const handleClickGeneral = () => {
    setGeneral(!general);
  };
  const handleClickContent = () => {
    setGeneral(!general);
  };
  const pickDatafromCK = (event, editor) => {
    const data = editor.getData();
    // console.log(data);
    setData(data);
  };
  return (
    <>
      <ModalNew
        size="1210"
        title={"Vista previa del documento"}
        show={showModal}
        onHide={() => setShowModal(false)}
        btnYesName={true}
      >
        <Template data={data}></Template>
      </ModalNew>

      <div className={"w-100"}>
        <div className={`${tableStyles.ItemSmall} mt-5`}>
          <Row
            className="d-flex justify-content-space-between "
            style={{ width: "90%" }}
          >
            <Col xs={1} className="ml-5 d-flex">
              <button onClick={() => handleBackButton()}>
                <img src={leftArrow} alt="back"></img>
              </button>
            </Col>
            <Col xs={4}>
              <div
                className="backgroundColorApp inputDocumentTitle"
                value={documentTitle}
              >
                {arrDocument.name}
              </div>
              <div className="selectDocumentTitle">{arrDocument.type}</div>
            </Col>
          </Row>
        </div>
        <div className={tableStyles.centeredContainer}>
          <div className={`${tableStyles.container1} mt-2`}>
            <Card
              style={{ padding: "0px" }}
              className={`${tableStyles.fourBorderRadiuses} ${tableStyles.container}`}
            >
              <div
                className={`${tableStyles.bgColorClearBlue} d -flex `}
                style={{ width: "100%" }}
              >
                <Col xs={12}>
                  <button
                    className={`btn btn_tabs ${
                      general && tableStyles.slideEffectbtn
                    }`}
                    style={{ marginLeft: "15px" }}
                    onClick={handleClickGeneral}
                  >
                    Archivo
                  </button>

                  <button
                    onClick={handleClickContent}
                    className="btn"
                    className={`btn  btn_tabs ${
                      !general && tableStyles.slideEffectbtn
                    }`}
                  >
                    Edición
                  </button>
                </Col>
              </div>

              {general ? (
                <>
                  <div>
                    <p
                      className={`${tableStyles.clearBlueText} div-document-title-header fontBold`}
                    >
                      Editar documento
                    </p>
                    <hr className="hr-document-title-header"></hr>
                  </div>
                  <div style={{ padding: "0.75em 2em 1em 2em" }}>
                    <h6
                      className={`${tableStyles.clearBlueText} mt-2 fontBold`}
                    >
                      ● Información General
                    </h6>
                    {/* ---------------------------- ROW 1 ---------------------------------- */}
                    <Row className="d-flex" style={{ width: "100%" }}>
                      <Col xs={2}>
                        <p className={tableStyles.crudModalLabel}>Código</p>
                        <input
                          className="register-inputs text-muted text-readonly"
                          name="requisition_date"
                          type="text"
                          value={arrDocument.code}
                          readOnly={true}
                        />
                      </Col>
                      <Col xs={2}>
                        <p className={`${tableStyles.crudModalLabel}`}>
                          Versión
                        </p>
                        <input
                          className="register-inputs  text-muted text-readonly"
                          name="requisition_date"
                          type="text"
                          value={arrDocument.version}
                          radOnly={true}
                        />
                      </Col>
                      <Col xs={3}>
                        <p
                          className={`${tableStyles.crudModalLabel} text-dark`}
                        >
                          Plantilla
                          <span className={"text-danger"}>*</span>
                        </p>
                        <input
                          type="text"
                          className="register-inputs text-readonly"
                          value={arrDocument.template}
                        />
                      </Col>

                      <Col xs={2}>
                        <p className={tableStyles.crudModalLabel}>Creado por</p>
                        <input
                          className="select-register text-muted text-readonly"
                          name="id_consumption_center"
                          radOnly={true}
                          value={arrDocument.creator}
                        ></input>
                      </Col>
                      <Col xs={3} className="d-flex mt-4">
                        <input
                          onChange={(e) => handleChangeIsInstitutional(e)}
                          value={isInstitutional}
                          className="mt-2"
                          type="checkbox"
                        />
                        <p
                          className={`${tableStyles.crudModalLabel} text-dark`}
                        >
                          Es institutional
                        </p>
                      </Col>
                    </Row>
                    {/* ---------------------------- ROW 2 ---------------------------------- */}
                    <Row className="d-flex" style={{ width: "100%" }}>
                      <Col xs={3}>
                        <p className={tableStyles.crudModalLabel}>Creación</p>
                        <input
                          className="register-inputs text-readonly text-muted"
                          name="requisition_date"
                          type="text"
                          value={arrDocument.creation}
                          readOnly={true}
                        />
                      </Col>
                      <Col xs={3}>
                        <p
                          className={`${tableStyles.crudModalLabel} text-dark`}
                        >
                          Vigencia
                          <span className={"text-danger"}>*</span>
                        </p>
                        <input
                          onChange={(e) => handleChangeValidity(e)}
                          className="register-inputs text-readonly"
                          name="requisition_date"
                          type="text"
                          value={arrDocument.validity}
                        />
                      </Col>
                      <Col xs={3}>
                        <p
                          className={`${tableStyles.crudModalLabel} text-dark`}
                        >
                          Proceso
                          <span className={"text-danger"}>*</span>
                        </p>
                        <input
                          value={arrDocument.process}
                          readOnly={true}
                          className={"register-inputs text-readonly"}
                        ></input>
                      </Col>
                      <Col xs={3}>
                        <p
                          className={`${tableStyles.crudModalLabel} text-dark`}
                        >
                          Subproceso
                        </p>
                        <input
                          value={arrDocument.subprocess}
                          readOnly={true}
                          className={"register-inputs text-readonly"}
                        ></input>
                      </Col>
                    </Row>
                    {/* ---------------------------- ROW 3 ---------------------------------- */}
                    <h6
                      className={`${tableStyles.clearBlueText} mt-4 fontBold`}
                    >
                      ● Responsables
                    </h6>
                    <Row className="d-flex" style={{ width: "100%" }}>
                      <Col xs={3}>
                        <p
                          className={`${tableStyles.crudModalLabel} text-dark`}
                        >
                          Elaborador<span className={"text-danger"}>*</span>
                        </p>
                        <input
                          value={arrDocument.developer}
                          readOnly={true}
                          className={"register-inputs text-readonly"}
                        ></input>
                      </Col>
                      <Col xs={3}>
                        <p
                          className={`${tableStyles.crudModalLabel} text-dark`}
                        >
                          Editor<span className={"text-danger"}>*</span>
                        </p>
                        <input
                          value={arrDocument.editor}
                          readOnly={true}
                          className={"register-inputs text-readonly"}
                        ></input>
                      </Col>
                      <Col xs={3}>
                        <p
                          className={`${tableStyles.crudModalLabel} text-dark`}
                        >
                          Revisor<span className={"text-danger"}>*</span>
                        </p>
                        <input
                          value={arrDocument.reviewer}
                          readOnly={true}
                          className={"register-inputs text-readonly"}
                        ></input>
                      </Col>
                      <Col xs={3}>
                        <p
                          className={`${tableStyles.crudModalLabel} text-dark`}
                        >
                          Aprobador<span className={"text-danger"}>*</span>
                        </p>
                        <input
                          value={arrDocument.approver}
                          readOnly={true}
                          className={"register-inputs text-readonly"}
                        ></input>
                      </Col>
                    </Row>
                    {/* ---------------------------- ROW 4 ---------------------------------- */}
                    <p className={`${tableStyles.clearBlueText} mt-4 fontBold`}>
                      ● Opciones del control de flujo
                    </p>
                    <Row className="d-flex mt-" style={{ width: "100%" }}>
                      <img src={flow} style={{ width: "50%" }}></img>
                    </Row>
                    {/* ---------------------------- ROW 5 ---------------------------------- */}
                    <p className={`${tableStyles.clearBlueText} mt-4 fontBold`}>
                      ● Versionamiento
                    </p>
                    <Row className="d-flex " style={{ width: "100%" }}>
                      <Col xs={6}>
                        <span
                          style={{ padding: "10px" }}
                          className={`${tableStyles.clearBlueText} small`}
                        >
                          Version 001 // Rev 1 // FV.4 de octubre 2021
                        </span>
                        <div className={"div-versionamiento-textLike"}>
                          CAMBIOS Sed ut perspiciatis unde omnis iste natus
                          error sit voluptatem accusantium doloremque
                          laudantium, totam rem aperiam, eaque ipsa quae ab illo
                          inventore veritatis et quasi architecto beatae vitae
                          dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                          voluptas sit aspernatur aut odit aut fugit, sed quia
                          consequuntur magni dolores eos qui ratione voluptatem
                          sequi nesciunt. Neque porro quisquam est, qui dolorem
                          ipsum quia dolor sit amet, consectetur, adipisci
                          velit, sed quia non numquam eius modi tempora incidunt
                          ut labore et dolore magnam aliquam quaerat voluptatem.
                          Ut enim ad minima veniam, quis nostrum exercitationem
                          ullam corporis suscipit laboriosam, nisi ut aliquid ex
                          ea commodi consequatur? Quis autem vel eum iure
                          reprehenderit qui in ea voluptate velit esse quam
                          nihil molestiae consequatur, vel illum qui dolorem eum
                          fugiat quo voluptas nulla pariatur? Sed ut
                          perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam, eaque ipsa quae ab illo inventore
                          veritatis et quasi architecto beatae vitae dicta sunt
                          explicabo. Nemo enim ipsam voluptatem quia voluptas
                          sit aspernatur aut odit aut fugit, sed quia
                          consequuntur magni dolores eos qui ratione voluptatem
                          sequi nesciunt. Neque porro quisquam est, qui dolorem
                          ipsum quia dolor sit amet, consectetur, adipisci
                          velit, sed quia non numquam eius modi tempora incidunt
                          ut labore et dolore magnam aliquam quaerat voluptatem.
                          Ut enim ad minima veniam, quis nostrum exercitationem
                          ullam corporis suscipit laboriosam, nisi ut aliquid ex
                          ea commodi consequatur? Quis autem vel eum iure
                          reprehenderit qui in ea voluptate velit esse quam
                          nihil molestiae consequatur, vel illum qui dolorem eum
                          fugiat quo voluptas nulla pariatur?
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <CkEditorForm
                  content={data}
                  pickDatafromCK={pickDatafromCK}
                ></CkEditorForm>
              )}
            </Card>
          </div>
        </div>
        <div className={`customTabs__Footer mt-5`} style={{ width: "84%" }}>
          <Button
            onClick={() => submitDocumentEdited()}
            className={tableStyles.btnPrimary}
          >
            Enviar a revisión
          </Button>
          <Button
            onClick={() => preview()}
            className={tableStyles.btnSecondary}
          >
            Vista previa
          </Button>
          <Button
            onClick={() => handleBackButton()}
            className={tableStyles.btnSecondary}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </>
  );
};
