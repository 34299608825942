import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { message, TokenIsExpired } from "../helpers/helpers";

import {
  SAVE_ALL_POLL_STATUS
} from "../actions/actionTypes";

export const getAllPollStatus = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;

  fetch(`${URL_GATEWAY}${API_VERSION}/status/survey`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        //Si no es 200 va a verificar si es un 401, si no lo es no hace nada
        TokenIsExpired(data.statusCode, dispatch);
        return message("error", "Error", data.message);
      }
      dispatch({
        type: SAVE_ALL_POLL_STATUS,
        payload: data.data
      });
    })
    .catch((err) => {
      console.error(err);
      // message("error", "Error", err.message + "catch");
    });
};

//generic function for all status
export const getAllStatusByType = (appEntity, TYPE) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;

  fetch(`${URL_GATEWAY}${API_VERSION}/status/`+appEntity, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        //Si no es 200 va a verificar si es un 401, si no lo es no hace nada
        TokenIsExpired(data.statusCode, dispatch);
        return message("error", "Error", data.message);
      }
      dispatch({
        type: TYPE,
        payload: data.data
      });
    })
    .catch((err) => {
      console.error(err);
      // message("error", "Error", err.message + "catch");
    });
};