import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import lupa from "../../assets/img/icons/lupa.svg";
import "../TableUsers/tableUserStyle.scss";
import blueAddNew from "../../assets/img/icons/blueAddNew.svg";
import tableStyles from "../Layouts/tableStyle.module.scss";
import paginationStyles from "../Layouts/pagination.module.scss";
/* ------------------------------------ x ----------------------------------- */
import { getAllDocumentType } from "../../actions/documentActions";
import { ComitesGrilla } from "./ComitesGrilla";
import {
  getAllComites,
  getAllComitesTypes,
  getComiDataById,
} from "../../actions/comitesAction";
import { ComitesMinuteForm } from "./ComitesMinuteForm";
import { useHistory } from "react-router-dom";
import { getAllBusinessUnits } from "../../actions/businessUnits";
import { Col, Row } from "react-bootstrap";

function ComitesMasterTable(props) {
  const [showForm, setShowForm] = useState(false);
  const store = useSelector((state) => state);
  const arrFilter = store.comitesReducer.comitesTypes;
  const arrComites = store.comitesReducer.comitesList;
  const token = store.loginReducer.Authorization;
  const dispatch = useDispatch();
  const history = useHistory();
  const [arrDataComi, setArrDataComi] = useState([]);
  const [filters, setFilter] = useState({
    search: "",
    page: 1,
    perpage: 10,
  }); //valores de entrada de la funcion en el backend
  const [showFilters, setShowFilters] = useState(true);
  let i = 0;
  const [contador, setContador] = useState(0); //esto es para recargar la pagina
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(getAllComitesTypes());
    dispatch(getAllComites());
  }, [contador, filters.page, filters.search, showForm]);

  const act = (id) => {
    getDataComi(id, token);
    setShowFilters(false);
    setShow(true);
  };

  const getDataComi = async (id, token) => {
    const result = await getComiDataById(id, token);
    setArrDataComi(result);
  };

  const goToForm = () => {
    history.push("/comites/creacion");
  };
  const hideForm = () => {
    setShow(false);
    setShowFilters(true);
  };
  return (
    <>
      {/* {showForm ? (
        <ComitesMinuteForm hideForm={() => setShowForm(false)} />
        <ComitesMinuteForm  />
        <h1>ya</h1>
      ) : ( */}
      <div className={tableStyles.container}>
        {showFilters ? (
          <h1
            className={`${tableStyles.clearBlueText} ${tableStyles.marginLeftsix}`}
          >
            Listado de comités
          </h1>
        ) : (
          <></>
        )}
        <div className={tableStyles.tableArea}>
          {showFilters ? (
            <div className={tableStyles.mainRow}>
              <div className="d-flex justify-content-space-between filterDiv-ListView">
                <div>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Tipo
                  </p>
                  <select
                    className="select-register"
                    // onChange={(e) => handleChangeTypeSelect(e)}
                    placeholder="Seleccione"
                  >
                    <option value="Seleccione" selected>
                      Seleccione
                    </option>
                    {arrFilter
                      ? arrFilter.length > 0 &&
                        arrFilter.map((e) => {
                          return <option value={e.id}> {e.name} </option>;
                        })
                      : ""}
                  </select>
                </div>
                &nbsp;
                <div className="mt-4">
                  <input
                    className={tableStyles.searchUsersAlt}
                    type="text"
                    placeholder="Buscar"
                    // onChange={(e) => handleChangeInputSearch(e)}
                  />

                  <button
                  // onClick={() => filterTable()}
                  >
                    <img
                      src={lupa}
                      alt="User icon"
                      className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                    />
                  </button>
                </div>
              </div>

              <div className={tableStyles.groupElems}>
                <small className="mt-4">
                  <b onClick={goToForm} className="buttonNewOrCreate">
                    Nuevo
                    <img
                      width="15.6px"
                      height="20px"
                      src={blueAddNew}
                      alt="Form"
                      className={`${tableStyles.iconSvg} buttonNewOrCreate_img`}
                    />
                  </b>
                </small>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* <ComitesGrilla
            arrComites={arrComites}
            showFilters={() => setShowFilters(false)}
          /> */}

          {show ? (
            <ComitesMinuteForm
              hideForm={() => hideForm()}
              dataComite={arrDataComi}
            ></ComitesMinuteForm>
          ) : (
            <div>
              <Row className="d-flex w-100 justify-content-space-between mt-3 ">
                {arrComites.map((e) => {
                  let id = e.id;
                  return (
                    <>
                      <Col
                        xs={1}
                        style={{
                          cursor: "pointer",
                          width: "174px",
                          margin: "10px",
                        }}
                      >
                        <img
                          onClick={() => act(id)}
                          style={{ borderRadius: "15px" }}
                          width="174px"
                          height="184px"
                          src={`${e.imageUrl}`}
                        ></img>
                        <p
                          className="text-center"
                          style={{ "font-size": "15px" }}
                        >
                          {e.name}
                        </p>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </div>
          )}

          {/* <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                Pag. {store.cupsReducer.total ? filters.page : ""}
                {" de "}
                {Math.ceil(store.cupsReducer.total / filters.perpage)
                  ? Math.ceil(store.cupsReducer.total / filters.perpage)
                  : ""}{" "}
                ({store.cupsReducer.total} encontrados)
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={10}
                totalItemsCount={store.cupsReducer.total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div> */}
        </div>
      </div>
      {/* )} */}
    </>
  );
}
export default ComitesMasterTable;
