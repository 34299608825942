import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import lookingForChild from "../../assets/img/icons/lookingForChild.svg";
import womanCheck from "../../assets/img/icons/womanCheck.svg";
import checkList from "../../assets/img/icons/checkList.svg";
import graySettings from "../../assets/img/icons/graySettings.svg";
import homeStyles from "../Home/homeStyles.css";

export const DashShortCuts = () => {
  return (
    <Row className="d-flex" style={{ marginBottom: "5rem", "width":"100%" }}>
      <Col xs={3} onClick={() => alert("hola")}>
        <Card
          className={"boxShadow boxShadowBlue cardBorderRadiusHome"}
          style={{
            backgroundColor: "#4d84fe",
            height: "144px",
            padding: "20px 10px 10px 20px",
          }}
        >
          <div className="d-flex">
            <div>
              <img
                width="80px"
                height="80px"
                src={lookingForChild}
                alt="lookingForChild"
              ></img>
            </div>
            <div style={{ color: "white", marginLeft: "18px" }}>
              <b className="fwCards">Indicadores</b>
              <p className="fwCards">
                Revisa el estado de cada proceso a tiempo real
              </p>
            </div>
          </div>
        </Card>
      </Col>
      <Col xs={7}>
        <Row className="d-flex">
          <Col xs={6}>
            <Card
              className={"boxShadow boxShadowGray cardBorderRadiusHome"}
              style={{
                height: "128px",
                padding: "10px 10px 10px 20px",
              }}
            >
              <div className="d-flex">
                <div>
                  <img
                    width="80px"
                    height="80px"
                    src={womanCheck}
                    alt="womanCheck"
                  ></img>
                </div>
                <div style={{ marginLeft: "18px" }}>
                  <b className="fwCards titlesHomePage">Auditorias</b>
                  <p className="fwCards subtitlesHomePage" style={{ color: "gray" }}>
                    Programa las auditorias los reportes y los procesos que serán
                    evaluados
                  </p>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={6}>
            <Card
              className={"boxShadow boxShadowGray cardBorderRadiusHome"}
              style={{
                height: "128px",
                padding: "20px 10px 10px 20px",
              }}
            >
              <div className="d-flex">
                <div>
                  <img
                    width="80px"
                    height="80px"
                    src={checkList}
                    alt="checkList"
                  ></img>
                </div>
                <div style={{ marginLeft: "18px" }}>
                  <b className="fwCards titlesHomePage">Plan de acción</b>
                  <p className="fwCards subtitlesHomePage" style={{ color: "gray" }}>
                    Revisa el estado de cada proceso a tiempo real
                  </p>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col xs={2} styles={homeStyles.boxShadow}>
        <Card
          className={"boxShadow  boxShadowGray cardBorderRadiusHome"}
          style={{
            height: "128px",
            padding: "20px 10px 10px 20px",
          }}
        >
          <center>
            <img
              width="30.01px"
              height="30px"
              src={graySettings}
              alt="graySettings"
            ></img>
          </center>
          <center className="mt-2">
            <b className="fwCards titlesHomePage">Configuración</b>
          </center>
        </Card>
      </Col>
    </Row>
  );
};
