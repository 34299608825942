import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { message, TokenIsExpired } from "../helpers/helpers";
import { LOADING_SAVE_COMITES_LIST, SAVE_COMITES_LIST, SAVE_COMITES_TYPES } from "./actionTypes";

export const getAllComites = () => (dispatch, getState) => {
  dispatch({
    type: LOADING_SAVE_COMITES_LIST,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/committee/getAll`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        console.log(data.statusCode);
        TokenIsExpired(data.statusCode, dispatch);
        return message("error", "Error", data.message);
      }
      //Si no es 200 va a verificar si es un 401, si no lo es no hace nada
      dispatch({
        type: SAVE_COMITES_LIST,
        payload: data.data,
        loading: false,
      });
    })
    .catch((err) => {
      console.error(err);
      message("error", "Error", err.message + "catch");
    });
};

export const getAllComitesTypes = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/committee/getTypes`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statusCode !== 200) {
        return TokenIsExpired(data.statusCode, dispatch);
        // return message("error", "Error", data.message);
      }
      dispatch({
        type: SAVE_COMITES_TYPES,
        payload: data.data,
      });
    })
    .catch((err) => {
      console.error(err);
      // message("error", "Error", err.message);
    });
};

export const createNewComitee = async (
  name,
  type,
  process,
  firstDate,
  time,
  repeat,
  summary,
  goal,
  token
) => {
  try {
    let data = {
      name,
      type,
      process,
      firstDate,
      time,
      repeat,
      summary,
      goal,
      token,
    };
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/committee/addNew`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const getComiDataById = async (id, token) => {
  let data = { id };
  
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/committee/get`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
export const createNewMinute = async (
  committeeId,
  intro,
  subject,
  location,
  goal,
  date,
  time,
  estimatedDuration,
  topicsComments,
  token
) => {
  try {
    let data = {
      committeeId,
      intro,
      subject,
      location,
      goal,
      date,
      time,
      estimatedDuration,
      topicsComments,
      token,
    };
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/minute/addNew`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.statusCode === 200) {
      return result.data;
    } else {
      return message("error", "Error", result.message);
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error.message);
  }
};
