//All actions for Login component
import {
  USER_LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SHOW_ACCOUNT_INFO,
  SAVE_USER_INFO,
} from "./actionTypes";
import {
  ACCOUNT_SELECT_SAVE,
  ACCOUNT_SELECT_GET,
  ACCOUNT_LOADING,
} from "./actionTypes";
import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { clear } from "redux-localstorage-simple";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { isEmptyOrUndefined, message } from "../helpers/helpers";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
const MySwal = withReactContent(Swal);

export const verifyAccessToken = (token) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/security/validate`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (!res.status || res.status > 300) {
        dispatch(logOutUser());
      }
    })
    .catch((err) => console.error(err, "ERROR"));
};

export const validateUser = (user, password) => (dispatch, getState) => {
  if (user && password) {
    let data = {
      user,
      password,
    };
    fetch(`${URL_GATEWAY}${API_VERSION}/login`, {
      method: "POST",
      body: JSON.stringify(data),
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const { userData, token } = data.data;
        console.log("Borrar esto=>", userData);

        if (data.statusCode !== 200) {
          return message("error", "Error", data.message);
        }

        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: userData,
          Authorization: token,
        });
        dispatch({
          type: SAVE_USER_INFO,
          payload: userData,
        });
      })
      .catch((err) => {
        console.error(err.message);
        MySwal.fire({
          icon: "error",
          title: "Error al conectar",
          text: "Ocurrió un error al intentar conectarse, por favor intente de nuevo.",
        });
      });
  } else {
    MySwal.fire({
      icon: "error",
      title: "Error al ingresar",
      text: "Debe ingresar correo y contraseña para acceder al sistema.",
    });
  }
};

export const logOutUser = () => (dispatch, getState) => {
  clear({
    states: ["loginReducer", "profileReducer", "userReducer"],
  });
  dispatch({
    type: SHOW_ACCOUNT_INFO,
    payload: false,
  });
  dispatch({
    type: LOGOUT_SUCCESS,
  });
};

export const selectAccount = (userid) => (dispatch, getState) => {
  dispatch({
    type: ACCOUNT_LOADING,
    error: false,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/composite/selectaccount/${userid}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      const { status, message, accounts } = data;
      if (status > 300) {
        dispatch({
          type: ACCOUNT_LOADING,
          error: true,
          loading: false,
        });
        return MySwal.fire({
          icon: "error",
          title: "Error buscando cuentas",
          text: message,
        });
      } else {
        dispatch({
          type: ACCOUNT_SELECT_GET,
          payload: accounts,
          loading: false,
        });
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: ACCOUNT_LOADING,
        error: true,
        loading: false,
      });
    });
};

export const saveAccount = (account, doAfter) => (dispatch, getState) => {
  dispatch({
    type: ACCOUNT_SELECT_SAVE,
    payload: account,
  });
  doAfter();
  return MySwal.fire({
    icon: "success",
    title: "selecciono cuenta " + account.name + " ",
  });
};

export const DoLogin = async (user, password) => {
  const dispatch = useDispatch();
  let data = { user, password };
  try {
    if (isEmptyOrUndefined(user) || isEmptyOrUndefined(password)) {
      return message(
        "warning",
        "Campos vacios",
        "Todos los campos son obligatorios"
      );
    }
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const result = await query.json();

    if (result.statusCode !== 200) {
      return message("error", "Error", `${result.message}`);
    } else {
      try {
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: result.data.userData,
          Authorization: result.token,
        });
        dispatch({
          type: SAVE_USER_INFO,
          payload: result.userData,
        });
        <Redirect to="/inicio"></Redirect>;
      } catch (error) {
        console.log(error);
      }
    }
  } catch (error) {
    console.log(error);
    return message("error", "Error", error);
  }
};

export const getAllDocs = async () => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/getAll`, {
      method: "GET",
      // mode: "CORS",
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(query);
    return query;
  } catch (error) {
    console.log(error);
  }
};
