import React, { Component, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Template } from "./Template";
export const CkEditorForm = ({ pickDatafromCK, content }) => {
  const initialData = `<table border=1>
  <tr>
      <td rowspan='3'>
      <img width="15px" height="" src="https://cofca.com/concursos/wp-content/uploads/2018/08/logocofca."></img>
      </td>
      <td rowspan='2' style='width: 180px'>funda</td>
      <td>codigo</td>
      <td>pc-f</td>
  </tr>
  <tr>
      <td>version</td>
      <td>003</td>
  </tr>
  <tr>
      <td>proto</td>
      <td>fecha</td>
      <td>2020</td>
  </tr>
</table>`;

  const test = ({ onChangeInputs }) => {
    return (
      <>
        <table>
          <tr>
            <td rowspan="3">
              <img
                width="15px"
                height=""
                src="https://cofca.com/concursos/wp-content/uploads/2018/08/logocofca.png"
              ></img>
            </td>
            <td rowspan="2" style="width: 180px">
              funda
            </td>
            <td>codigo</td>
            <td>pc-f</td>
          </tr>
          <tr>
            <td>version</td>
            <td>003</td>
          </tr>
          <tr>
            <td>proto</td>
            <td>fecha</td>
            <td>2020</td>
          </tr>
        </table>
      </>
    );
  };

  // const pickDatafromCK = (event, editor) => {
  //   const data = editor.getData();
  //   console.log({ event, editor, data });
  //   setData(data);
  // };
  return (
    <>
      {/* /* ---------------------------------------------------------------------- */
      /*                                CK Editor div                               */
      /* -------------------------------------------------------------------------- */}
      {/* <Template></Template> */}
      <div className="App">
        <CKEditor
          editor={ClassicEditor}
          data={content}
          // onChange={(event, editor) => pickDatafromCK(event, editor)}
          onChange={(event, editor) => pickDatafromCK(event, editor)}
          //   onReady={(editor) => {
          //     console.log("Editor is ready to use!", editor);
          //   }}

          //   onBlur={(event, editor) => {
          //     console.log("Blur.", editor);
          //   }}
          //   onFocus={(event, editor) => {
          //     console.log("Focus.", editor);
          //   }}
        />
      </div>
    </>
  );
};
