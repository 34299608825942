import { Component } from "react";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import { showAccountInfo } from "../actions/headerActions";
import { connect } from "react-redux";
import styles from "./fullLayout.module.css";
class FullLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error:""
        }
    }
    onClickHideAccountInfo = () =>{
        this.props.showAccountInfo(false)
    }
    render() {
        return (
            // <div className="h-100">
            //     <Header isAuth={this.props.isAuth}></Header>
            //     <div className="wrapper d-flex align-items-stretch">
            //         <Sidebar></Sidebar>
            //         <div className="background-ttn" onClick={this.onClickHideAccountInfo}>
            //             {this.props.children}
            //         </div>
            //     </div>
            // </div>

            <div className={styles.fullLayout}>
                <Header isAuth={this.props.isAuth}></Header>
                <div className={styles.fullLayoutBody}>
                    <Sidebar loginReducer={this.props.loginReducer}></Sidebar>
                    <div className={styles.fullLayoutWorkArea} onClick={this.onClickHideAccountInfo}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    isAuth: state.loginReducer.Authenticated,
    loginReducer: state.loginReducer,
    showAccount: state.headerReducer.showAccount
  });
  
  const mapDispatchToProps = (dispatch) => ({
    showAccountInfo:(status)=>{
        dispatch(showAccountInfo(status))
      }
  });
  export default connect(mapStateToProps, mapDispatchToProps)(FullLayout);