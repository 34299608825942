import React from "react";
import { DashAdvices } from "./DashAdvices";
import { DashShortCuts } from "./DashShortCuts";
import tableStyles from "../Layouts/tableStyle.module.scss";

const Home = () => {
  return (
    <div className="d-flex w-100 mx-auto">
      <div
        className=""
        style={{
          marginLeft: "69px",
          marginRight: "20px",
        }}
      >
        <h1 className={`${tableStyles.clearBlueText} mt-5 fw-bold`}>
          Bienvenido,
        </h1>
        &nbsp;
        <h3>
          <b className={"titlesHomePage"}>Dashboard</b>
        </h3>
        <DashShortCuts />
        <h3>
          <b className={"titlesHomePage"}>Anuncios</b>
        </h3>
        <DashAdvices />
      </div>
    </div>
  );
};

export default Home;
