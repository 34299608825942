export const API_VERSION = process.env.REACT_APP_VERSION;
export const URL_GATEWAY = process.env.REACT_APP_URL_BASE;

//Filtros por defecto
export const PERPAGE = 10;
export const PAGE = 1;
export const defaultFilters = {
  perpage: 10,
  page: 1,
  search: "",
};

export const MAX_SIZE_FILE = 5000000;
// Regex for inputs files, valid to format file
export const REGEX_VALID_PDF_TYPE = /^.+\.(([pP][dD][fF]))$/;
export const REGEX_VALID_EXCEL_TYPE = /^.+\.(xlsx|xls|csv)$/g;
export const REGEX_VALID_WORD_TYPE = /^.+\.(doc|docx)$/g;

// provider type like Enumns
export const ProviderType = {
  legal_person: "Persona Jurídica",
  natural: "Persona natural",
};

export const ProviderTypeShort = {
  legal_person: "p. jurídica",
  natural: "p. natural",
};
Object.freeze(ProviderType);

export const ProviderStatus = {
  incomplete: "Incompleto",
  enabled: "Habilitado",
  disabled: "Deshabilitado",
};
Object.freeze(ProviderStatus);
export const MULTIPLE_REGEX_VALID_FILE =
  /^.+\.(([pP][dD][fF]))|(xlsx|xls|csv)|(doc|docx)$/;

export const FILE_ACCEPT_PROVIDER =
  ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, \
.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, \
application/pdf";

export const CompantyTypes = {
  private: "Privada",
  public: "Pública",
  mix: "Mixta",
  nonprofit: "Sin ánimo de lucro",
};
Object.freeze(CompantyTypes);

export const SocietyTypes = {
  other: "Otra",
  anonymous: "Anónimas",
  limited: "Limitada",
  sas: "SAS",
};
Object.freeze(SocietyTypes);

export const regexNumbers = /^[0-9]*$/;

export const BankAccountTypes = {
  current: "Corriente",
  investmentFunds: "Fondos de inversión",
  savings: "Ahorros",
  fiduciaries: "Fiduciarias",
};

export const REGEX_IMAGES = /\.(gif|jpe?g|tiff?|png|webp|bmp|jpg)$/i;

export const ACCEPT_IMAGES = "image/png, image/jpeg";
