import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Select from "react-select";
import tableStyles from "../Layouts/tableStyle.module.scss";
import flow from "../../assets/img/icons/flow.svg";
import leftArrow from "../../assets/img/icons/leftArrow.svg";
import { useSelector } from "react-redux";
import {
  createNewDocument,
  getAllUsers,
  getProcessByIdTemplate,
  getSubProcessByIdProcess,
  getTemplates,
} from "../../actions/documentActions";
import { isEmptyOrUndefined, message } from "../../helpers/helpers";
import { customSelectNew } from "../Layouts/react-select-custom";
import {
  getAllWorkPositions,
  getAsisProcess,
  getBranchOfficeBybUnit,
  getIdentityTypes,
  getLocationsBybOffice,
  getProcessByIdbUnit,
  getProcessByType,
} from "../../actions/businessUnits";
import { createNewEvent } from "../../actions/adverseEventsAction";
export const AdverseEventsForm = (props) => {
  const store = useSelector((state) => state);
  const token = store.loginReducer.Authorization;
  const [businessList, setBusinessList] = useState([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState("");
  const arrBusinessList = store.businessUnitsReducer.businessUnits;
  const identities = store.businessUnitsReducer.identities;
  const genders = store.businessUnitsReducer.genders;
  const insurances = store.businessUnitsReducer.insurances;
  const [arrBranchOffices, setArrBranchOffices] = useState([]);
  const [selectedBranchOffice, setSelectedBranchOffice] = useState("");
  const [arrWorkPosition, setArrWorkPosition] = useState([]);
  const [selectedWorkPosition, setSelectedWorkPosition] = useState("");
  const [dataUserInput, setDataUserInput] = useState({});
  const [arrServices, setArrServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [arrPlaces, setArrPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState("");
  const [arrIdentityType, setArrIdentityType] = useState([]);
  const [selectedIdentityType, setSelectedIdentityType] = useState("");
  const [arrSex, setArrSex] = useState([]);
  const [selectedSex, setSelectedSex] = useState("");
  const [arrInsurancer, setArrInsurancer] = useState([]);
  const [selectedInsurancer, setSelectedInsurancer] = useState("");
  /* -------------------------------------------------------------------------- */
  /*                           GET DOCUMENT TEMPLATES                           */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    cleanFilters();
    getIdentities();
    getGenders();
    getInsurances();
  }, []);
  const cleanFilters = () => {
    let arrBusiness = [];
    if (Array.isArray(arrBusinessList) && arrBusinessList.length > 0) {
      arrBusinessList.forEach((e) => {
        arrBusiness.push({ value: e.id, label: e.name });
      });
      setBusinessList(arrBusiness);
    }
  };
  const getIdentities = () => {
    let arrIdentities = [];
    if (Array.isArray(identities) && identities.length > 0) {
      identities.forEach((e) => {
        arrIdentities.push({ value: e.id, label: e.name });
      });
      setArrIdentityType(arrIdentities);
    }
  };
  const getGenders = () => {
    let arrGenders = [];
    if (Array.isArray(genders) && genders.length > 0) {
      genders.forEach((e) => {
        arrGenders.push({ value: e.id, label: e.name });
      });
      setArrSex(arrGenders);
    }
  };
  const getInsurances = () => {
    let arrInsurances = [];
    if (Array.isArray(insurances) && insurances.length > 0) {
      insurances.forEach((e) => {
        arrInsurances.push({ value: e.id, label: e.name });
      });
      setArrInsurancer(arrInsurances);
    }
  };
  const getWorkPostion = async (bUnit) => {
    let result = await getAllWorkPositions(bUnit, token);
    if (Array.isArray(result) && result.length > 0) {
      let arrWork = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrWork.push({ value: e.id, label: e.name });
      });
      setArrWorkPosition(arrWork);
    }
  };
  const getProcess = async (bUnit) => {
    let result = await getAsisProcess(bUnit, token);
    if (Array.isArray(result) && result.length > 0) {
      let arrServices = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrServices.push({ value: e.id, label: e.name });
      });
      setArrServices(arrServices);
    }
  };
  const getBranchOffices = async (bUnit) => {
    let result = await getBranchOfficeBybUnit(bUnit, token);
    if (Array.isArray(result) && result.length > 0) {
      let arrBranchOffices = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrBranchOffices.push({ value: e.id, label: e.name });
      });
      setArrBranchOffices(arrBranchOffices);
    }
  };
  const getLocations = async (idbOffice) => {
    let result = await getLocationsBybOffice(idbOffice, token);
    if (Array.isArray(result) && result.length > 0) {
      let arrLocations = [{ value: -1, label: "-Seleccione-" }];
      result.forEach((e) => {
        arrLocations.push({ value: e.id, label: e.name });
      });
      setArrPlaces(arrLocations);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                              CREATE NEW EVENT                              */
  /* -------------------------------------------------------------------------- */
  console.log(selectedPlace);
  const submitNewEvent = async () => {
    if (
      isEmptyOrUndefined(dataUserInput.reviewer)
      // isEmptyOrUndefined(validity) ||
      // isEmptyOrUndefined(processSelectValue) ||
      // // isEmptyOrUndefined(subProcessSelectedValue) ||
      // isEmptyOrUndefined(selectedCreator) ||
      // isEmptyOrUndefined(selectedEditor) ||
      // isEmptyOrUndefined(selectedReviewer) ||
      // isEmptyOrUndefined(selectedApprover) ||
      // isEmptyOrUndefined(documentTitle) ||
      // isEmptyOrUndefined(filterSelectValue)
    ) {
      return message(
        "warning",
        "Advertencia",
        "Por favor llene todos los campos  obligatorios"
      );
    }
    const saveNewEvent = await createNewEvent(
      dataUserInput.reviewer,
      selectedWorkPosition,
      selectedBranchOffice,
      selectedService,
      selectedPlace,
      dataUserInput.incidentDate,
      dataUserInput.patientName,
      selectedIdentityType,
      dataUserInput.idNumber,
      selectedSex,
      dataUserInput.age,
      selectedInsurancer,
      dataUserInput.patalogicRecord,
      dataUserInput.incidentDescription,
      token
    );
    if (saveNewEvent) {
      if (!isEmptyOrUndefined(saveNewEvent.docId)) {
        props.hideForm();
        return message("success", "Éxito", "Reporte creado exitosamente");
      } else {
        return message("error", "Error", "No se pudo guardar el reporte");
      }
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                           HANDLE CHANGES SECTION                           */
  /* -------------------------------------------------------------------------- */
  const handleChangeBusinessUnit = (e) => {
    setSelectedBranchOffice(-1);
    setSelectedWorkPosition(-1);
    setSelectedService(-1);
    if (!isEmptyOrUndefined(e.value)) {
      getBranchOffices(e.value);
      setSelectedBusinessUnit(e.value);
      getWorkPostion(e.value);
      getProcess(e.value);
    }
  };
  const handleChangeBranchOffice = (e) => {
    setSelectedPlace(-1);
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedBranchOffice(e.value);
      getLocations(e.value);
    }
  };
  const handleChangeWorkPosition = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedWorkPosition(e.value);
    }
  };
  const handleChangeInput = (e) => {
    let { value, name } = e.target;
    setDataUserInput({ ...dataUserInput, [name]: value });
  };

  const handleChangeService = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedService(e.value);
    }
  };
  const handleChangePlace = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedPlace(e.value);
    }
  };
  const handleChangeIdType = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedIdentityType(e.value);
    }
  };
  const handleChangeSex = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedSex(e.value);
    }
  };
  const handleChangeInsurancer = (e) => {
    if (!isEmptyOrUndefined(e.value)) {
      setSelectedInsurancer(e.value);
    }
  };
  return (
    <>
      <div className={"w-100"}>
        <div className={`${tableStyles.ItemSmall} mt-5`}>
          <Row
            className="d-flex justify-content-space-between "
            style={{ width: "90%" }}
          >
            <Col xs={12} className="ml-5 d-flex">
              <button onClick={props.hideForm}>
                <img src={leftArrow} alt="back"></img>
              </button>
              <h2 className="text-dark mb-3" style={{ marginLeft: "4%" }}>
                Crear reporte
              </h2>
            </Col>
          </Row>
        </div>
        <div className={tableStyles.centeredContainer}>
          <div className={`${tableStyles.container1} mt-2`}>
            <Card
              className={`${tableStyles.fourBorderRadiuses} ${tableStyles.container} ${tableStyles.bgColorClearBlue}`}
              style={{ borderWidth: 0 }}
            >
              <h6 className={`${tableStyles.clearBlueText} mt-2`}>
                Información Básica
              </h6>
              {/* ---------------------------- ROW 1 ---------------------------------- */}
              <Row className="d-flex" style={{ width: "100%" }}>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Observador
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    className="register-inputs"
                    name="reviewer"
                    onChange={(e) => handleChangeInput(e)}
                  />
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Unidad de negocio
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={businessList}
                    styles={customSelectNew}
                    value={businessList.find(
                      (obj) => obj.value === selectedBusinessUnit
                    )}
                    onChange={(e) => handleChangeBusinessUnit(e)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Cargo
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrWorkPosition}
                    styles={customSelectNew}
                    value={arrWorkPosition.find(
                      (obj) => obj.value === selectedWorkPosition
                    )}
                    onChange={(e) => handleChangeWorkPosition(e)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Sede
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrBranchOffices}
                    styles={customSelectNew}
                    value={arrBranchOffices.find(
                      (obj) => obj.value === selectedBranchOffice
                    )}
                    onChange={(e) => handleChangeBranchOffice(e)}
                  ></Select>
                </Col>
              </Row>
              {/* ---------------------------- ROW 2 ---------------------------------- */}
              <Row className="mb-4 d-flex" style={{ width: "100%" }}>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Servicio
                    <span className={"text-danger"}>*</span>
                  </p>

                  <Select
                    placeholder={"Seleccione"}
                    options={arrServices}
                    styles={customSelectNew}
                    value={arrServices.find(
                      (obj) => obj.value === selectedService
                    )}
                    onChange={(e) => handleChangeService(e)}
                  ></Select>
                </Col>
                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Lugar
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrPlaces}
                    styles={customSelectNew}
                    value={arrPlaces.find((obj) => obj.value === selectedPlace)}
                    onChange={(e) => handleChangePlace(e)}
                  ></Select>
                </Col>

                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Fecha de incidente
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    name="incidentDate"
                    onChange={(e) => handleChangeInput(e)}
                    className="register-inputs"
                    type="date"
                  />
                </Col>
              </Row>
            </Card>
            {/* /* -------------------------------- BELOW ROW ------------------------------- */}
            <div style={{ padding: "15px" }}>
              <h6 className={`${tableStyles.clearBlueText} mt-2`}>
                Información de captura
              </h6>
              {/* /* ---------------------------------- ROW 1 --------------------------------- */}
              <Row className="d-flex" style={{ width: "100%" }}>
                <Col xs={4}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Nombre del paciente
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    name="patientName"
                    onChange={(e) => handleChangeInput(e)}
                    className="register-inputs"
                  />
                </Col>
                <Col xs={2}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Tipo de identidad
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrIdentityType}
                    styles={customSelectNew}
                    value={arrIdentityType.find(
                      (obj) => obj.value === selectedIdentityType
                    )}
                    onChange={(e) => handleChangeIdType(e)}
                  ></Select>
                </Col>

                <Col xs={3}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Número de identidad
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    name="idNumber"
                    type="number"
                    onChange={(e) => handleChangeInput(e)}
                    className="register-inputs"
                  />
                </Col>
                <Col xs={2}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Sexo
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrSex}
                    styles={customSelectNew}
                    value={arrSex.find((obj) => obj.value === selectedSex)}
                    onChange={(e) => handleChangeSex(e)}
                  ></Select>
                </Col>
                <Col xs={1}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Edad
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    name="age"
                    type="number"
                    onChange={(e) => handleChangeInput(e)}
                    className="register-inputs"
                  />
                </Col>
              </Row>
              {/* /* ---------------------------------- ROW 2 --------------------------------- */}
              <Row className="d-flex" style={{ width: "100%" }}>
                <Col xs={4}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Aseguradora
                    <span className={"text-danger"}>*</span>
                  </p>
                  <Select
                    placeholder={"Seleccione"}
                    options={arrInsurancer}
                    styles={customSelectNew}
                    value={arrInsurancer.find(
                      (obj) => obj.value === selectedInsurancer
                    )}
                    onChange={(e) => handleChangeInsurancer(e)}
                  ></Select>
                </Col>
                <Col xs={8}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Antecedentes pátológicos
                    <span className={"text-danger"}>*</span>
                  </p>
                  <input
                    name="patalogicRecord"
                    onChange={(e) => handleChangeInput(e)}
                    className="register-inputs"
                  />
                </Col>
              </Row>
              {/* /* ---------------------------------- ROW 3 --------------------------------- */}
              <Row className="d-flex" style={{ width: "100%" }}>
                <Col xs={6}>
                  <p className={`${tableStyles.crudModalLabel} text-dark`}>
                    Descripción del incidente
                    <span className={"text-danger"}>*</span>
                  </p>
                  <textarea
                    style={{ height: "5.3rem" }}
                    placeholder="Escribir..."
                    rows="10"
                    cols="100"
                    className="register-inputs"
                    name="incidentDescription"
                    onChange={(e) => handleChangeInput(e)}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className={`customTabs__Footer mt-5`} style={{ width: "84%" }}>
          <Button
            onClick={() => submitNewEvent()}
            className={tableStyles.btnPrimary}
          >
            Crear
          </Button>
          <Button className={tableStyles.btnSecondary}>Cancelar</Button>
        </div>
      </div>
    </>
  );
};
